import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetExpenses = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getExpenses', property_id, page, limit]

  const { data: { data: { Expenses = [], pagination: expensesPagination = {} } = [] } = [], ...getExpensesRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`expenses?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { Expenses, expensesPagination, getExpensesRest }
}


// ### ADD

export const useAddExpense = () => {

  const queryClient = useQueryClient()

  const { mutate: addExpense, ...addExpenseRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`expenses/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getExpenses'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addExpense, addExpenseRest }
}

// ### UPDATE

export const useUpdateExpense = () => {

  const queryClient = useQueryClient()

  const { mutate: updateExpense, ...updateExpenseRest } = useMutation({
    mutationFn: async ({ data, expense_id }) => (await api.put(`expenses/${expense_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getExpenses'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })  

  return { updateExpense, updateExpenseRest }
}

// ### DELETE

export const useDestroyExpense = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyExpense, ...destroyExpenseRest } = useMutation({
    mutationFn: async (expense_id) => (await api.delete(`expenses/${expense_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getExpenses'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyExpense, destroyExpenseRest }

}
