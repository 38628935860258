import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import InputSelect from '../../components/InputSelect'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import BackgroundData from '../../components/BackgroundData'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useGetProperties } from '../../queries/PropertyQueries'
import { useGetReportProductiveYearAnimalProductions } from '../../queries/ReportProductiveYearAnimalProductionQueries'
import { useGetReportFinancialDRE } from '../../queries/ReportFinancialDREQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { months, brazilianCurrencyMask } from '../../utils'

function ReportFinancialDRE() {

  const { selectedProperty } = usePropertyStore()

  const [year, setYear] = useState(new Date().getFullYear())

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  const { ReportFinancialDRE, getReportFinancialDRERest } = useGetReportFinancialDRE(selectedProperty?.id, year)

  if (getReportFinancialDRERest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="DRE Anual">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <div className='w-full flex flex-col justify-start space-y-6'>

          <BackgroundData>
            <div className='w-full flex flex-col lg:flex-row space-y-8 lg:space-y-0 items-center justify-around'>

              <div className='flex lg:w-1/3 w-full items-center justify-around text-3xl'>

                <button className='flex-1 flex justify-center items-center hover:text-4xl text-primaryBackground' onClick={() => setYear(parseInt(year) - 1)}>
                  <Icon icon="ep:arrow-left-bold" />
                </button>

                <span className='flex-1 flex justify-center items-center text-tertiaryBackground'>{year}</span>

                <button className='flex-1 flex justify-center items-center hover:text-4xl text-primaryBackground' onClick={() => setYear(parseInt(year) + 1)}>
                  <Icon icon="ep:arrow-right-bold" />
                </button>

              </div>
            </div>
          </BackgroundData>

          <BackgroundData>

            <div className="relative overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ">
                <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                  <tr>
                    <th scope="col" className="px-6 py-3" >Receitas</th>

                    {
                      months?.length > 0 && months?.map((item, index) => {
                        return (
                          <td scope="col" className="px-6 py-3" key={item}>{item}</td>
                        )
                      })
                    }
                  </tr>
                </thead>

                <tbody>

                  {ReportFinancialDRE?.revenuesData?.length > 0 && ReportFinancialDRE?.revenuesData?.map((item, index) => {

                    return (
                      <tr className="bg-white border-b" key={item}>
                        <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{item?.name}</th>

                        {item?.revenues?.map(revenue => {
                          return (
                            <td className="px-6 py-4 text-xs truncate">{`R$ ${brazilianCurrencyMask(revenue?.value)}`}</td>
                          )
                        })}
                      </tr>
                    )
                  })}

                  <tr>
                    <th scope="row" className="px-6 py-4 whitespace-nowrap uppercase font-bold text-xs">Receitas Totais</th>
                    <td scope="col" className="px-6 py-3 text-xs truncate font-bold" >{`R$ ${brazilianCurrencyMask(ReportFinancialDRE?.revenuesTotalValue)}`}</td>
                  </tr>

                  <tr className="text-xs uppercase bg-gray-50 text-primaryBackground">
                    <th scope="col" className="px-6 py-3" >Despesas</th>

                    {
                      months?.length > 0 && months?.map((item, index) => {
                        return (
                          <td scope="col" className="px-6 py-3" key={item}>{item}</td>
                        )
                      })
                    }
                  </tr>

                  {ReportFinancialDRE?.expensesData?.length > 0 && ReportFinancialDRE?.expensesData?.map((item, index) => {

                    return (
                      <tr className="bg-white border-b" key={item}>
                        <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{item?.name}</th>

                        {item?.expenses?.map(expense => {
                          return (
                            <td className="px-6 py-4 text-xs truncate">{`R$ ${brazilianCurrencyMask(expense?.value)}`}</td>
                          )
                        })}

                      </tr>
                    )
                  })}

                  <tr>
                    <th scope="row" className="px-6 py-4 whitespace-nowrap uppercase font-bold text-xs">Despesas Totais</th>
                    <td scope="col" className="px-6 py-3 text-xs truncate font-bold" >{`R$ ${brazilianCurrencyMask(ReportFinancialDRE?.expensesTotalValue)}`}</td>
                  </tr>
                  <tr>
                    <th scope="row" className="px-6 whitespace-nowrap uppercase font-bold text-xs">Resultado</th>
                    <td scope="col" className={`text-xs px-6 truncate font-bold ${ReportFinancialDRE?.profit < 0 ? "text-primaryAlert" : ""}`} >{`R$ ${brazilianCurrencyMask(ReportFinancialDRE?.profit)}`}</td>
                  </tr>

                </tbody>
              </table>

            </div>

          </BackgroundData>

        </div>

        {showModalAddProperty &&
          <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
            <PropertyAdd setOpenModal={setShowModalAddProperty} />
          </ModalSide>
        }

      </div>
    </Menu>
  )
}

export default ReportFinancialDRE