import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetCalvings = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getCalvings', property_id, page, limit]

  const { data: { data: { Calvings = [], pagination: calvingsPagination = {} } = [] } = [], ...getCalvingsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`calvings?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { Calvings, calvingsPagination, getCalvingsRest }
}


// ### ADD

export const useAddCalving = () => {

  const queryClient = useQueryClient()

  const { mutate: addCalving, ...addCalvingRest } = useMutation({
    mutationFn: async ({ data, mating_id }) => (await api.post(`calvings/${mating_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCalvings'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addCalving, addCalvingRest }
}

// ### UPDATE

export const useUpdateCalving = () => {

  const queryClient = useQueryClient()

  const { mutate: updateCalving, ...updateCalvingRest } = useMutation({
    mutationFn: async ({ data, calving_id }) => (await api.put(`calvings/${calving_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCalvings'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateCalving, updateCalvingRest }
}

// ### DELETE

export const useDestroyCalving = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyCalving, ...destroyCalvingRest } = useMutation({
    mutationFn: async (calving_id) => (await api.delete(`calvings/${calving_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCalvings'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyCalving, destroyCalvingRest }

}
