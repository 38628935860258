import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ModalSide from '../../components/ModalSide'
import BackgroundData from '../../components/BackgroundData'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useGetReportProductiveYearAnimalProductions } from '../../queries/ReportProductiveYearAnimalProductionQueries'
import { useGetReportProductiveMonthMilkQualities } from '../../queries/ReportProductiveMonthMilkQualityQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { months, brazilianCurrencyMask, setMonthAndYearBrazilianFormat } from '../../utils'

function ReportProductiveMonthMilkQuality() {

  const { selectedProperty } = usePropertyStore()

  const [year, setYear] = useState(new Date().getFullYear())
  const [proteinTotal, setProteinTotal] = useState(0)
  const [proteinPercentageTotal, setProteinPercentageTotal] = useState(0)
  const [fatPercentageTotal, setFatPercentageTotal] = useState(0)
  const [fatTotal, setFatTotal] = useState(0)
  const [lactoseTotal, setLactoseTotal] = useState(0)
  const [solitosTotal, setSolitosTotal] = useState(0)
  const [ureaTotal, setUreaTotal] = useState(0)
  const [ccsTotal, setCcsTotal] = useState(0)
  const [cbtTotal, setCbtTotal] = useState(0)

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  const { MonthMilkQualities, getReportProductiveMonthMilkQualitiesRest } = useGetReportProductiveMonthMilkQualities(selectedProperty?.id, year)

  useEffect(() => {

    if (MonthMilkQualities.length > 0) {
      setProteinTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.protein, 0))
      setProteinPercentageTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.protein_percentage, 0))
      setFatPercentageTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.fat_percentage, 0))
      setFatTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.fat, 0))
      setLactoseTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.lactose, 0))
      setSolitosTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.solitos, 0))
      setUreaTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.urea, 0))
      setCcsTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.ccs, 0))
      setCbtTotal(MonthMilkQualities?.reduce((acc, item) => acc + item.cbt, 0))

      //  setTotalAnnual(YearAnimalProductions?.reduce((acc, item) => acc + (parseInt(((item.liters_delivered / 100) * (item.price / 100)) * 100)), 0))

    } else {
      setProteinTotal(0)
      setProteinPercentageTotal(0)
      setFatPercentageTotal(0)
      setFatTotal(0)
      setLactoseTotal(0)
      setSolitosTotal(0)
      setUreaTotal(0)
      setCcsTotal(0)
      setCbtTotal(0)

      // setTotalAnnual(0)
    }

  }, [MonthMilkQualities])

  if (getReportProductiveMonthMilkQualitiesRest?.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Qualidade do Leite Mensal">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <div className='w-full flex flex-col justify-start space-y-6'>

          <BackgroundData>
            <div className='w-full flex flex-col lg:flex-row space-y-8 lg:space-y-0 items-center justify-around'>

              <div className='flex lg:w-1/3 w-full items-center justify-around text-3xl'>

                <button className='flex-1 flex justify-center items-center hover:text-4xl text-primaryBackground' onClick={() => setYear(parseInt(year) - 1)}>
                  <Icon icon="ep:arrow-left-bold" />
                </button>

                <span className='flex-1 flex justify-center items-center text-tertiaryBackground'>{year}</span>

                <button className='flex-1 flex justify-center items-center hover:text-4xl text-primaryBackground' onClick={() => setYear(parseInt(year) + 1)}>
                  <Icon icon="ep:arrow-right-bold" />
                </button>

              </div>


            </div>
          </BackgroundData>

          <BackgroundData>

            <div className="relative overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ">
                <thead className="text-xs uppercase bg-gray-50 text-primaryBackground truncate">
                  <tr>
                    <th scope="col" className="px-6 py-3">Mês</th>
                    <th scope="col" className="px-6 py-3">Proteína (KG)</th>
                    <th scope="col" className="px-6 py-3">Proteína (%)</th>
                    <th scope="col" className="px-6 py-3">Gordura (KG)</th>
                    <th scope="col" className="px-6 py-3">Gordura (%)</th>
                    <th scope="col" className="px-6 py-3">Lactose (%)</th>
                    <th scope="col" className="px-6 py-3">Sólidos (%)</th>
                    <th scope="col" className="px-6 py-3 normal-case">UREIA (mg/dl)</th>
                    <th scope="col" className="px-6 py-3">CCS</th>
                    <th scope="col" className="px-6 py-3">CBT</th>
                    <th scope="col" className="px-6 py-3"></th>
                  </tr>
                </thead>

                {months?.length > 0 && months.map((item, index) => {


                  return (
                    <tbody key={index}>
                      <tr className="bg-white border-b">
                        <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{item}</th>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.protein ? brazilianCurrencyMask(+parseFloat(MonthMilkQualities[index]?.protein * 100).toFixed(2)) : "0"}</td>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.protein_percentage ? brazilianCurrencyMask(+parseFloat(MonthMilkQualities[index]?.protein_percentage * 100).toFixed(2)) : "0"}</td>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.fat ? brazilianCurrencyMask(+parseFloat(MonthMilkQualities[index]?.fat * 100).toFixed(2)) : "0"}</td>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.fat_percentage ? brazilianCurrencyMask(+parseFloat(MonthMilkQualities[index]?.fat_percentage * 100).toFixed(2)) : "0"}</td>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.lactose ? brazilianCurrencyMask(+parseFloat(MonthMilkQualities[index]?.lactose * 100).toFixed(2)) : "0"}</td>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.solitos ? brazilianCurrencyMask(+parseFloat(MonthMilkQualities[index]?.solitos * 100).toFixed(2)) : "0"}</td>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.urea ? brazilianCurrencyMask(MonthMilkQualities[index]?.urea * 100) : "0"}</td>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.ccs}</td>
                        <td className="px-6 py-4">{MonthMilkQualities[index]?.cbt}</td>
                      </tr>
                    </tbody>
                  )
                })}

                <tfoot className='uppercase'>
                  <tr className='font-bold bg-gray-50 border-b '>
                    <th scope="row" className="px-6 py-4 whitespace-nowrap">Total do Ano:</th>
                    <td className="px-6 py-4">{brazilianCurrencyMask(proteinTotal * 100) || "0"}</td>
                    <td className="px-6 py-4">{"-"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(fatTotal * 100) || "0"}</td>
                    <td className="px-6 py-4">{"-"}</td>
                    <td className="px-6 py-4">{"-"}</td>
                    <td className="px-6 py-4">{"-"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(ureaTotal * 100) || "0"}</td>
                    <td className="px-6 py-4">{ccsTotal}</td>
                    <td className="px-6 py-4">{cbtTotal}</td>
                  </tr>
                  <tr className='font-bold bg-gray-50'>
                    <th scope="row" className="px-6 py-4 whitespace-nowrap">Média do ano:</th>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(proteinTotal / 12).toFixed(2)) || "0"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(proteinPercentageTotal / 12).toFixed(2)) || "0"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(fatTotal / 12).toFixed(2)) || "0"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(fatPercentageTotal / 12).toFixed(2)) || "0"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(lactoseTotal / 12).toFixed(2)) || "0"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(solitosTotal / 12).toFixed(2)) || "0"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(ureaTotal / 12).toFixed(2)) || "0"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(ccsTotal / 12).toFixed(2)) || "0"}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(+parseFloat(cbtTotal / 12).toFixed(2)) || "0"}</td>
                  </tr>
                </tfoot>

              </table>

            </div>

          </BackgroundData>

        </div>

        {showModalAddProperty &&
          <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
            <PropertyAdd setOpenModal={setShowModalAddProperty} />
          </ModalSide>
        }

      </div>
    </Menu>
  )
}

export default ReportProductiveMonthMilkQuality