import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import InputBoolean from '../../components/InputBoolean'

import PropertyAdd from '../PropertyAdd'
import CustomerSupplierAdd from '../CustomerSupplierAdd'
import BankAccountAdd from '../BankAccountAdd'
import Loading from '../../components/Loading'

import { usePropertyStore } from '../../stores/propertyStore'

import { useUpdateRevenue } from '../../queries/RevenueQueries'
import { useGetBankAccounts } from '../../queries/BankAccountQueries'
import { useGetCustomerSuppliers } from '../../queries/CustomerSupplierQueries'
import { useGetCostCenters } from '../../queries/CostCenterQueries'
import { useGetRevenueCategories } from '../../queries/RevenueCategoryQueries'

import { brazilianCurrencyMask, toUSACurrency, setDatePostFormat, setInputDateFormat } from '../../utils'

function RevenueUpdate(props) {

  const selectedRevenue = props?.data || {}

  const { selectedProperty } = usePropertyStore()

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { updateRevenue, updateRevenueRest } = useUpdateRevenue()
  const { BankAccounts, getBankAccountsRest } = useGetBankAccounts(property_id)
  const { CustomerSuppliers, getCustomerSuppliersRest } = useGetCustomerSuppliers(property_id, 1, 1000000) 
  const { CostCenters, getCostCenterRest } = useGetCostCenters()
  const { RevenueCategories, getRevenueCategoryRest } = useGetRevenueCategories() 

  const [costCenter, setCostCenter] = useState({})
  const [costCenterName, setCostCenterName] = useState("")
  const [costCenterListName, setCostCenterListName] = useState([])

  const [category, setCategory] = useState({})
  const [categoryName, setCategoryName] = useState("")
  const [categoryListName, setCategoryListName] = useState([])

  const [customerSupplier, setCustomerSupplier] = useState({})
  const [customerSupplierName, setCustomerSupplierName] = useState("")
  const [customerSupplierListName, setCustomerSupplierListName] = useState([])

  const [bankAccount, setBankAccount] = useState({})
  const [bankAccountName, setBankAccountName] = useState("")
  const [bankAccountListName, setBankAccountListName] = useState([])

  const [description, setDescription] = useState(selectedRevenue?.description || "")
  const [numDoc, setNumDoc] = useState(selectedRevenue?.num_doc || "")
  const [dateIssue, setDateIssue] = useState("") 
  const [value, setValue] = useState(selectedRevenue?.value || "")
  const [discount, setDiscount] = useState(selectedRevenue?.discount || "")
  const [interest, setInterest] = useState(selectedRevenue?.interest || "")
  const [total, setTotal] = useState("")
  const [observation, setObservation] = useState(selectedRevenue?.observation || "")
   
  const [formOfPayment, setFormOfPayment] = useState("")
  const [formOfPaymentList, setFormOfPaymentList] = useState("")

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)
  const [showModalAddCustomerSupllier, setShowModalAddCustomerSupllier] = useState(false)
  const [showModalAddBankAccount, setShowModalAddBankAccount] = useState(false)

  useEffect(() => {

    const formPayments = [
      "Dinheiro",
      "Cartão de Crédito",
      "Cartão de Débito",
      "Cheque",
      "Depósito",
      "Boleto",
      "Transferência",
      "Débito em Conta",
      "Débito em Folha",
      "Débito Automático",
      "Pix",
      "Crédito na Loja"
    ]

    setFormOfPaymentList(formPayments)


    if (selectedRevenue?.form_of_payment) {

      const formOfPaymentNameStart = formPayments.find((x) => {

        if (x === selectedRevenue?.form_of_payment) {
          return x
        }
      })
      setFormOfPayment(formOfPaymentNameStart)
    }    

    if (selectedRevenue?.date_of_issue) {
      setDateIssue(setInputDateFormat(selectedRevenue?.date_of_issue))
    }
   

    if (selectedRevenue?.category_id && RevenueCategories.length > 0) {
      const categoryNameStart = RevenueCategories.map((x) => {

        if (x?.id === selectedRevenue?.category_id) {
          return x.name
        }
      }).filter((str) => str)

      setCategoryName(categoryNameStart)

    }

    if (selectedRevenue?.customer_supplier_id && CustomerSuppliers.length > 0) {
      const customerSupplierNameStart = CustomerSuppliers.map((x) => {

        if (x?.id === selectedRevenue?.customer_supplier_id) {
          return x.name
        }
      }).filter((str) => str)

      setCustomerSupplierName(customerSupplierNameStart)
    }

    if (selectedRevenue?.bank_account_id && BankAccounts.length > 0) {
      const bankAccountNameStart = BankAccounts.map((x) => {

        if (x?.id === selectedRevenue?.bank_account_id) {
          return x.description
        }
      }).filter((str) => str)

      setBankAccountName(bankAccountNameStart)
    }

    if (selectedRevenue?.cost_center_id && CostCenters.length > 0) {
      const costCenterNameStart = CostCenters.map((x) => {

        if (x?.id === selectedRevenue?.cost_center_id) {
          return x.name
        }
      }).filter((str) => str)

      setCostCenterName(costCenterNameStart)
    }

  }, [])

  useEffect(() => {
    if (RevenueCategories.length > 0) {
      const listCategory = RevenueCategories.map(x => x.name)
      setCategoryListName(listCategory)
    }

  }, [RevenueCategories])

  useEffect(() => {
    if (CostCenters.length > 0) {
      const listCosts = CostCenters.map(x => x.name)
      setCostCenterListName(listCosts)
    }

  }, [CostCenters])

  useEffect(() => {
    if (CustomerSuppliers.length > 0) {
      const listCustomerSupplier = CustomerSuppliers.map(x => x.name)
      setCustomerSupplierListName(listCustomerSupplier)
    }
  }, [CustomerSuppliers])

  useEffect(() => {
    if (BankAccounts.length > 0) {
      const listBankAccount = BankAccounts.map(x => x.description)
      setBankAccountListName(listBankAccount)
    }
  }, [BankAccounts])


  useEffect(() => {

    if (costCenterName) {

      const costCenterSelected = CostCenters.find(x => x.name === costCenterName)

      setCostCenter(costCenterSelected)

    }

  }, [costCenterName])

  useEffect(() => {

    if (categoryName) {

      const categorySelected = RevenueCategories.find(x => x.name === categoryName)

      setCategory(categorySelected)

    }

  }, [categoryName])

  useEffect(() => {

    if (customerSupplierName) {

      const customerSupplierSelected = CustomerSuppliers.find(x => x.name === customerSupplierName)

      setCustomerSupplier(customerSupplierSelected)

    }

  }, [customerSupplierName])

  useEffect(() => {

    if (bankAccountName) {

      const bankAccountSelected = BankAccounts.find(x => x.description === bankAccountName)

      setBankAccount(bankAccountSelected)
    }

  }, [bankAccountName])

  useEffect(() => {

    let discountTotal = 0
    let interestTotal = 0
    let valueTotal = 0

    if (discount) {
      discountTotal = Math.trunc(toUSACurrency(discount) * 100)
    }

    if (interest) {
      interestTotal = Math.trunc(toUSACurrency(interest) * 100)
    }

    if (value) {
      valueTotal = (Math.trunc(toUSACurrency(value) * 100)) - discountTotal - interestTotal

      setTotal(brazilianCurrencyMask(valueTotal))
    } else {
      setTotal("")
    }

    // console.log(valueTotal)

  }, [value, discount, interest])

  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (description && description !== selectedRevenue?.description) {
      data.description = description
      dataUpdated = true
    }
    
    if (formOfPayment && formOfPayment !== selectedRevenue?.form_of_payment) {
      data.form_of_payment = formOfPayment
      dataUpdated = true
    }

    if (value && parseInt(selectedRevenue?.value) !== Math.trunc(toUSACurrency(value) * 100)) {
      data.value = Math.trunc((toUSACurrency(value) * 100))
      dataUpdated = true
    }

    if (discount && parseInt(selectedRevenue?.discount) !== Math.trunc(toUSACurrency(discount) * 100)) {
      data.discount = Math.trunc((toUSACurrency(discount) * 100))
      dataUpdated = true
    }

    if (interest && parseInt(selectedRevenue?.interest) !== Math.trunc(toUSACurrency(interest) * 100)) {
      data.interest = Math.trunc((toUSACurrency(interest) * 100))
      dataUpdated = true
    }

    if (total && parseInt(selectedRevenue?.total) !== Math.trunc(toUSACurrency(total) * 100)) {
      data.total = Math.trunc(toUSACurrency(total) * 100)
      dataUpdated = true
    }

    if (costCenter?.id && costCenter?.id !== selectedRevenue?.cost_center_id) {
      data.cost_center_id = costCenter.id
      dataUpdated = true
    }

    if (category?.id && category?.id !== selectedRevenue?.category_id) {
      data.category_id = category.id
      dataUpdated = true
    }

    if (dateIssue && dateIssue !== setInputDateFormat(selectedRevenue?.date_issue)) {
      data.date_of_issue = setDatePostFormat(dateIssue)
      dataUpdated = true
    }

    if (numDoc && numDoc !== selectedRevenue?.num_doc) {
      data.num_doc = numDoc
      dataUpdated = true
    }

    if (observation && observation !== selectedRevenue?.observation) {
      data.observation = observation
      dataUpdated = true
    }    


    if (customerSupplier?.id && customerSupplier?.id !== selectedRevenue?.customer_supplier_id) {
      data.customer_supplier_id = customerSupplier.id
      dataUpdated = true
    }

    if (bankAccount?.id && bankAccount?.id !== selectedRevenue?.bank_account_id) {
      data.bank_account_id = bankAccount.id
      dataUpdated = true
    }   


    if (dataUpdated) {     

      updateRevenue({ data, revenue_id: selectedRevenue?.id })

      props.setOpenModal(false)

    } else {
      alert('Nenhum dado para ser atualizado!')
    }

  }

  if (getCostCenterRest.isPending || updateRevenueRest.isPending || getBankAccountsRest.isPending || getCustomerSuppliersRest.isPending || getRevenueCategoryRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>
       
        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Descrição"
          value={description}
          setValue={(e) => { setDescription(e) }}
        />       

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Forma de Pagamento"
          value={formOfPayment}
          list={formOfPaymentList}
          setValue={(e) => { setFormOfPayment(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Valor*"
            value={value}
            setValue={(e) => { setValue(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Desconto"
            value={discount}
            setValue={(e) => { setDiscount(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Juros"
            value={interest}
            setValue={(e) => { setInterest(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <div className='flex items-center'>
          <Input
            type="text"
            disabled
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Total"
            value={total}
            setValue={(e) => { setTotal(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>       

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Centro de Custo"
          value={costCenterName}
          list={costCenterListName}
          setValue={(e) => { setCostCenterName(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Categoria"
          value={categoryName}
          list={categoryListName}
          setValue={(e) => { setCategoryName(e) }}
        />

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Cliente / Fornecedor"
            value={customerSupplierName}
            list={customerSupplierListName}
            setValue={(e) => { setCustomerSupplierName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddCustomerSupllier(true)} />
        </div>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Conta Bancária"
            value={bankAccountName}
            list={bankAccountListName}
            setValue={(e) => { setBankAccountName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddBankAccount(true)} />
        </div>

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data de Emissão"
          value={dateIssue}
          setValue={(e) => { setDateIssue(e) }}
        />


        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Número do documento"
          value={numDoc}
          setValue={(e) => { setNumDoc(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Observação"
          value={observation}
          setValue={(e) => { setObservation(e) }}
        />       

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }
      {showModalAddCustomerSupllier &&
        <ModalSide setOpenModal={setShowModalAddCustomerSupllier} title="Novo Cliente / Fornecedor" >
          <CustomerSupplierAdd setOpenModal={setShowModalAddCustomerSupllier} />
        </ModalSide>
      }
      {showModalAddBankAccount &&
        <ModalSide setOpenModal={setShowModalAddBankAccount} title="Nova Conta Bancária" >
          <BankAccountAdd setOpenModal={setShowModalAddBankAccount} />
        </ModalSide>
      }

    </div >

  )
}

export default RevenueUpdate