import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import Loading from '../../components/Loading'

import BatchAdd from '../BatchAdd'
import BatchUpdate from '../BatchUpdate'

import { useGetBatches, useDestroyBatch } from '../../queries/BatchQueries'

import { usePropertyStore } from '../../stores/propertyStore'

function BatchList() {

  const { selectedProperty } = usePropertyStore()
  const { destroyBatch, destroyBatchRest } = useDestroyBatch()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [selectedBatch, setSelectedBatch] = useState()

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }  

  const { Batches, getBatchesRest } = useGetBatches(property_id)


  const handleDeleteBatch = async () => {

    destroyBatch(selectedBatch.id)
    setShowModalConfirm(false)

  }

  if (getBatchesRest?.isPending && destroyBatchRest?.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Lotes" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>

        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Fazenda</th>
                  <th scope="col" className="px-6 py-3">Nome</th>
                  <th scope="col" className="px-6 py-3">Descrição</th>
                  <th scope="col" className="px-6 py-3">Ativo</th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(Batches) && Batches.map((item, index) => {

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <td scope="row" className="px-6 py-4">{item?.Property?.name}</td>
                      <td className="px-6 py-4">{item?.name}</td>
                      <td className="px-6 py-4">{item?.description}</td>
                      <td className="px-6 py-4">
                        <span className={`${item?.active ? "text-tertiaryBackground" : "text-primaryAlert"} group-hover:text-white group-hover:font-bold text-center`}>
                          {item?.active ? "Sim" : "Não"}
                        </span>
                      </td>

                      <th className=''>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedBatch(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedBatch(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

          </div>

        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Novo Lote" ><BatchAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar Lote"><BatchUpdate setOpenModal={setShowModalUpdate} data={selectedBatch} /></ModalSide>}

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteBatch() }} />}
    </Menu>
  )
}

export default BatchList