import { useQuery, useMutation, useQueryClient, keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetAnimals = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getAnimals', property_id, page, limit]

  const { data: { data: { Animals = [], pagination = {} } = [] } = [], ...getAnimalsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`animals?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { Animals, pagination, getAnimalsRest }
}

// ### ADD

export const useAddAnimal = () => {

  const queryClient = useQueryClient()

  const { mutate: addAnimal, ...addAnimalRest } = useMutation({
    mutationFn: async ({ data, productive_area_id }) => (await api.post(`animals/${productive_area_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAnimals'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addAnimal, addAnimalRest }
}

// ### UPDATE

export const useUpdateAnimal = () => {

  const queryClient = useQueryClient()

  const { mutate: updateAnimal, ...updateAnimalRest } = useMutation({
    mutationFn: async ({ data, animal_id }) => (await api.put(`animals/${animal_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAnimals'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateAnimal, updateAnimalRest }
}

// ### DELETE

export const useDestroyAnimal = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyAnimal, ...destroyAnimalRest } = useMutation({
    mutationFn: async (animal_id) => (await api.delete(`animals/${animal_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAnimals'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyAnimal, destroyAnimalRest }

}
