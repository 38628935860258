import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { setDatePostFormat } from '../../utils'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetAnimals } from '../../queries/AnimalQueries'
import { useAddMating } from '../../queries/MatingQueries'


function MatingAdd(props) {

  const typeList = [
    "Artificial",
    "Monta Natural",
    "Transferência de Embrião",
    "Outro"
  ]

  const statusList = [
    "Não diagnosticado",
    "Confirmada",
    "Falha"
  ]

  const { selectedProperty } = usePropertyStore()

  const { addMating, addMatingRest } = useAddMating()

  const [type, setType] = useState(typeList[0] || "")
  const [date, setDate] = useState("")
  const [status, setStatus] = useState(statusList[0] || "")

  const [mother, setMother] = useState("")
  const [motherListName, setMotherListName] = useState([])
  const [motherName, setMotherName] = useState("")

  const [father, setFather] = useState("")
  const [fatherListName, setFatherListName] = useState([])
  const [fatherName, setFatherName] = useState("")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)
  

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Animals, getAnimalsRest } = useGetAnimals(property_id, 1, 1000000)


  useEffect(() => {   

    if (Animals.length > 0) {

      const motherListData = Animals.map((x) => {
        if (x?.sex === "Fêmea") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)

      setMotherListName(motherListData)

      const fatherListData = Animals.map((x) => {
        if (x?.sex === "Macho") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)

      setFatherListName(fatherListData)

    }

  }, [Animals])


  useEffect(() => {

    if (motherName) {

      const animalSelected = Animals.find(x => {

        if (motherName.indexOf(x.name) > 0 || motherName && motherName === x?.earring) {
          return x
        }
      })

      setMother(animalSelected)

    }

  }, [motherName])

  useEffect(() => {

    if (fatherName) {

      const animalSelected = Animals.find(x => {

        if (fatherName.indexOf(x.name) > 0 || fatherName && fatherName === x?.earring) {
          return x
        }
      })

      setFather(animalSelected)

    }

  }, [fatherName])


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (mother) {
      data.cow_id = mother.id
    }
    else {
      allFieldsFilled = false
    }

    if (father) {
      data.bull_id = father.id
    }

    if (type) {
      data.type = type
    }

    if (date) {
      data.date = setDatePostFormat(date)
    }

    if (allFieldsFilled) {

      addMating(data)

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (getAnimalsRest?.isPending || addMatingRest?.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tipo"
          value={type}
          list={typeList}
          setValue={(e) => { setType(e) }}
        />

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Matriz"
            value={motherName}
            list={motherListName}
            setValue={(e) => { setMotherName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Reprodutor"
            value={fatherName}
            list={fatherListName}
            setValue={(e) => { setFatherName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Status"
          value={status}
          list={statusList}
          setValue={(e) => { setStatus(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data da inseminação"
          value={date}
          setValue={(e) => { setDate(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default MatingAdd