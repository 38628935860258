import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useAddCustomerSupplier } from '../../queries/CustomerSupplierQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { phoneMask, cpfAndCnpjMask, cepMask, countryList, brazilianStatesAndCities } from '../../utils'


function CustomerSupplierAdd(props) {

  const { addCustomerSupplier, addCustomerSupplierRest } = useAddCustomerSupplier()

  const { selectedProperty } = usePropertyStore()

  const [typeList, setTypeList] = useState([])
  const [type, setType] = useState("")

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [cnpj, setCnpj] = useState("")
  const [street, setStreet] = useState("")
  const [number, setNumber] = useState("")
  const [district, setDistrict] = useState("")
  const [country, setCountry] = useState("")
  const [cep, setCep] = useState("")

  const [state, setState] = useState("")
  const [stateList, setStateList] = useState([])

  const [city, setCity] = useState("")
  const [cityList, setCityList] = useState([])

  const [disabledState, setDisabledState] = useState(false)

  const [showModalAddProperty, setShowModalAddProperty] = useState(false) 

  useEffect(() => {   

    const types = [
      "Cliente",
      "Fornecedor"
    ]

    setTypeList(types)
    setType(types[0])

    const listState = brazilianStatesAndCities.map(x => x.nome)
    setStateList(listState)
    setState(listState[0])

  }, [])
 

  useEffect(() => {

    if (state) {
      const list = brazilianStatesAndCities.find((x) => {
        if (x.nome === state) {
          return x
        }
      })

      if (list?.cidades) {
        setCityList(list.cidades)
        setCity(list.cidades[0])
      } else {
        setCityList([])
        setCity("")
      }
    }

  }, [state])

  useEffect(() => {
    if (country !== "Brasil") {
      setDisabledState(true)
    } else {
      setDisabledState(false)
    }
  }, [country])
 
  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (name) {
      data.name = name
    } else {
      allFieldsFilled = false
    }

    if (phone) {
      data.phone = phone.toString().replace(/\D/g, "")
    }

    if (email) {
      data.email = email
    }

    if (cnpj) {
      data.cnpj = cnpj.toString().replace(/\D/g, "")
    }

    if (type) {
      data.type = type
    } else {
      allFieldsFilled = false
    }

    if (street) {
      data.street = street
    }

    if (number) {
      data.number = parseInt(number)
    }

    if (district) {
      data.district = district
    }

    if (country) {
      data.country = country
    }

    if (state) {
      data.state = state
    }

    if (city) {
      data.city = city
    }

    if (cep) {
      data.cep = cep.toString().replace(/\D/g, "")
    }

    if (allFieldsFilled) {

      addCustomerSupplier({ data, property_id: selectedProperty.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (addCustomerSupplierRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>       

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Categoria*"
          value={type}
          list={typeList}
          setValue={(e) => { setType(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Celular"
          value={phone}
          setValue={(e) => { setPhone(phoneMask(e)) }}
        />


        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="E-mail"
          value={email}
          setValue={(e) => { setEmail(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="CPF/CNPJ"
          value={cnpj}
          setValue={(e) => { setCnpj(cpfAndCnpjMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Endereço"
          value={street}
          setValue={(e) => { setStreet(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Número"
          value={number}
          setValue={(e) => { setNumber(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Bairro"
          value={district}
          setValue={(e) => { setDistrict(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Cep"
          value={cep}
          setValue={(e) => { setCep(cepMask(e)) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="País"
          value={country}
          list={countryList}
          setValue={(e) => { setCountry(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Estado"
          value={state}
          list={stateList}
          disabled={disabledState}
          setValue={(e) => { setState(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Cidade"
          value={city}
          list={cityList}
          disabled={disabledState}
          setValue={(e) => { setCity(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default CustomerSupplierAdd