import axios from 'axios'

import { useTokenStore } from '../stores/authStore'

const productionURL = "https://api.gepagro.com"
const devURL = "http://192.168.1.7:4000"
const work = "http://10.52.2.246:4000"

const api = axios.create({
  baseURL: productionURL,
  headers: {
    Authorization: `Bearer ${useTokenStore.getState()?.token
      ? useTokenStore.getState()?.token
      : null
      }`
  }
})

export default api


// PRODUCTION FILE EXAMPLE
// import axios from 'axios'

// const productionURL = "https://api.belezagenda.com.br"
// const devURL = "http://192.168.1.128:3333"

// const api = axios.create({
//   baseURL: productionURL
// })

// export default api
