import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { useGetAnimals } from '../../queries/AnimalQueries'
import { useUpdateMating } from '../../queries/MatingQueries'

import { usePropertyStore } from '../../stores/propertyStore'

function MatingUpdate(props) {

  const selectedMating = props?.data || {}

  const typeList = [
    "Artificial",
    "Monta Natural",
    "Transferência de Embrião",
    "Outro"
  ]

  const statusList = [
    "Não diagnosticado",
    "Confirmada",
    "Falha"
  ]

  const { updateMating, updateMatingRest } = useUpdateMating()

  const { selectedProperty } = usePropertyStore()

  //const { Animals, getAnimalsRest } = useGetAnimals()

  const [type, setType] = useState(selectedMating?.type || "")
  const [date, setDate] = useState("")
  const [status, setStatus] = useState(statusList[0] || "")

  const [mother, setMother] = useState("")
  const [motherListName, setMotherListName] = useState([])
  const [motherName, setMotherName] = useState("")

  const [father, setFather] = useState("")
  const [fatherListName, setFatherListName] = useState([])
  const [fatherName, setFatherName] = useState("")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Animals, getAnimalsRest } = useGetAnimals(property_id, 1, 1000000)

  useEffect(() => {

    if (selectedMating?.date) {
      const date = new Date(selectedMating?.date)
      setDate(`${date?.getFullYear()}-${date?.getMonth() < 10 ? '0' + (date?.getMonth() + 1) : date?.getMonth() + 1}-${date?.getDate() < 10 ? '0' + date?.getDate() : date?.getDate()}`)
    }

  }, [])

  useEffect(() => {

    if (Animals.length > 0) {

      const motherListData = Animals.map((x) => {
        if (x?.sex === "Fêmea") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)
      setMotherListName(motherListData)

      const fatherListData = Animals.map((x) => {
        if (x?.sex === "Macho") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)
      setFatherListName(fatherListData)

      if (selectedMating?.Bull) {
        setFatherName(selectedMating?.Bull?.name ? `${selectedMating?.Bull?.earring} - ${selectedMating?.Bull?.name}` : selectedMating?.Bull?.earring)
      }

      if (selectedMating?.Cow) {
        setMotherName(selectedMating?.Cow?.name ? `${selectedMating?.Cow?.earring} - ${selectedMating?.Cow?.name}` : selectedMating?.Cow?.earring)
      }


    }

  }, [Animals])

  useEffect(() => {

    if (motherName) {

      const animalSelected = Animals.find(x => {

        if (motherName.indexOf(x.name) > 0 || motherName && motherName === x?.earring) {
          return x
        }
      })    

      setMother(animalSelected)

    }

  }, [motherName])

  useEffect(() => {

    if (fatherName) {

      const animalSelected = Animals.find(x => {

        if (fatherName.indexOf(x.name) > 0 || fatherName && fatherName === x?.earring) {
          return x
        }
      })     

      setFather(animalSelected)

    }

  }, [fatherName])


  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (mother?.id && mother?.id !== selectedMating?.cow_id) {
      data.cow_id = mother.id
      dataUpdated = true
    }

    if (father?.id && father?.id !== selectedMating?.bull_id) {
      data.bull_id = father.id
      dataUpdated = true
    }

    if (type && type !== selectedMating?.type) {
      data.type = type
      dataUpdated = true
    }

    const newDate = new Date(selectedMating?.date)
    const newDateFormat = (`${newDate?.getFullYear()}-${newDate?.getMonth() < 10 ? '0' + (newDate?.getMonth() + 1) : newDate?.getMonth() + 1}-${newDate?.getDate() < 10 ? '0' + newDate?.getDate() : newDate?.getDate()}`)

    if (date && date !== newDateFormat) {
      const textDate = date?.replace(/-/g, "/")
      data.date = textDate
      dataUpdated = true
    }

    if (dataUpdated) {

     // console.log(data)

      updateMating({ data, mating_id: selectedMating.id })

      props.setOpenModal(false)

    } else {
      alert('Nenhum dado para ser atualizado!')
    }

  }

  if (updateMatingRest?.isPending || getAnimalsRest?.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tipo"
          value={type}
          list={typeList}
          setValue={(e) => { setType(e) }}
        />

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Matriz"
            value={motherName}
            list={motherListName}
            setValue={(e) => { setMotherName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Reprodutor"
            value={fatherName}
            list={fatherListName}
            setValue={(e) => { setFatherName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Status"
          value={status}
          list={statusList}
          setValue={(e) => { setStatus(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data da inseminação"
          value={date}
          setValue={(e) => { setDate(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default MatingUpdate