import React from 'react'
import { useNavigate } from 'react-router-dom'

import Menu from '../../components/Menu'
import ButtonReport from '../../components/ButtonReport'
import BackgroundData from '../../components/BackgroundData'


function ReportSalesList() {

  const navigate = useNavigate()

  return (
    <Menu title="Relatórios de Vendas">
      <div className='flex lg:flex-col items-center justify-center p-2 py-4 lg:p-4 space-y-8'>

        <BackgroundData>

          <div className='w-full flex flex-col lg:flex-row lg:justify-start items-center gap-3 '>

            <div className='w-64 flex'>
              <ButtonReport
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Receitas por Categorias"
                onClick={() => { navigate("/report_revenue_by_category") }}
              />
            </div>

            <div className='w-64 flex'>
              <ButtonReport
                textColor="text-white"
                backColor="bg-primaryContrast"
                title="Venda de Leite"
                onClick={() => { navigate("/report_milk_sales") }}
              />
            </div>

            {/* <div className='w-64 flex'>
              <ButtonReport
                textColor="text-white"
                backColor="bg-primaryAlert"
                title="Animais a Secar"
                onClick={() => { navigate("/report_animal_stop_lactation") }}
              />
            </div> */}

          </div>
        </BackgroundData>

      </div>
    </Menu>
  )
}

export default ReportSalesList