import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportReproductiveBirthByPeriods = (property_id = 0, start_date, end_date, page = 1, limit = 10) => {

  const QUERY_KEY = ['getReportReproductiveBirthByPeriods', property_id, start_date, end_date, page, limit]  

  const { data: { data: { Calvings = [], pagination: reportReproductiveBirthByPeriodsPagination = {} } = [] } = [], ...getReproductiveBirthByPeriodsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_calving_birth_by_period/${property_id}?start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })  

  return { Calvings, reportReproductiveBirthByPeriodsPagination, getReproductiveBirthByPeriodsRest }
}