import React, { useRef, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'


function InputSelectMultiple(props) {

  const searchElement = useRef(null)



  const [showOptions, setShowOptions] = useState(false)
  const [list, setList] = useState(props.list.map(x => ({ checked: false, name: x })) || [])
  const [disabled, setDisabled] = useState(false)
  const [valueList, setValueList] = useState([])
  const [value, setValue] = useState("")
  const [startList, setStartList] = useState([])

  const borderColor = props.borderColor ? props.borderColor : "white"
  const textColor = props.textColor ? props.textColor : "white"
  const backColor = props.backColor ? props.backColor : "white"

  const inputElement = useRef(null)

  useEffect(() => {
    inputElement.current?.focus()

  //  console.log(props.selectedList)


    setStartList(props.list.map(x => {

      if (props?.selectedList?.indexOf(x) !== -1) {
        return { checked: true, name: x }
      } else {
        return { checked: false, name: x }
      }
      
    }))

    setList(props.list.map(x => {
      if (props?.selectedList && props?.selectedList?.indexOf(x) !== -1) {
        return { checked: true, name: x }
      } else {
        return { checked: false, name: x }
      }      
    }))

  }, [])

  useEffect(() => {   

    setStartList(props.list.map(x => {
      if (props?.selectedList && props?.selectedList?.indexOf(x) !== -1) {
        return { checked: true, name: x }
      } else {
        return { checked: false, name: x }
      }      
    }))

    setList(props.list.map(x => {
      if (props?.selectedList && props?.selectedList?.indexOf(x) !== -1) {
        return { checked: true, name: x }
      } else {
        return { checked: false, name: x }
      }      
    }))

  }, [props.list, props.selectedList])

  useEffect(() => {

    function handleClickOutside(event) {
      if (searchElement.current && !searchElement.current.contains(event.target)) {
        setShowOptions(false)
      } else {
        if (!disabled) {
          setShowOptions(true)
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside)


    return () => {

      document.removeEventListener("mousedown", handleClickOutside)
    }

  }, [searchElement, disabled])

  useEffect(() => {
    if (valueList) {
      props.setValue(valueList)
      // setValue(valueList[0] || "")
      // console.log(valueList)
    }

  }, [valueList])

  useEffect(() => {
    setDisabled(props.disabled)
  }, [props.disabled])

  useEffect(() => {
    setValueList(startList.filter((x) => x.checked === true ? x.name : null).map(y => y.name))

    // console.log(startList)
  }, [startList])

  useEffect(() => {
    if (!showOptions) {
      setValue(valueList[0] || "")
    }
  }, [showOptions])

  const updateValue = (event) => {

    var searchValue = event.target.value

    setValue(event.target.value)

    var newValue = startList.filter((v, i) => {

      const lowerCaseItem = v.name.toString().toLowerCase()
      const lowerCaseValue = searchValue.toString().toLowerCase()

      return (lowerCaseItem.includes(lowerCaseValue))
    })

    setList(newValue)
  }

  function toggleOffer(item) {

    setList(list.map((x) => {
      return x.name === item.name ? { ...x, checked: !x.checked } : x
    }))

    setStartList(startList.map((x) => {
      return x.name === item.name ? { ...x, checked: !x.checked } : x
    }))

  }


  return (

    <div className={`relative group w-full h-12 bg-transparent`} >

      <div className='w-full min-h-12 flex cursor-pointer'
        ref={searchElement}
        disabled={disabled}
      >
        <input
          type="text"
          className={`w-full h-12 bg-transparent ${disabled ? "opacity-50 cursor-default" : "cursor-pointer"} border border-${borderColor} outline-none placeholder:italic pl-4 pr-2 placeholder:text-${textColor} text-${textColor} peer rounded focus:border-2`}
          value={value || ""}
          onChange={e => props.setValue(e.target.value)}
          disabled={disabled}
        />
        <label
          id={`label${props.placeholder}`}
          className={`absolute bg-${backColor} text-xs text-${textColor} ${disabled ? "opacity-80" : "opacity-100"} left-2 top-[-5px] px-2 cursor-text transform transition-all`}>
          {props.placeholder}
        </label>

        <Icon icon="icon-park-solid:down-one" className={`absolute text-${textColor} ${disabled ? "opacity-70" : ""} text-sm right-4 top-5`} />

        {showOptions &&
          <div className={`absolute w-full h-60 overflow-y-auto overflow-x-hidden mt-2 bg-white top-1 left-0 drop-shadow-lg z-10`}>
            <div className='flex w-full h-12 bg-gray-50 mb-4'>
              <input
                autoFocus="autofocus"
                type="text"
                className='`w-full h-12 bg-transparent border-b border-gray-600 outline-none placeholder:italic pl-4 pr-2 placeholder:text-${textColor} text-${textColor} peer'
                placeholder='Pesquisar'
                value={value}
                onChange={event => updateValue(event)}
                ref={inputElement}
              />
            </div>

            <div className='flex flex-col'>

              {Array.isArray(list) && list.length > 0 && list.map((item, index) => {
                return (
                  <div className="flex items-center mb-1 w-full py-2 pl-4 text-left hover:bg-gray-100 z-40" key={item.name} onClick={() => toggleOffer(item)}>
                    <input
                      type="checkbox"
                      value=""
                      checked={item.checked}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 z-0"
                      onChange={() => toggleOffer(item)}
                    />
                    <label className="ms-2 -z-10">{item.name}</label>
                  </div>
                )
              })}


            </div>

          </div>

        }
      </div>
    </div>



  )
}

export default InputSelectMultiple



