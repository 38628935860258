import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'


function InputBoolean(props) {

  const [active, setActive] = useState(props?.value || false)

  useEffect(() => {
   // console.log(active)
    props?.setValue(active)
  }, [active])


  return (

    <label className="relative inline-flex items-center cursor-pointer space-x-3 py-2" onClick={() => setActive(prev => !prev)}  defaultChecked={active}>     
      <span className="ms-3 text-base font-medium text-primaryBackground dark:text-gray-300">{props.title}</span>
      {
        active ? 
        <Icon icon="material-symbols:thumb-up" className='text-3xl text-tertiaryBackground' /> :
        <Icon icon="material-symbols:thumb-down" className='text-3xl text-primaryAlert' />
      }
     
    </label>

  )
}

export default InputBoolean