import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'


function ButtonReport(props) {

  const [backColorClass, setBackColorClass] = useState(props.backColor && props.backColor != "" ? props.backColor : 'bg-primaryBackground')
  const [textColorClass, setTextColorClass] = useState(props.textColor && props.textColor != "" ? props.textColor : 'text-white')

  useEffect(() => {
    setBackColorClass(props.backColor && props.backColor != "" ? props.backColor : 'bg-primaryBackground')
    setTextColorClass(props.textColor && props.textColor != "" ? props.textColor : 'text-white')
  }, [props.backColor, props.textColor])


  return (
    <button
      className={`flex-1 ${backColorClass} ${textColorClass} text-xl rounded flex items-center justify-center lg:h-12 h-16 space-x-2 hover:opacity-80`}
      onClick={() => props.onClick()}
    >
      {props.icon && <Icon icon={props.icon} className='text-3xl text-white' />}
      {props.loading ? <Icon icon="eos-icons:bubble-loading" className={`text-2xl`} />
        : <p className='text-base'>{props.title}</p>}       
    </button>
  )
}

export default ButtonReport