export const productiveAreaUseList = [
  "Pasto",
  "Piquete",
  "Milho",
  "Sorgo",
  "Bezerros desmamados",
  "Bezerras desmamadas",
  "Novilhas",
  "Recria",
  "Garrotes",
  "Matrizes",
  "Reprodutores",
  "Fêmeas vazias",
  "Fêmeas prenhas",
  "Fêmeas pré - parto",
  "Fêmeas pós - parto",
  "Bezerros em aleitamento",
  "Bezerras em aleitamento"
]

export const investimentTypeList = [
  "Maquinário",
  "Veículos",
  "Silos",
  "Benfeitorias",
  "Imóvel",
  "Terras",
  "Animais"
]

export const sexList = [
  "Macho",
  "Fêmea"
]

export const breedList = [
  "Holandes Preto e Branco",
  "Girolando",
  "Gir Leiteiro",
  "Jersey",
  "Guernsey",
  "Ayrshire",
  "Caracu",
  "Simental",
  "Mestiça",
  "Pardo Suíça (Schwyz)",
  "Jersolando",
  "Nelore",
  "Angus",
  "Red Angus",
  "Aberdeen Angus",
  "Hereford",
  "Tabapuã",
  "Senepol",
  "Brahman",
  "Kiwi",
  "Friesian",
  "Guzerá",
  "Guzolando",
  "F1",
  "S1",
  "Desconhecida",
  "Brangus",
  "Cruzamento Industrial",
  "Murrah",
  "Jafarabadi",
  "Purunã",
  "Pardo Alpina",
  "Saanen",
  "Santa Inês",
  "Gir",
  "White Dorper",
  "Black Dorper",
  "Poll Dorset",
  "Tricross",
  "Holandês Vermelho",
  "Braford",
  "Norueguês Vermelho",
  "Outra",
  "Montbeliard",
  "Charolês",
  "Normando",
  "Composto",
  "Zebuíno",
  "Tabolanda",
  "Guzerá Leiteiro",
  "Sindi",
  "Sindolando",
  "Nelorando",
  "Sinjer",
  "Nelore Pintado",
  "Canchim",
  "Waguyu",
  "Nelore Mocho",
  "Mini Gado",
  "Nelore Pintado Preto",
  "Nelore Pintado Vermelho",
  "Mediterrâneo",
  "Holandês Frísia"
]

export const originList = [
  "Nascimento",
  "Compra",
  "Arrendamento"
]

export const bloodDegreesList = [
  "Desconhecido",
  "1/8",
  "1/4",
  "3/8",
  "7/16",
  "1/2",
  "9/16",
  "5/8",
  "11/16",
  "3/4",
  "13/16",
  "7/8",
  "15/16",
  "31/32",
  "PC",
  "PCOD",
  "PCCOC",
  "PO",
  "LA",
  "CG",
  "63/64",
  "127/128",
  "255/256",
  "GC-01",
  "GC-02",
  "GC-03",
  "GC-04",
  "GC-05",
  "GC-06",
  "GC-07",
  "17/32",
  "23/32",
  "25/32",
  "41/64",
  "57/64",
  "1023/1024",
  "63/37",
  "50/50",
  "75/25",
  "88/12",
  "81/19",
  "62/37",
  "56/44",
  "56/43",
  "72/28",
  "25/25",
  "31/18",
  "69/31",
  "PS",
  "CEIP",
  "GC-10",
  "GC-08",
  "GC-09",
  "SRD",
  "31/32 PCOD/PA",
  "3/8 Z + 5/8 A",
  "POI",
  "5/8 H + 3/8 G"
]

export const installmentList = Array.from(Array(200).keys()).filter(item => item !== 0)

export const taggleAccordion = (id) => {

  let elements = document.querySelectorAll(`[id=${id}]`)

  for (var i = 0; i < elements.length; i++) {

    if (elements[i].className.search("hidden") !== -1) {
      elements[i].className = elements[i].className.replace("hidden", "table-row")
    } else if (elements[i].className.search("table-row") !== -1) {
      elements[i].className = elements[i].className.replace("table-row", "hidden")
    }

  }

}