import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetUsers = (property_id) => {

  const QUERY_KEY = ['getUsers', property_id]

  const { data: { data: Users = [] } = [], ...getUsersRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`users?property_id=${property_id}`)),
    placeholderData: keepPreviousData,
  })

  return { Users, getUsersRest }
}

// ### ADD

export const useAddUser = () => {

  const queryClient = useQueryClient()

  const { mutateAsync: addUser, ...addUserRest } = useMutation({
    mutationFn: async ({ data, user_type_id }) => (await api.post(`users/${user_type_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsers'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addUser, addUserRest }
}

// ### ADD USER BY USER

export const useAddUserByUser = () => {

  const queryClient = useQueryClient()

  const { mutate: addUserByUser, ...addUserByUserRest } = useMutation({
    mutationFn: async ({ data, user_type_id }) => (await api.post(`user_by_user/${user_type_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsers'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addUserByUser, addUserByUserRest }
}

// ### UPDATE

export const useUpdateUser = () => {

  const queryClient = useQueryClient()

  const { mutate: updateUser, ...updateUserRest } = useMutation({
    mutationFn: async ({ data, user_id }) => (await api.put(`users/${user_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsers'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { updateUser, updateUserRest }

}

// ### DELETE

export const useDestroyUser = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyUser, ...destroyUserRest } = useMutation({
    mutationFn: async (user_id) => (await api.delete(`users/${user_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsers'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyUser, destroyUserRest }

}





