import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { usePropertyStore } from '../../stores/propertyStore'

import { useUpdateMilkQuality } from '../../queries/MilkQualityQueries'

import { setDatePostFormat, brazilianCurrencyMask, percentageMask, toUSACurrency, toBrazilianCurrency, setInputMonthAndYearFormat } from '../../utils'

function MilkQualityUpdate(props) {

  const selectedMilkQuality = props?.data || {}

  const { selectedProperty } = usePropertyStore()

  const { updateMilkQuality, updateMilkQualityRest } = useUpdateMilkQuality()

  const [date, setDate] = useState(setInputMonthAndYearFormat(selectedMilkQuality?.date))
  const [protein, setProtein] = useState(selectedMilkQuality?.protein ? toBrazilianCurrency(selectedMilkQuality?.protein * 100) : "")
  const [proteinPercentage, setProteinPercentage] = useState(selectedMilkQuality?.protein_percentage ? toBrazilianCurrency(selectedMilkQuality?.protein_percentage * 100) : "")
  const [fatPercentage, setFatPercentage] = useState(selectedMilkQuality?.fat_percentage ? toBrazilianCurrency(selectedMilkQuality?.fat_percentage * 100) : "")
  const [fat, setFat] = useState(selectedMilkQuality?.fat ? toBrazilianCurrency(selectedMilkQuality?.fat * 100) : "")
  const [lactose, setLactose] = useState(selectedMilkQuality?.lactose ? toBrazilianCurrency(selectedMilkQuality?.lactose * 100) : "")
  const [solitos, setSolitos] = useState(selectedMilkQuality?.solitos ? toBrazilianCurrency(selectedMilkQuality?.solitos * 100) : "")
  const [urea, setUrea] = useState(selectedMilkQuality?.urea ? toBrazilianCurrency(selectedMilkQuality?.urea * 100) : "")
  const [ccs, setCcs] = useState(selectedMilkQuality?.ccs ? selectedMilkQuality?.ccs : "")
  const [cbt, setCbt] = useState(selectedMilkQuality?.cbt ? selectedMilkQuality?.cbt : "")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  useEffect(() => {

    if (selectedMilkQuality?.date) {
      setInputMonthAndYearFormat(selectedMilkQuality?.date)
    }

    console.log(setInputMonthAndYearFormat(selectedMilkQuality?.date))

  }, [selectedMilkQuality])

  const submit = async () => {

    let data = {}
    let dataUpdated = false

    const newDate = new Date(selectedMilkQuality?.date)
    const newDateFormat = setInputMonthAndYearFormat(newDate)

    if (date && date !== newDateFormat) {
      const newDate = date + '-15'
      data.date = setDatePostFormat(newDate)
      dataUpdated = true
    }

    if (protein && selectedMilkQuality?.protein !== toUSACurrency(protein)) {
      data.protein = toUSACurrency(protein)
      dataUpdated = true
    }

    if (proteinPercentage && selectedMilkQuality?.protein_percentage !== toUSACurrency(proteinPercentage)) {
      data.protein_percentage = toUSACurrency(proteinPercentage)
      dataUpdated = true
    }

    if (fat && selectedMilkQuality?.fat !== toUSACurrency(fat)) {
      data.fat = toUSACurrency(fat)
      dataUpdated = true
    }

    if (fatPercentage && selectedMilkQuality?.fat_percentage !== toUSACurrency(fatPercentage)) {
      data.fat_percentage = toUSACurrency(fatPercentage)
      dataUpdated = true
    }

    if (lactose && selectedMilkQuality?.lactose !== toUSACurrency(lactose)) {
      data.lactose = toUSACurrency(lactose)
      dataUpdated = true
    }

    if (solitos && selectedMilkQuality?.solitos !== toUSACurrency(solitos)) {
      data.solitos = toUSACurrency(solitos)
      dataUpdated = true
    }

    if (urea && selectedMilkQuality?.urea !== toUSACurrency(urea)) {
      data.urea = toUSACurrency(urea)
      dataUpdated = true
    }

    if (ccs && selectedMilkQuality?.ccs !== toUSACurrency(ccs)) {
      data.ccs = toUSACurrency(ccs)
      dataUpdated = true
    }

    if (cbt && selectedMilkQuality?.cbt !== toUSACurrency(cbt)) {
      data.cbt = toUSACurrency(cbt)
      dataUpdated = true
    }

    if (dataUpdated) {

      updateMilkQuality({ data, milk_quality_id: selectedMilkQuality?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (updateMilkQualityRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <Input
          type="month"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data da coleta*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Proteína (KG)*"
          value={protein}
          setValue={(e) => { setProtein(brazilianCurrencyMask(e)) }}
        />
        
        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Proteína (%)*"
          value={proteinPercentage}
          setValue={(e) => { setProteinPercentage(percentageMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Gordura (KG)*"
          value={fat}
          setValue={(e) => { setFat(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Gordura (%)*"
          value={fatPercentage}
          setValue={(e) => { setFatPercentage(percentageMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Lactose (%)*"
          value={lactose}
          setValue={(e) => { setLactose(percentageMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Sólitos (%)*"
          value={solitos}
          setValue={(e) => { setSolitos(percentageMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Ureia no leite(mg/dl)*"
          value={urea}
          setValue={(e) => { setUrea(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="CCS*"
          value={ccs}
          setValue={(e) => { setCcs(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="CBT*"
          value={cbt}
          setValue={(e) => { setCbt(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default MilkQualityUpdate