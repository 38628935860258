import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import Loading from '../../components/Loading'

import UserAdd from '../UserAdd'
import UserUpdate from '../UserUpdate'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetUsers, useDestroyUser } from '../../queries/UserQueries'

function UserList() {

  const { selectedProperty } = usePropertyStore()

  const { destroyUser, destroyUserRest } = useDestroyUser()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [selectedUser, setSelectedUser] = useState()

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Users, getUsersRest } = useGetUsers(property_id)

  const handleDeleteRevenue = async () => {

    destroyUser(selectedUser.id)

    setShowModalConfirm(false)
  }

  if(getUsersRest.isPending || destroyUserRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Usuários" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>


        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Nome</th>
                  <th scope="col" className="px-6 py-3">E-mail</th>
                  <th scope="col" className="px-6 py-3">Telefone</th>
                  <th scope="col" className="px-6 py-3">Ativo</th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(Users) && Users.map((item, index) => {

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <td className="px-6 py-4">{item?.name}</td>
                      <td className="px-6 py-4">{item?.email}</td>
                      <td className="px-6 py-4">{item?.phone}</td>
                      <td className="px-6 py-4">{item?.active ? "Sim" : "Não"}</td>

                      <th className=''>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedUser(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedUser(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

          </div>

        </BackgroundData>


      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Novo Usuário" ><UserAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar Usuário"><UserUpdate setOpenModal={setShowModalUpdate} data={selectedUser} /></ModalSide>}

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteRevenue() }} />}
    </Menu>
  )
}

export default UserList