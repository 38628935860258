import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportProductiveMonthMilkQualities = (property_id = 0, year) => {

  const QUERY_KEY = ['getReportProductiveMonthMilkQualities', property_id, year]  

  const { data: { data: MonthMilkQualities = [] } = [], ...getReportProductiveMonthMilkQualitiesRest } = useQuery({    
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_milk_quality/${property_id}?year=${year}`)),
    placeholderData: keepPreviousData,
  })  
    
  return { MonthMilkQualities, getReportProductiveMonthMilkQualitiesRest }
}