import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import DailyProductionAdd from '../DailyProductionAdd'
import DailyProductionUpdate from '../DailyProductionUpdate'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetDailyProductions, useDestroyDailyProduction } from '../../queries/DailyProductionQueries'

import { toBrazilianCurrency, setDateBrazilianFormat } from '../../utils'

function DailyProductionList() {

  const { selectedProperty } = usePropertyStore()

  const { destroyDailyProduction, destroyDailyProductionRest } = useDestroyDailyProduction()

  const [selectedDailyProduction, setSelectedDailyProduction] = useState()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { dailyProductionsPagination, DailyProductions, getDailyProductionsRest } = useGetDailyProductions(property_id, page, limit)


  const handleDeleteDailyProduction = async () => {

    destroyDailyProduction(selectedDailyProduction.id)

    setShowModalConfirm(false)
  }

  if (getDailyProductionsRest.isPending || destroyDailyProductionRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Produção diária" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>

        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Data</th>
                  <th scope="col" className="px-6 py-3">Litros Entregues</th>
                  <th scope="col" className="px-6 py-3">Litros Descartados</th>
                  <th scope="col" className="px-6 py-3">Consumo Interno</th>
                  <th scope="col" className="px-6 py-3">Consumo Bezerros</th>
                  <th scope="col" className="px-6 py-3">Total Produzido</th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(DailyProductions) && DailyProductions.map((item, index) => {

                const totalLiters = ((parseInt(item?.liters_delivered || 0) + parseInt(item?.liters_consumed_calves || 0) + parseInt(item?.liters_consumed_internally || 0) + parseInt(item?.liters_discarted || 0)))

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <td className="px-6 py-4">{setDateBrazilianFormat(item?.date)}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(item?.liters_delivered)}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(item?.liters_discarted)}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(item?.liters_consumed_internally)}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(item?.liters_consumed_calves)}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(totalLiters)}</td>

                      <th className=''>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedDailyProduction(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedDailyProduction(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

            <div className='flex items-center justify-center'>
              <ButtonsPagination

                data={dailyProductionsPagination}
                page={(page) => { setPage(page) }}
                limit={(limit) => { setLimit(limit) }}
              />
            </div>

          </div>

        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Nova Produção Diária" ><DailyProductionAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar Produção diária"><DailyProductionUpdate setOpenModal={setShowModalUpdate} data={selectedDailyProduction} /></ModalSide>}

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteDailyProduction() }} />}
    </Menu>
  )
}

export default DailyProductionList