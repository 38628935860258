import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import Loading from '../../components/Loading'

import { useAddProductiveArea } from '../../queries/ProductiveAreaQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { productiveAreaUseList } from '../../utils'

function ProductiveAreaAdd(props) {

  const { selectedProperty } = usePropertyStore()
  const { addProductiveArea, addProductiveAreaRest } = useAddProductiveArea()

  const [use, setUse] = useState(productiveAreaUseList[0] || "")
  const [name, setName] = useState("")

  const [size, setSize] = useState("")


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    use ? data.use = use : allFieldsFilled = false
    size ? data.size = size : allFieldsFilled = false
    name ? data.name = name : allFieldsFilled = false 

    if (allFieldsFilled) {     

      addProductiveArea({ data, property_id: selectedProperty?.id })
      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (addProductiveAreaRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Uso*"
          value={use}
          list={productiveAreaUseList}
          setValue={(e) => { setUse(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tamanho em hectares*"
          value={size}
          setValue={(e) => { setSize(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

    </div>

  )
}

export default ProductiveAreaAdd