import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import axiosInterceptorsGetToken from '../config/interceptorGetToken'

import api from '../config/api'

// #### GET

export const useGetProperties = () => {

  axiosInterceptorsGetToken()

  const QUERY_KEY = ['getProperties']

  const { data: { data: properties = [] } = [], ...getPropertiesRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`properties`)),
    placeholderData: keepPreviousData,
  })

  return { properties, getPropertiesRest }
}

// ### ADD

export const useAddProperty = () => {

  const queryClient = useQueryClient()

  const { mutate: addProperty, ...addPropertyRest } = useMutation({
    mutationFn: async (data) => (await api.post('properties', data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProperties'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addProperty, addPropertyRest }
}

// ### UPDATE

export const useUpdateProperty = () => {

  const queryClient = useQueryClient()

  const { mutate: updateProperty, ...updatePropertyRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.put(`properties/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProperties'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { updateProperty, updatePropertyRest }

}

// ### DELETE

export const useDestroyProperty = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyProperty, ...destroyPropertyRest } = useMutation({
    mutationFn: async (property_id) => (await api.delete(`properties/${property_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProperties'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyProperty, destroyPropertyRest }

}





