export const toggleElement = (id) => {

    let element = document.getElementById(id)

    let nowClassName = element.className

    if (nowClassName.search("hidden") !== -1) {
      nowClassName = nowClassName.replace("hidden", "flex")
      element.className = nowClassName
    } else if (nowClassName.search("flex") !== -1) {
      nowClassName = nowClassName.replace("flex", "hidden")
      element.className = nowClassName
    }

  }