import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportProductiveAnimalStopLactations = (property_id = 0, start_date, end_date, page = 1, limit = 10) => {

  const QUERY_KEY = ['getReportProductiveAnimalStopLactations', property_id, start_date, end_date, page, limit]  

  const { data: { data: { StopLactations = [], pagination: reportProductiveAnimalStopLactationsPagination = {} } = [] } = [], ...getReportProductiveAnimalStopLactationsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_animal_stop_lactation/${property_id}?start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })  

  return { StopLactations, reportProductiveAnimalStopLactationsPagination, getReportProductiveAnimalStopLactationsRest }
}