import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const usePropertyStore = create(
  persist(
    (set) => ({
      selectedProperty: {},
      setSelectedProperty: (selectedProperty) => set(() => ({ selectedProperty }))      
    }),
    {
      name: 'selectedProperty'
    }
  )
)

