import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportProductiveYearAnimalProductions = (property_id = 0, year) => {

  const QUERY_KEY = ['getReportProductiveYearAnimalProductions', property_id, year]  

  const { data: { data: YearAnimalProductions = [] } = [], ...getReportProductiveYearAnimalProductionsRest } = useQuery({    
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_year_animal_production/${property_id}/${year}`)),
    placeholderData: keepPreviousData,
  })  
    
  return { YearAnimalProductions, getReportProductiveYearAnimalProductionsRest }
}