import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import Loading from '../../components/Loading'

import { countryList, brazilianStatesAndCities } from '../../utils'

import { useUpdateProperty } from '../../queries/PropertyQueries'

function PropertyUpdate(props) {

  const { updateProperty, updatePropertyRest } = useUpdateProperty()

  const [name, setName] = useState(props?.data?.name || "")
  const [size, setSize] = useState(props?.data?.size || "")
  const [country, setCountry] = useState(props?.data?.country || countryList[0])
  const [state, setState] = useState(props?.data?.state || [])
  const [stateList, setStateList] = useState([])
  const [city, setCity] = useState(props?.data?.city || "")
  const [cityList, setCityList] = useState([])
  const [disabledState, setDisabledState] = useState(false)
  const [owner, setOwner] = useState(props?.data?.owner || "")
  const [phone, setPhone] = useState(props?.data?.phone || "")

  useEffect(() => {
    const list = brazilianStatesAndCities.map(x => x.nome)
    setStateList(list)
    setState(props?.data?.state || list[0])
  }, [])

  useEffect(() => {
    if (country !== "Brasil") {
      setDisabledState(true)
    } else {
      setDisabledState(false)
    }
  }, [country])

  useEffect(() => {

    if (state) {
      const list = brazilianStatesAndCities.find((x) => {
        if (x.nome === state) {
          return x
        }
      })

      if (list?.cidades) {
        setCityList(list.cidades)
        setCity(state != props?.data?.state ? list.cidades[0] : props?.data?.city)
      } else {
        setCityList([])
        setCity("")
      }
    }

  }, [state])


  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (name && name != props?.data?.name) {
      data.name = name
      dataUpdated = true
    }

    if (size && size != props?.data?.size) {
      data.size = size
      dataUpdated = true
    }

    if (owner && owner != props?.data?.owner) {
      data.owner = owner
      dataUpdated = true
    }

    if (phone && phone != props?.data?.phone) {
      data.phone = phone
      dataUpdated = true
    }

    if (country && country != props?.data?.country) {
      data.country = country
      dataUpdated = true
    }


    if (country === "Brasil") {

      if (state != props?.data?.state) {
        data.state = state
        dataUpdated = true
      }

      if (city != props?.data?.city) {
        data.city = city
        dataUpdated = true
      }
    }


    if (dataUpdated) {

      updateProperty({ data, property_id: props?.data?.id })

      props.setOpenModal(false)

    } else {
      alert('Nenhum dado foi modificado!')
    }

  }

  // if(updatePropertyRest.isPending) {
  //   return <Loading />
  // }

  return (
    <div className='space-y-12'>
      <div className='space-y-3'>
        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome da propriedade*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tamanho em hectares*"
          value={size}
          setValue={(e) => { setSize(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome do proprietário*"
          value={owner}
          setValue={(e) => { setOwner(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Celular"
          value={phone}
          setValue={(e) => { setPhone(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="País*"
          value={country}
          list={countryList}
          setValue={(e) => { setCountry(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Estado*"
          value={state}
          list={stateList}
          disabled={disabledState}
          setValue={(e) => { setState(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Cidade*"
          value={city}
          list={cityList}
          disabled={disabledState}
          setValue={(e) => { setCity(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

    </div>

  )
}

export default PropertyUpdate