import React, { useEffect, useState } from 'react'

import Menu from '../../components/Menu'
import InputSelect from '../../components/InputSelect'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Input from '../../components/Input'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import { useGetReportReproductiveBirthByPeriods } from '../../queries/ReportReproductiveBirthByPeriodQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { setInputDateFormat, setDateBrazilianFormat } from '../../utils'

function ReportReproductiveBirthByPeriod() {

  const { selectedProperty } = usePropertyStore()

  var date = new Date()
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const [startDate, setStartDate] = useState(setInputDateFormat(firstDay))
  const [endDate, setEndDate] = useState(setInputDateFormat(lastDay))

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const {
    Calvings, reportReproductiveBirthByPeriodsPagination, getReproductiveBirthByPeriodsRest
  } = useGetReportReproductiveBirthByPeriods(selectedProperty?.id, setInputDateFormat(startDate || new Date(date.getFullYear(), date.getMonth(), 1)), setInputDateFormat(endDate || new Date(date.getFullYear(), date.getMonth() + 1, 0)), page, limit)

  if (getReproductiveBirthByPeriodsRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Nascimento por Período">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <div className='w-full flex flex-col justify-start space-y-6'>

          <BackgroundData>

            <div className='w-full flex flex-col lg:flex-row space-y-8 lg:space-y-0 space-x-4 items-center justify-start'>

              <div className='lg:w-[50%] flex space-x-4'>

                <div className='flex-1'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="De"
                    value={startDate}
                    setValue={(e) => { setStartDate(e) }}
                    className="flex-1"
                  />
                </div>

                <div className='flex-1'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="Até"
                    value={endDate}
                    setValue={(e) => { setEndDate(e) }}
                  />
                </div>
              </div>

            </div>
          </BackgroundData>

          <BackgroundData>

            <div className="relative overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ">
                <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                  <tr>
                    <th scope="col" className="px-6 py-3">Nome</th>
                    <th scope="col" className="px-6 py-3">Data de Nascimento</th>
                    <th scope="col" className="px-6 py-3">Sexo</th>
                    <th scope="col" className="px-6 py-3">Raça</th>
                    <th scope="col" className="px-6 py-3">Mãe</th>
                    <th scope="col" className="px-6 py-3">Pai</th>
                  </tr>
                </thead>

                {Calvings?.length > 0 && Calvings.map((item, index) => {

                  return (
                    <tbody key={index}>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{item?.name}</th>
                        <td className="px-6 py-4">{setDateBrazilianFormat(item?.birth_date)}</td>
                        <td className="px-6 py-4">{item?.sex}</td>
                        <td className="px-6 py-4">{item?.breed}</td>
                        <td className="px-6 py-4">{item?.Cow?.name ? `${item?.Cow?.earring} - ${item?.Cow?.name}` : item?.Cow?.earring || ""}</td>
                        <td className="px-6 py-4">{item?.Bull?.name ? `${item?.Bull?.earring} - ${item?.Bull?.name}` : item?.Bull?.earring || ""}</td>
                      </tr>
                    </tbody>
                  )
                })}


              </table>

              <div className='flex items-center justify-center'>

                <ButtonsPagination
                  data={reportReproductiveBirthByPeriodsPagination}
                  page={(page) => { setPage(page) }}
                  limit={(limit) => { setLimit(limit) }}
                />

              </div>


            </div>
          </BackgroundData>

        </div>

      </div>
    </Menu>
  )
}

export default ReportReproductiveBirthByPeriod