import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { usePropertyStore } from '../../stores/propertyStore'

import { useAddMilkQuality } from '../../queries/MilkQualityQueries'

import { setDatePostFormat, brazilianCurrencyMask, percentageMask, toUSACurrency } from '../../utils'

function MilkQualityAdd(props) {

  const { selectedProperty } = usePropertyStore()

  const { addMilkQuality, addMilkQualityRest } = useAddMilkQuality()

  const [date, setDate] = useState("")
  const [protein, setProtein] = useState("")
  const [proteinPercentage, setProteinPercentage] = useState("")
  const [fatPercentage, setFatPercentage] = useState("")
  const [fat, setFat] = useState("")
  const [lactose, setLactose] = useState("")
  const [solitos, setSolitos] = useState("")
  const [urea, setUrea] = useState("")
  const [ccs, setCcs] = useState("")
  const [cbt, setCbt] = useState("")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  useEffect(() => {
    console.log(date)
  }, [date])

  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (date) {
      const newDate = date + '-15'
      data.date = setDatePostFormat(newDate)
    } else {
      allFieldsFilled = false
    }

    if (protein) {
      data.protein = toUSACurrency(protein)
    } else {
      allFieldsFilled = false
    }

    if (proteinPercentage) {
      data.protein_percentage = toUSACurrency(proteinPercentage)
    } else {
      allFieldsFilled = false
    }

    if (fat) {
      data.fat = toUSACurrency(fat)
    } else {
      allFieldsFilled = false
    }

    if (fatPercentage) {
      data.fat_percentage = toUSACurrency(fatPercentage)
    } else {
      allFieldsFilled = false
    }

    if (lactose) {
      data.lactose = toUSACurrency(lactose)
    } else {
      allFieldsFilled = false
    }

    if (solitos) {
      data.solitos = toUSACurrency(solitos)
    } else {
      allFieldsFilled = false
    }

    if (urea) {
      data.urea = toUSACurrency(urea)
    } else {
      allFieldsFilled = false
    }

    if (ccs) {
      data.ccs = toUSACurrency(ccs)
    } else {
      allFieldsFilled = false
    }

    if (cbt) {
      data.cbt = toUSACurrency(cbt)
    } else {
      allFieldsFilled = false
    }


    if (allFieldsFilled) {

      addMilkQuality({ data, property_id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (addMilkQualityRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <Input
          type="month"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Mês da coleta*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Proteína (KG)*"
          value={protein}
          setValue={(e) => { setProtein(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Proteína (%)*"
          value={proteinPercentage}
          setValue={(e) => { setProteinPercentage(percentageMask(e)) }}
        />


        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Gordura (KG)*"
          value={fat}
          setValue={(e) => { setFat(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Gordura (%)*"
          value={fatPercentage}
          setValue={(e) => { setFatPercentage(percentageMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Lactose (%)*"
          value={lactose}
          setValue={(e) => { setLactose(percentageMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Sólidos (%)*"
          value={solitos}
          setValue={(e) => { setSolitos(percentageMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Ureia no leite(mg/dl)*"
          value={urea}
          setValue={(e) => { setUrea(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="CCS*"
          value={ccs}
          setValue={(e) => { setCcs(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="CBT*"
          value={cbt}
          setValue={(e) => { setCbt(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default MilkQualityAdd