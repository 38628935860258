import React from 'react'

function BackgroundData(props) {

  return (
    <div className='w-full rounded-xl bg-white shadow-md p-4'>
      {props.children}
    </div>

  )
}

export default BackgroundData