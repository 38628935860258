import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import Loading from '../../components/Loading'

import { useUpdateProductiveArea } from '../../queries/ProductiveAreaQueries'

import { productiveAreaUseList } from '../../utils'

function ProductiveAreaUpdate(props) {

  const selectedProductiveArea = props?.data

  const { updateProductiveArea, updateProductiveAreaRest } = useUpdateProductiveArea()

  const [use, setUse] = useState(selectedProductiveArea?.use || productiveAreaUseList[0] || "")
  const [size, setSize] = useState(selectedProductiveArea?.size || "")
  const [name, setName] = useState(selectedProductiveArea?.name || "")


  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (size && size != selectedProductiveArea?.size) {
      data.size = size
      dataUpdated = true
    }

    if (use && use != selectedProductiveArea?.use) {
      data.use = use
      dataUpdated = true
    }

    if (name && name != selectedProductiveArea?.name) {
      data.name = name
      dataUpdated = true
    }


    if (dataUpdated) {

      updateProductiveArea({ data, productive_area_id: selectedProductiveArea?.id })

      props.setOpenModal(false)

    } else {
      alert('Nenhum dado foi modificado!')
    }

  }

  if (updateProductiveAreaRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-12'>
      <div className='space-y-3'>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Uso*"
          value={use}
          list={productiveAreaUseList}
          setValue={(e) => { setUse(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tamanho em hectares*"
          value={size}
          setValue={(e) => { setSize(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

    </div>

  )
}

export default ProductiveAreaUpdate