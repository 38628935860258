import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import MilkQualityAdd from '../MilkQualityAdd'
import MilkQualityUpdate from '../MilkQualityUpdate'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetMilkQualities, useDestroyMilkQuality } from '../../queries/MilkQualityQueries'

import { setDateBrazilianFormat, brazilianCurrencyMask, setMonthAndYearBrazilianFormat } from '../../utils'


function MilkQualityList() {

  const { selectedProperty } = usePropertyStore()

  const { destroyMilkQuality, destroyMilkQualityRest } = useDestroyMilkQuality()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [selectedMilkQuality, setSelectedMilkQuality] = useState()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { MilkQualities, getMilkQualitiesRest, milkQualitiesPagination } = useGetMilkQualities(property_id, page, limit)


  const handleDeleteMilkQuality = async () => {

    destroyMilkQuality(selectedMilkQuality?.id)

    setShowModalConfirm(false)
  }

  if (destroyMilkQualityRest.isPending || getMilkQualitiesRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Qualidade do Leite Mensal" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>

        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground truncate">
                <tr>                  
                  <th scope="col" className="px-6 py-3">Mês da coleta</th>
                  <th scope="col" className="px-6 py-3">Proteína (KG)</th>
                  <th scope="col" className="px-6 py-3">Proteína (%)</th>
                  <th scope="col" className="px-6 py-3">Gordura (KG)</th>
                  <th scope="col" className="px-6 py-3">Gordura (%)</th>
                  <th scope="col" className="px-6 py-3">Lactose (%)</th>
                  <th scope="col" className="px-6 py-3">Sólidos (%)</th>
                  <th scope="col" className="px-6 py-3 normal-case">UREIA (mg/dl)</th>
                  <th scope="col" className="px-6 py-3">CCS</th>
                  <th scope="col" className="px-6 py-3">CBT</th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(MilkQualities) && MilkQualities.map((item, index) => {

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b truncate">                     
                      <td className="px-6 py-4">{setMonthAndYearBrazilianFormat(item?.date)}</td>
                      <td className="px-6 py-4">{item?.protein ? brazilianCurrencyMask(+parseFloat(item?.protein * 100).toFixed(2)) : "-"}</td>
                      <td className="px-6 py-4">{item?.protein_percentage ? brazilianCurrencyMask(+parseFloat(item?.protein_percentage * 100).toFixed(2)) : "-"}</td>
                      <td className="px-6 py-4">{item?.fat ? brazilianCurrencyMask(+parseFloat(item?.fat * 100).toFixed(2)) : "-"}</td>
                      <td className="px-6 py-4">{item?.fat_percentage ? brazilianCurrencyMask(+parseFloat(item?.fat_percentage * 100).toFixed(2)) : "-"}</td>
                      <td className="px-6 py-4">{item?.lactose ? brazilianCurrencyMask(+parseFloat(item?.lactose * 100).toFixed(2)) : "-"}</td>
                      <td className="px-6 py-4">{item?.solitos ? brazilianCurrencyMask(+parseFloat(item?.solitos * 100).toFixed(2)) : "-"}</td>
                      <td className="px-6 py-4">{item?.urea ? brazilianCurrencyMask(+parseFloat(item?.urea * 100).toFixed(2)) : "-"}</td>
                      <td className="px-6 py-4">{item?.ccs}</td>
                      <td className="px-6 py-4">{item?.cbt}</td>

                      <th className=''>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {    
                              setSelectedMilkQuality(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedMilkQuality(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

            <div className='flex items-center justify-center'>
              <ButtonsPagination

                data={milkQualitiesPagination}
                page={(page) => { setPage(page) }}
                limit={(limit) => { setLimit(limit) }}
              />
            </div>

          </div>
        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Nova qualidade do leite" ><MilkQualityAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar qualidade do leite"><MilkQualityUpdate setOpenModal={setShowModalUpdate} data={selectedMilkQuality} /></ModalSide>}

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteMilkQuality() }} />}
    </Menu>
  )
}

export default MilkQualityList