import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetMilkQualities = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getMilkQualities', property_id, page, limit]

  const { data: { data: { MilkQualities = [], pagination: milkQualitiesPagination = {} } = [] } = [], ...getMilkQualitiesRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`milk_quality?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { MilkQualities, milkQualitiesPagination, getMilkQualitiesRest }
}


// ### ADD

export const useAddMilkQuality = () => {

  const queryClient = useQueryClient()

  const { mutate: addMilkQuality, ...addMilkQualityRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`milk_quality/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMilkQualities'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addMilkQuality, addMilkQualityRest }
}

// ### UPDATE

export const useUpdateMilkQuality = () => {

  const queryClient = useQueryClient()

  const { mutate: updateMilkQuality, ...updateMilkQualityRest } = useMutation({
    mutationFn: async ({ data, milk_quality_id }) => (await api.put(`milk_quality/${milk_quality_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMilkQualities'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateMilkQuality, updateMilkQualityRest }
}

// ### DELETE

export const useDestroyMilkQuality = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyMilkQuality, ...destroyMilkQualityRest } = useMutation({
    mutationFn: async (milk_quality_id) => (await api.delete(`milk_quality/${milk_quality_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMilkQualities'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyMilkQuality, destroyMilkQualityRest }

}
