import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportReproductiveCalvingPredictions = (property_id = 0, start_date, end_date, page = 1, limit = 10) => {

  const QUERY_KEY = ['getReportReproductiveCalvingPredictions', property_id, start_date, end_date, page, limit]  

  const { data: { data: { Matings = [], pagination: reportReproductiveCalvingPredictionsPagination = {} } = [] } = [], ...getReportReproductiveCalvingPredictionsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_calving_prediction/${property_id}?start_date=${start_date}&end_date=${end_date}&limit=${limit}&page=${page}`)),
    placeholderData: keepPreviousData,
  })  

//  console.log(property_id, start_date, end_date, page, limit)

  return { Matings, reportReproductiveCalvingPredictionsPagination, getReportReproductiveCalvingPredictionsRest }
}

// #### GET

export const useGetReportReproductiveCalvingPredictionsByMonth = (property_id = 0, start_date, end_date) => {

  const QUERY_KEY = ['getReportReproductiveCalvingPredictionsByMonth', property_id, start_date, end_date]  

  const { data: { data: MatingsByMonth = []  } = [], ...getReportReproductiveCalvingPredictionsByMonthRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_calving_prediction_by_month/${property_id}?start_date=${start_date}&end_date=${end_date}`)),
    placeholderData: keepPreviousData,
  })  

  return { MatingsByMonth, getReportReproductiveCalvingPredictionsByMonthRest }
}


