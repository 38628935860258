import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import Switch from '../../components/Switch'
import Loading from '../../components/Loading'

import { useUpdateBatch } from '../../queries/BatchQueries'

function BatchUpdate(props) {

  const selectedBatch = props?.data

  const { updateBatch, updateBatchRest } = useUpdateBatch()

  const [name, setName] = useState(selectedBatch?.name || "")
  const [description, setDescription] = useState(selectedBatch?.description || "")
  const [active, setActive] = useState(selectedBatch?.active)

  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (name && name != selectedBatch?.name) {
      data.name = name
      dataUpdated = true
    }

    if (description && description != selectedBatch?.description) {
      data.description = description
      dataUpdated = true
    }   

    if (active != selectedBatch?.active) {
      data.active = active
      dataUpdated = true
    }


    if (dataUpdated) {

      updateBatch({ data, batch_id: selectedBatch?.id })
      props.setOpenModal(false)

    } else {
      alert('Nenhum dado foi modificado!')
    }

  }

  if (updateBatchRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-12'>
      <div className='space-y-3'>       

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Descrição"
          value={description}
          setValue={(e) => { setDescription(e) }}
        />

        <Switch
          active={active}
          setValue={(e) => { setActive(e) }}
          activeText="Ativo"
          inactiveText="Inativo"
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

    </div>

  )
}

export default BatchUpdate