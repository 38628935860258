import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useUpdateDailyProduction } from '../../queries/DailyProductionQueries'

import { brazilianCurrencyMask, setDatePostFormat, toUSACurrency, toBrazilianCurrency } from '../../utils'


function DailyProductionUpdate(props) {

  const selectedDailyProduction = props?.data || {}
  
  const { updateDailyProduction, updateDailyProductionRest } = useUpdateDailyProduction()

  const [date, setDate] = useState()
  const [litersDelivered, setLitersDelivered] = useState(selectedDailyProduction?.liters_delivered ? toBrazilianCurrency(selectedDailyProduction?.liters_delivered) : "")
  const [litersConsumedCalves, setLitersConsumedCalves] = useState(selectedDailyProduction?.liters_consumed_calves ? toBrazilianCurrency(selectedDailyProduction?.liters_consumed_calves) : "")
  const [litersConsumedInternally, setLitersConsumedInternally] = useState(selectedDailyProduction?.liters_consumed_internally ? toBrazilianCurrency(selectedDailyProduction?.liters_consumed_internally) : "")
  const [litersDiscarted, setLitersDiscarted] = useState(selectedDailyProduction?.liters_discarted ? toBrazilianCurrency(selectedDailyProduction?.liters_discarted) : "")
  const [price, setPrice] = useState(selectedDailyProduction?.price ? toBrazilianCurrency(selectedDailyProduction?.price) : "")

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  useEffect(() => {   

    if (selectedDailyProduction?.date) {
      const newDate = new Date(selectedDailyProduction?.date)
      setDate(`${newDate?.getFullYear()}-${(newDate?.getMonth() + 1) < 10 ? '0' + (newDate?.getMonth() + 1) : newDate?.getMonth() + 1}-${newDate?.getDate() < 10 ? '0' + newDate?.getDate() : newDate?.getDate()}`)
    }

  }, [])

  const submit = async () => {

    let data = {}
    let dataUpdated = false

    const newDate = new Date(selectedDailyProduction?.date)
    const newDateFormat = (`${newDate?.getFullYear()}-${(newDate?.getMonth() + 1) < 10 ? '0' + (newDate?.getMonth() + 1) : newDate?.getMonth() + 1}-${newDate?.getDate() < 10 ? '0' + newDate?.getDate() : newDate?.getDate()}`)

    if (date && date !== newDateFormat) {
      //const textDate = date?.replace(/-/g, "/")
      data.date = setDatePostFormat(date)
      dataUpdated = true
    }

    if (litersDelivered && parseInt(selectedDailyProduction?.liters_delivered) !== Math.trunc(toUSACurrency(litersDelivered) * 100)) {
      data.liters_delivered = Math.trunc(toUSACurrency(litersDelivered) * 100)
      dataUpdated = true
    }

    if (litersConsumedCalves && parseInt(selectedDailyProduction?.liters_consumed_calves) !== Math.trunc(toUSACurrency(litersConsumedCalves) * 100)) {
      data.liters_consumed_calves = Math.trunc(toUSACurrency(litersConsumedCalves) * 100)
      dataUpdated = true
    }

    if (litersConsumedInternally && parseInt(selectedDailyProduction?.liters_consumed_internally) !== Math.trunc(toUSACurrency(litersConsumedInternally) * 100)) {
      data.liters_consumed_internally = Math.trunc(toUSACurrency(litersConsumedInternally) * 100)
      dataUpdated = true
    }

    if (litersDiscarted && parseInt(selectedDailyProduction?.liters_discarted) !== Math.trunc(toUSACurrency(litersDiscarted) * 100)) {
      data.liters_discarted = Math.trunc(toUSACurrency(litersDiscarted) * 100)
      dataUpdated = true
    }

    if (price && parseInt(selectedDailyProduction?.price) !== Math.trunc(toUSACurrency(price) * 100)) {
      data.price = Math.trunc(toUSACurrency(price) * 100)
      dataUpdated = true
    }

    if (dataUpdated) {

      updateDailyProduction({ data, daily_production_id: selectedDailyProduction?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (updateDailyProductionRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>      

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros entregues"
          value={litersDelivered}
          setValue={(e) => { setLitersDelivered(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros consumidos bezerros"
          value={litersConsumedCalves}
          setValue={(e) => { setLitersConsumedCalves(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros consumidos internamente"
          value={litersConsumedInternally}
          setValue={(e) => { setLitersConsumedInternally(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros descartados"
          value={litersDiscarted}
          setValue={(e) => { setLitersDiscarted(brazilianCurrencyMask(e)) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Preço do leite"
            value={price}
            setValue={(e) => { setPrice(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>



      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Property" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default DailyProductionUpdate