import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetLactationInductions = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getLactationInductions', property_id, page, limit]

  const { data: { data: { LactationInductions = [], pagination: lactationInductionsPagination = {} } = [] } = [], ...getLactationInductionsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`lactation_inductions?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  }) 

  return { LactationInductions, lactationInductionsPagination, getLactationInductionsRest }
}


// ### ADD

export const useAddLactationInduction = () => {

  const queryClient = useQueryClient()

  const { mutate: addLactationInduction, ...addLactationInductionRest } = useMutation({
    mutationFn: async ({ data, animal_id }) => (await api.post(`lactation_inductions/${animal_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getLactationInductions'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addLactationInduction, addLactationInductionRest }
}

// ### UPDATE

export const useUpdateLactationInduction = () => {

  const queryClient = useQueryClient()

  const { mutate: updateLactationInduction, ...updateLactationInductionRest } = useMutation({
    mutationFn: async ({ data, lactation_induction_id }) => (await api.put(`lactation_inductions/${lactation_induction_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getLactationInductions'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateLactationInduction, updateLactationInductionRest }
}

// ### DELETE

export const useDestroyLactationInduction = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyLactationInduction, ...destroyLactationInductionRest } = useMutation({
    mutationFn: async (lactation_induction_id) => (await api.delete(`lactation_inductions/${lactation_induction_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getLactationInductions'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyLactationInduction, destroyLactationInductionRest }

}
