import React from 'react'
import { useNavigate } from 'react-router-dom'

import Menu from '../../components/Menu'
import ButtonReport from '../../components/ButtonReport'
import BackgroundData from '../../components/BackgroundData'


function ReportProductiveList() {

  const navigate = useNavigate()

  return (
    <Menu title="Relatórios de Reprodução">
      <div className='flex lg:flex-col items-center justify-center p-2 py-4 lg:p-4 space-y-8'>

        <BackgroundData>

          <div className='w-full flex flex-col flex-wrap lg:flex-row lg:justify-start items-center gap-3'>

            <div className='w-64 flex'>
              <ButtonReport
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Previsão de Partos"
                onClick={() => { navigate("/report_calving_prediction") }}
              />
            </div>

            <div className='w-64 flex'>
              <ButtonReport
                textColor="text-white"
                backColor="bg-primaryContrast"
                title="Nascimento por Período"
                onClick={() => { navigate("/report_calving_birth_by_period") }}
              />
            </div>

            {/* <div className='w-64 flex'>
              <ButtonReport
                textColor="text-white"
                backColor="bg-primaryAlert"
                title="Eficiência Reprodutiva"
                onClick={() => { navigate("") }}
              />
            </div> */}

          </div>

        </BackgroundData>

      </div>
    </Menu>
  )
}

export default ReportProductiveList