import React from 'react'
import { useNavigate } from 'react-router-dom'

function Subitems(props) {

  const navigate = useNavigate()

  const redirect = () => {
    navigate(props.pageName)
  }


    return (
        <button 
        type='button' 
        className='className="flex items-center justify-start lg:py-2 py-4 border-b border-primaryBackground w-full rounded hover:bg-secondaryBackground group hover:shadow-md"'
        onClick={redirect}
        >
            <span className='flex-1 text-white lg:text-base text-xl'>{props.title}</span>
        </button>
    )
}

export default Subitems