import React, { useEffect, useState } from 'react'

import Menu from '../../components/Menu'
import Input from '../../components/Input'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'



import { useGetReportProductiveAnimalStopLactations } from '../../queries/ReportProductiveAnimalStopLactationQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { setInputDateFormat, setDateBrazilianFormat } from '../../utils'

function ReportProductiveAnimalStopLactation() { 

  const { selectedProperty } = usePropertyStore()

  var date = new Date()
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const [startDate, setStartDate] = useState(setInputDateFormat(firstDay))
  const [endDate, setEndDate] = useState(setInputDateFormat(lastDay))

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10) 

  const {
    StopLactations,
    reportProductiveAnimalStopLactationsPagination,
    getReportProductiveAnimalStopLactationsRest
  } = useGetReportProductiveAnimalStopLactations(selectedProperty?.id, setInputDateFormat(startDate || new Date(date.getFullYear(), date.getMonth(), 1)), setInputDateFormat(endDate || new Date(date.getFullYear(), date.getMonth() + 1, 0)), page, limit)

  if (getReportProductiveAnimalStopLactationsRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Animais a Secar">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <div className='w-full flex flex-col justify-start space-y-6'>

          <BackgroundData>

            <div className='w-full flex flex-col lg:flex-row space-y-8 lg:space-y-0 space-x-4 items-center justify-start'>

              <div className='lg:w-[50%] flex space-x-4'>

                <div className='flex-1'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="De"
                    value={startDate}
                    setValue={(e) => { setStartDate(e) }}
                    className="flex-1"
                  />
                </div>

                <div className='flex-1'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="Até"
                    value={endDate}
                    setValue={(e) => { setEndDate(e) }}
                  />
                </div>              

              </div>

            </div>
          </BackgroundData>

          <BackgroundData>

            <div className="relative overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ">
                <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                  <tr>
                    <th scope="col" className="px-6 py-3">Animal</th>
                    <th scope="col" className="px-6 py-3">Lote</th>
                    <th scope="col" className="px-6 py-3">Dias p/ parto</th>
                    <th scope="col" className="px-6 py-3">Data da secagem</th>
                  </tr>
                </thead>

                {StopLactations?.length > 0 && StopLactations.map((item, index) => {

                  return (
                    <tbody key={index}>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{item?.Animal?.name ? `${item?.Animal?.earring} - ${item?.Animal?.name}` : item?.Animal?.earring}</th>
                        <td className="px-6 py-4">{item?.Animal?.Batch?.name}</td>
                        <td className="px-6 py-4">{item?.stopLactationInDays}</td>
                        <td className="px-6 py-4">{setDateBrazilianFormat(item?.date)}</td>
                      </tr>
                    </tbody>
                  )
                })}

              </table>

              <div className='flex items-center justify-center'>

                <ButtonsPagination
                  data={reportProductiveAnimalStopLactationsPagination}
                  page={(page) => { setPage(page) }}
                  limit={(limit) => { setLimit(limit) }}
                />

              </div>


            </div>
          </BackgroundData>

        </div>        

      </div>
    </Menu>
  )
}

export default ReportProductiveAnimalStopLactation