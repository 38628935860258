import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import ExpenseAdd from '../ExpenseAdd'
import ExpenseUpdate from '../ExpenseUpdate'
import ExpenseInstallmentsUpdate from '../ExpenseInstallmentsUpdate'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetExpenses, useDestroyExpense } from '../../queries/ExpenseQueries'

import { brazilianCurrencyMask, setDateBrazilianFormat } from '../../utils'


function ExpenseList() {

  const { selectedProperty } = usePropertyStore()

  const { destroyExpense, destroyExpenseRest } = useDestroyExpense()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalInstallmentsUpdate, setShowModalInstallmentsUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)  

  const [selectedExpense, setSelectedExpense] = useState()
  const [selectedInstallment, setSelectedInstallment] = useState()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const taggleAccordion = (id) => {

    let elements = document.querySelectorAll(`[id=${id}]`)

    for (var i = 0; i < elements.length; i++) {

      if (elements[i].className.search("hidden") !== -1) {
        elements[i].className = elements[i].className.replace("hidden", "table-row")
      } else if (elements[i].className.search("table-row") !== -1) {
        elements[i].className = elements[i].className.replace("table-row", "hidden")
      }

    }

  }

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Expenses, expensesPagination, getExpensesRest } = useGetExpenses(property_id, page, limit)


  const handleDeleteExpense = async () => {

    destroyExpense(selectedExpense.id)

    setShowModalConfirm(false)
  }

  if (getExpensesRest.isPending || destroyExpenseRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Despesas" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>

        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-sm uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Descrição</th>
                  <th scope="col" className="px-6 py-3">Categoria</th>
                  <th scope="col" className="px-6 py-3">Valor</th>

                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(Expenses) && Expenses.map((item, index) => {

                return (
                  <tbody key={item?.id}>
                    <tr className="bg-white border-b cursor-pointer uppercase hover:bg-gray-100 focus:bg-gray-100 focus:border focus:border-gray-100" tabIndex="0" onClick={() => { taggleAccordion(`accordion-collapse-body-${index}`) }}>
                      <td className="px-6 py-4">{item?.description}</td>
                      <td className="px-6 py-4">{item?.RevenueCategory?.name}</td>
                      <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(item?.total)}`}</td>

                      <th className=''>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedExpense(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedExpense(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                          <div className='hidden' id={`accordion-collapse-body-${index}`}>
                            <Icon icon="iconamoon:arrow-up-2" className='text-xl' />
                          </div>
                          <dir className='table-row' id={`accordion-collapse-body-${index}`}>
                            <Icon icon="iconamoon:arrow-down-2" className='text-xl' />
                          </dir>
                        </div>
                      </th>
                    </tr>

                    <tr className="hidden bg-white border border-separate border-gray-200 rounded-b-lg" tabIndex="0" id={`accordion-collapse-body-${index}`} >
                      <td className="px-10 py-4" colSpan={4}>

                        <table className="w-full text-sm text-left rtl:text-righ bg-gray-50" colSpan={4}>
                          <thead className="text-xs uppercase border-b border-gray-400 text-primaryBackground" colSpan={4}>
                            <tr>
                              <th scope="col" className="px-6 py-3">Parcela</th>
                              <th scope="col" className="px-6 py-3">Vencimento</th>
                              <th scope="col" className="px-6 py-3">Valor da parcela</th>
                              <th scope="col" className="px-6 py-3">Paga?</th>
                              <th scope="col" className="px-6 py-3">Data de Pagamento</th>

                              <th scope="col" className="px-6 py-3"></th>
                            </tr>
                          </thead>

                          {
                            item?.ExpenseInstallments?.length > 0 && item?.ExpenseInstallments?.map((subitem, subIndex) => {

                              return (
                                <tbody key={subitem?.id}>
                                  <tr className="border-b" id={`accordion-collapse-body-${index}`} >
                                    <td className="px-6 py-4">{`${subitem?.installment_number}`}</td>
                                    <td className="px-6 py-4">{`${setDateBrazilianFormat(subitem?.due_date)}`}</td>
                                    <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(subitem?.value)}`}</td>                                    
                                    <td className="px-6 py-4">
                                      <Icon
                                        icon={`material-symbols:${subitem?.paid ? "thumb-up" : "thumb-down"}`}
                                        className={`text-2xl ${subitem?.paid ? "text-tertiaryBackground" : "text-primaryAlert"}`}
                                      />
                                    </td>
                                    <td className="px-6 py-4">{`${subitem?.payment_date ? setDateBrazilianFormat(subitem?.payment_date) : "-"}`}</td>

                                    <td className=''>
                                      <div className='flex items-center justify-end'>
                                        <button
                                          className='p-3 hover:bg-secondaryBackground group'
                                          onClick={() => {
                                            setSelectedInstallment(subitem)
                                            setShowModalInstallmentsUpdate(true)
                                          }}
                                        >
                                          <Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                                        </button>

                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })
                          }

                        </table>
                      </td>
                    </tr>

                  </tbody>
                )
              })}

            </table >

            <div className='flex items-center justify-center'>

              <ButtonsPagination
                data={expensesPagination}
                page={(page) => { setPage(page) }}
                limit={(limit) => { setLimit(limit) }}
              />
            </div>

          </div>
        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Nova Despesa" ><ExpenseAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar Despesa"><ExpenseUpdate setOpenModal={setShowModalUpdate} data={selectedExpense} /></ModalSide>
      }

      {showModalInstallmentsUpdate &&
        <ModalSide setOpenModal={setShowModalInstallmentsUpdate} title="Atualizar Parcela"><ExpenseInstallmentsUpdate setOpenModal={setShowModalInstallmentsUpdate} data={selectedInstallment} /></ModalSide>
      }

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteExpense() }} />
      }
    </Menu>
  )
}

export default ExpenseList