import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import InputSelect from '../../components/InputSelect'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Input from '../../components/Input'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'
import AnimalAdd from '../AnimalAdd'

import { useGetReportProductiveAnimalMilkQualities } from '../../queries/ReportProductiveAnimalMilkQualityQueries'
import { useGetAnimals } from '../../queries/AnimalQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { setInputDateFormat, setDateBrazilianFormat, percentageMask } from '../../utils'

function ReportProductiveAnimalMilkQuality() {

  const { selectedProperty } = usePropertyStore()

  var date = new Date()
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const [startDate, setStartDate] = useState(setInputDateFormat(firstDay))
  const [endDate, setEndDate] = useState(setInputDateFormat(lastDay))

  const [animal, setAnimal] = useState("")
  const [animalListName, setAnimalListName] = useState([])
  const [animalName, setAnimalName] = useState("")

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)
  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  const { Animals, getAnimalsRest } = useGetAnimals(selectedProperty?.id || 0)

  useEffect(() => {

    const animalListData = Animals.map(x => x?.name ? `${x?.earring} - ${x?.name}` : x?.earring)
    setAnimalListName(["Todos", ...animalListData])
    setAnimalName(animalListData[0])

  }, [])

  useEffect(() => {
    const animalListData = Animals.map(x => x?.name ? `${x?.earring} - ${x?.name}` : x?.earring)
    setAnimalListName(["Todos", ...animalListData])
  }, [Animals])

  useEffect(() => {

    if (selectedProperty?.name) {

      const propertySelected = selectedProperty

      const animalList = Animals.filter(x => x.ProductiveArea.Property.id === propertySelected.id).map(x => x?.name ? `${x?.earring} - ${x?.name}` : x?.earring)
      const newAnimalData = ["Todos", ...animalList]
      setAnimalListName(newAnimalData)
      setAnimalName(newAnimalData[0])

    }

  }, [selectedProperty])

  useEffect(() => {

    if (animalName && animalName !== "Todos") {

      const animalSelected = Animals.find(x => {

        if (animalName.indexOf(x.name) > 0 || animalName && animalName === x?.earring) {
          return x
        }
      })

      setAnimal(animalSelected)

    } else if (animalName === "Todos") {
      setAnimal({ id: 0 })
    }

  }, [animalName])

  const { AnimalMilkQualities,
    getReportProductiveAnimalMilkQualitiesRest,
    reportProductiveAnimalMilkQualitiesPagination
  } = useGetReportProductiveAnimalMilkQualities(selectedProperty?.id, animal?.id, setInputDateFormat(startDate || new Date(date.getFullYear(), date.getMonth(), 1)), setInputDateFormat(endDate || new Date(date.getFullYear(), date.getMonth() + 1, 0)), page, limit)

  if (getAnimalsRest.isPending || getReportProductiveAnimalMilkQualitiesRest.isPending) {
    return <Loading />
  }


  return (
    <Menu title="Qualidade do Leite Animal">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <div className='w-full flex flex-col justify-start space-y-6'>

          <BackgroundData className=''>
            <div className='w-full flex flex-col lg:flex-row lg:space-y-0 gap-y-6 space-y-0 lg:space-x-4 items-center lg:justify-around justify-around'>

              <div className='lg:w-[50%] flex space-x-4'>

                <div className='flex-1'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="De"
                    value={startDate}
                    setValue={(e) => { setStartDate(e) }}
                    className="flex-1"
                  />
                </div>

                <div className='flex-1'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="Até"
                    value={endDate}
                    setValue={(e) => { setEndDate(e) }}
                  />
                </div>

              </div>


              <div className='flex items-center space-x-2 lg:space-x-1'>
                <InputSelect
                  type="text"
                  borderColor="primaryBackground"
                  textColor="primaryBackground"
                  placeholder="Animal*"
                  value={animalName}
                  list={animalListName}
                  setValue={(e) => { setAnimalName(e) }}
                />
                <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
              </div>

            </div>
          </BackgroundData>

          <BackgroundData>


            <div className="relative overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ">
                <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                  <tr>
                    <th scope="col" className="px-6 py-3">Animal</th>
                    <th scope="col" className="px-6 py-3">Data</th>
                    <th scope="col" className="px-6 py-3">Proteína(%)</th>
                    <th scope="col" className="px-6 py-3">Gordura(%)</th>
                    <th scope="col" className="px-6 py-3">Lactose(%)</th>
                    <th scope="col" className="px-6 py-3">Sólitos(%)</th>
                    <th scope="col" className="px-6 py-3">Uréia(mg/dl)</th>
                    <th scope="col" className="px-6 py-3">CBT</th>
                    <th scope="col" className="px-6 py-3">CCS</th>
                  </tr>
                </thead>

                {AnimalMilkQualities?.length > 0 && AnimalMilkQualities.map((item, index) => {

                  return (
                    <tbody key={index}>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{`${item?.Animal?.earring} - ${item?.Animal?.name}`}</th>
                        <td className="px-6 py-4">{setDateBrazilianFormat(item?.date)}</td>
                        <td className="px-6 py-4">{percentageMask(item?.protein_percentage)}</td>
                        <td className="px-6 py-4">{percentageMask(item?.fat_percentage)}</td>
                        <td className="px-6 py-4">{percentageMask(item?.lactose)}</td>
                        <td className="px-6 py-4">{percentageMask(item?.solitos)}</td>
                        <td className="px-6 py-4">{percentageMask(item?.urea)}</td>
                        <td className="px-6 py-4">{item?.cbt}</td>
                        <td className="px-6 py-4">{item?.ccs}</td>
                      </tr>
                    </tbody>
                  )
                })}

              </table>

              <div className='flex items-center justify-center'>

                <ButtonsPagination
                  data={reportProductiveAnimalMilkQualitiesPagination}
                  page={(page) => { setPage(page) }}
                  limit={(limit) => { setLimit(limit) }}
                />

              </div>

            </div>
          </BackgroundData>

        </div>

        {showModalAddProperty &&
          <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
            <PropertyAdd setOpenModal={setShowModalAddProperty} />
          </ModalSide>
        }

        {showModalAddAnimal &&
          <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
            <AnimalAdd setOpenModal={setShowModalAddAnimal} />
          </ModalSide>
        }

      </div>
    </Menu>
  )
}

export default ReportProductiveAnimalMilkQuality