import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetAnimalDisposals = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getAnimalDisposals', property_id, page, limit]

  const { data: { data: { AnimalDisposals = [], pagination: animalDisposalsPagination = {} } = [] } = [], ...getAnimalDisposalsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`animal_disposal?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })
  
  return { AnimalDisposals, animalDisposalsPagination, getAnimalDisposalsRest }
}


// ### ADD

export const useAddAnimalDisposal = () => {

  const queryClient = useQueryClient()

  const { mutate: addAnimalDisposal, ...addAnimalDisposalRest } = useMutation({
    mutationFn: async ({ data, animal_id }) => (await api.post(`animal_disposal/${animal_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAnimalDisposals'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addAnimalDisposal, addAnimalDisposalRest }
}

// ### UPDATE

export const useUpdateAnimalDisposal = () => {

  const queryClient = useQueryClient()

  const { mutate: updateAnimalDisposal, ...updateAnimalDisposalRest } = useMutation({
    mutationFn: async ({ data, animal_disposal_id }) => ( await api.put(`animal_disposal/${animal_disposal_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAnimalDisposals'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateAnimalDisposal, updateAnimalDisposalRest }
}

// ### DELETE

export const useDestroyAnimalDisposal = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyAnimalDisposal, ...destroyAnimalDisposalRest } = useMutation({
    mutationFn: async (animal_disposal_id) => (await api.delete(`animal_disposal/${animal_disposal_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAnimalDisposals'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyAnimalDisposal, destroyAnimalDisposalRest }

}
