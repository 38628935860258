import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useUpdateInvestiment } from '../../queries/InvestimentQueries'

import { brazilianCurrencyMask, toUSACurrency, setDatePostFormat, installmentList, setInputDateFormat, investimentTypeList } from '../../utils'


function InvestimentUpdate(props) {

  const selectedInvestiment = props?.data || {}  

  const { updateInvestiment, updateInvestimentRest } = useUpdateInvestiment()

  const [description, setDescription] = useState(selectedInvestiment?.description || "")
  const [startDate, setStartDate] = useState("")
  const [cost, setCost] = useState(selectedInvestiment?.cost || "")
  const [installments, setInstallments] = useState("")
  const [depreciation, setDepreciation] = useState(selectedInvestiment?.depreciation || "")
  const [brand, setBrand] = useState(selectedInvestiment?.brand || "")
  const [observation, setObservation] = useState(selectedInvestiment?.observation || "")

  const [type, setType] = useState("")
  const [typeList, setTypeList] = useState("")

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  useEffect(() => {   

    setTypeList(investimentTypeList)

    if (selectedInvestiment?.type) {
      setType(investimentTypeList.find(x => x === selectedInvestiment?.type))
    }

    if (selectedInvestiment?.installments) {
      setInstallments(installmentList.find(x => x === selectedInvestiment?.installments))
    }

    if (selectedInvestiment?.start_date) {
      setStartDate(setInputDateFormat(selectedInvestiment?.start_date))
    }

  }, [])


  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (description && description !== selectedInvestiment?.description) {
      data.description = description
      dataUpdated = true
    }

    if (startDate && startDate !== setInputDateFormat(selectedInvestiment?.start_date)) {
      data.start_date = setDatePostFormat(startDate)
      dataUpdated = true
    }

    if (type && type !== selectedInvestiment?.type) {
      data.type = type
      dataUpdated = true
    }

    if (cost && parseInt(selectedInvestiment?.cost) !== Math.trunc(toUSACurrency(cost) * 100)) {
      data.cost = Math.trunc(toUSACurrency(cost) * 100)
      dataUpdated = true
    }

    if (depreciation && parseInt(selectedInvestiment?.depreciation) !== Math.trunc(toUSACurrency(depreciation) * 100)) {
      data.depreciation = Math.trunc(toUSACurrency(depreciation) * 100)
      dataUpdated = true
    }

    if (installments && installments !== selectedInvestiment?.installments) {
      data.installments = installments
      dataUpdated = true
    }

    if (brand && brand !== selectedInvestiment?.brand) {
      data.brand = brand
      dataUpdated = true
    }

    if (observation && observation !== selectedInvestiment?.observation) {
      data.observation = observation
      dataUpdated = true
    }


    if (dataUpdated) {

      updateInvestiment({ data, investiment_id: selectedInvestiment?.id })

      props.setOpenModal(false)

    } else {
      alert('Nenhum dado para ser atualizado!')
    }

  }

  if(updateInvestimentRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Descrição*"
          value={description}
          setValue={(e) => { setDescription(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Categoria*"
          value={type}
          list={typeList}
          setValue={(e) => { setType(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data de Aquisição*"
          value={startDate}
          setValue={(e) => { setStartDate(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Custo*"
            value={cost}
            setValue={(e) => { setCost(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Prestações*"
          value={installments}
          list={installmentList}
          setValue={(e) => { setInstallments(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Depreciação a.a*"
            value={depreciation}
            setValue={(e) => { setDepreciation(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Marca"
          value={brand}
          setValue={(e) => { setBrand(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Observação"
          value={observation}
          setValue={(e) => { setObservation(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default InvestimentUpdate