import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetWeighings = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getWeighings', property_id, page, limit]

  const { data: { data: { Weighings = [], pagination: weighingsPagination = {} } = [] } = [], ...getWeighingsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`weighing_animal?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { Weighings, weighingsPagination, getWeighingsRest }
}


// ### ADD

export const useAddWeighing = () => {

  const queryClient = useQueryClient()

  const { mutate: addWeighing, ...addWeighingRest } = useMutation({
    mutationFn: async ({ data, animal_id }) => (await api.post(`weighing_animal/${animal_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getWeighings'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addWeighing, addWeighingRest }
}

// ### UPDATE

export const useUpdateWeighing = () => {

  const queryClient = useQueryClient()

  const { mutate: updateWeighing, ...updateWeighingRest } = useMutation({
    mutationFn: async ({ data, weighing_id }) => (await api.put(`weighing_animal/${weighing_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getWeighings'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateWeighing, updateWeighingRest }
}

// ### DELETE

export const useDestroyWeighing = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyWeighing, ...destroyWeighingRest } = useMutation({
    mutationFn: async (weighing_id) => (await api.delete(`weighing_animal/${weighing_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getWeighings'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyWeighing, destroyWeighingRest }

}
