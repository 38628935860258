import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetRevenues = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getRevenues', property_id, page, limit]

  const { data: { data: { Revenues = [], pagination: revenuesPagination = {} } = [] } = [], ...getRevenuesRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`revenues?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })  

  return { Revenues, revenuesPagination, getRevenuesRest }
}


// ### ADD

export const useAddRevenue = () => {

  const queryClient = useQueryClient()

  const { mutate: addRevenue, ...addRevenueRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`revenues/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRevenues'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addRevenue, addRevenueRest }
}

// ### UPDATE

export const useUpdateRevenue = () => {

  const queryClient = useQueryClient()

  const { mutate: updateRevenue, ...updateRevenueRest } = useMutation({
    mutationFn: async ({ data, revenue_id }) => (await api.put(`revenues/${revenue_id}`, data)          ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRevenues'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateRevenue, updateRevenueRest }
}

// ### DELETE

export const useDestroyRevenue = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyRevenue, ...destroyRevenueRest } = useMutation({
    mutationFn: async (revenue_id) => (await api.delete(`revenues/${revenue_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRevenues'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyRevenue, destroyRevenueRest }

}
