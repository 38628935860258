import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetDailyProductions = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getDailyProductions', property_id, page, limit]

  const { data: { data: { DailyProductions = [], pagination: dailyProductionsPagination = {} } = [] } = [], ...getDailyProductionsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`daily_productions?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { DailyProductions, dailyProductionsPagination, getDailyProductionsRest }
}


// ### ADD

export const useAddDailyProduction = () => {

  const queryClient = useQueryClient()

  const { mutate: addDailyProduction, ...addDailyProductionRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`daily_productions/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDailyProductions'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addDailyProduction, addDailyProductionRest }
}

// ### UPDATE

export const useUpdateDailyProduction = () => {

  const queryClient = useQueryClient()

  const { mutate: updateDailyProduction, ...updateDailyProductionRest } = useMutation({
    mutationFn: async ({ data, daily_production_id }) => (await api.put(`daily_productions/${daily_production_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDailyProductions'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateDailyProduction, updateDailyProductionRest }
}

// ### DELETE

export const useDestroyDailyProduction = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyDailyProduction, ...destroyDailyProductionRest } = useMutation({
    mutationFn: async (daily_production_id) => (await api.delete(`daily_productions/${daily_production_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDailyProductions'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyDailyProduction, destroyDailyProductionRest }

}
