import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetInvestiments = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getInvestiments', property_id, page, limit]

  const { data: { data: { Investiments = [], pagination: investimentsPagination = {} } = [] } = [], ...getInvestimentsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`investiments?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { Investiments, investimentsPagination, getInvestimentsRest }
}


// ### ADD

export const useAddInvestiment = () => {

  const queryClient = useQueryClient()

  const { mutate: addInvestiment, ...addInvestimentRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`investiments/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getInvestiments'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addInvestiment, addInvestimentRest }
}

// ### UPDATE

export const useUpdateInvestiment = () => {

  const queryClient = useQueryClient()

  const { mutate: updateInvestiment, ...updateInvestimentRest } = useMutation({
    mutationFn: async ({ data, investiment_id }) => (await api.put(`investiments/${investiment_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getInvestiments'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })  

  return { updateInvestiment, updateInvestimentRest }
}

// ### DELETE

export const useDestroyInvestiment = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyInvestiment, ...destroyInvestimentRest } = useMutation({
    mutationFn: async (investiment_id) => (await api.delete(`investiments/${investiment_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getInvestiments'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyInvestiment, destroyInvestimentRest }

}
