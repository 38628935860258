import React from 'react'
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query'
import { persistQueryClient, PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import { toast } from "react-toastify"

import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

import Routes from "./routes"

const notify = (message) => toast.error(message, { toastId: "custom-id-yes" })

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => { notify(`Algo deu errado: ${error.message}`) }
  }),
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

persistQueryClient({
  queryClient,
  persister,
})

function App() { 

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <Routes />
      <ToastContainer autoClose={2000} draggable={false} />
    </PersistQueryClientProvider>
  )
}

export default App
