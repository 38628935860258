import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportRevenueByCategory = (property_id = 0, start_date, end_date) => {

  const QUERY_KEY = ['getReportRevenueByCategory', property_id, start_date, end_date]  

  const { data: { data: RevenueByCategory = [] } = [], ...getReportRevenueByCategoryRest } = useQuery({    
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_revenue_by_category/${property_id}?start_date=${start_date}&end_date=${end_date}`)),
    placeholderData: keepPreviousData,
  })   
    
  return { RevenueByCategory, getReportRevenueByCategoryRest }
}