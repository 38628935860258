import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import ButtonSubmit from '../../components/ButtonSubmit'
import Loading from '../../components/Loading'

import { useGetProperties } from '../../queries/PropertyQueries'
import { useAddBatch } from '../../queries/BatchQueries'

import { usePropertyStore } from '../../stores/propertyStore'

function BatchAdd(props) { 


  const { selectedProperty } = usePropertyStore()
  const { addBatch, addBatchRest } = useAddBatch()

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    name ? data.name = name : allFieldsFilled = false
    if (description) {
      data.description = description
    }


    if (allFieldsFilled) {    

      addBatch({ data, property_id: selectedProperty?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (addBatchRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>
       
        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Descrição"
          value={description}
          setValue={(e) => { setDescription(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

    </div>

  )
}

export default BatchAdd