import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetMatings = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getMatings', property_id, page, limit]

  const { data: { data: { Matings = [], pagination: matingsPagination = {} } = [] } = [], ...getMatingsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`mating?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { Matings, matingsPagination, getMatingsRest }
}


// ### ADD

export const useAddMating = () => {

  const queryClient = useQueryClient()

  const { mutate: addMating, ...addMatingRest } = useMutation({
    mutationFn: async (data) => (await api.post(`mating`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMatings'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addMating, addMatingRest }
}

// ### UPDATE

export const useUpdateMating = () => {

  const queryClient = useQueryClient()

  const { mutate: updateMating, ...updateMatingRest } = useMutation({
    mutationFn: async ({ data, mating_id }) => (await api.put(`mating/${mating_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMatings'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateMating, updateMatingRest }
}

// ### DELETE

export const useDestroyMating = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyMating, ...destroyMatingRest } = useMutation({
    mutationFn: async (mating_id) => (await api.delete(`mating/${mating_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMatings'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyMating, destroyMatingRest }

}
