import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportFinancialDRE = (property_id = 0, year) => {

  const QUERY_KEY = ['getReportFinancialDRE', property_id, year]  

  const { data: { data: ReportFinancialDRE = [] } = [], ...getReportFinancialDRERest } = useQuery({    
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_dre/${property_id}?year=${year}`)),
    placeholderData: keepPreviousData,
  })  
    
  return { ReportFinancialDRE, getReportFinancialDRERest }
}