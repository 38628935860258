import React, { useEffect, useState } from 'react'

import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import InputSelect from '../../components/InputSelect'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Input from '../../components/Input'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'
import AnimalAdd from '../AnimalAdd'

import { useGetProperties } from '../../queries/PropertyQueries'
import { useGetReportProductiveAnimalStopLactations } from '../../queries/ReportProductiveAnimalStopLactationQueries'
import { useGetReportRevenueByCategory } from '../../queries/ReportRevenueByCategoryQueries'
import { useGetReportAnimalByBatch } from '../../queries/ReportAnimalByBatchQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { setInputDateFormat, setDateBrazilianFormat, brazilianCurrencyMask, taggleAccordion, ageCalculate } from '../../utils'

function ReportAnimalByBatch() {

  const { selectedProperty } = usePropertyStore()

  var date = new Date()
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)
  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  const { AnimalByBatch, getReportAnimalByBatchRest } = useGetReportAnimalByBatch(selectedProperty?.id)


  if (getReportAnimalByBatchRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Animais por Lotes">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <div className='w-full flex flex-col justify-start space-y-6'>

          <BackgroundData>

            <div className="relative overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ border-separate border-spacing-y-1">

                {Array.isArray(AnimalByBatch) && AnimalByBatch.map((item, index) => {

                  return (
                    <tbody key={item.name}>
                      <tr
                        className="bg-gray-100 border-b cursor-pointer uppercase hover:bg-gray-200 focus:bg-gray-200 focus:border focus:border-gray-200"
                        tabIndex="0"
                        onClick={() => { taggleAccordion(`accordion-collapse-body-${index}`) }}
                      >
                        <td className="px-6 py-4 font-semibold">{item?.name}</td>

                        <th className='pr-4'>
                          <div className='flex items-center justify-end'>
                            <div className='hidden' id={`accordion-collapse-body-${index}`}>
                              <Icon icon="iconamoon:arrow-up-2" className='text-xl' />
                            </div>
                            <dir className='table-row' id={`accordion-collapse-body-${index}`}>
                              <Icon icon="iconamoon:arrow-down-2" className='text-xl' />
                            </dir>
                          </div>
                        </th>

                      </tr>

                      <tr className="hidden bg-white border border-separate border-gray-200" tabIndex="0" id={`accordion-collapse-body-${index}`} >
                        <td className="px-2 py-4">

                          <table className="w-full text-sm text-left rtl:text-righ">
                            <thead className="text-xs uppercase border-b border-gray-400 text-primaryBackground">
                              <tr>
                                <th scope="col" className="px-6 py-3">Brinco</th>
                                <th scope="col" className="px-6 py-3">Nome</th>
                                <th scope="col" className="px-6 py-3">Data de Nascimento</th>
                                <th scope="col" className="px-6 py-3">Idade</th>
                                <th scope="col" className="px-6 py-3">Sexo</th>
                                <th scope="col" className="px-6 py-3">Raça</th>
                                <th scope="col" className="px-6 py-3">Graus de Sangue</th>
                                <th scope="col" className="px-6 py-3">Mãe</th>
                                <th scope="col" className="px-6 py-3">Pai</th>

                              </tr>
                            </thead>
                            {
                              item.Animals?.length > 0 && item?.Animals.map((subitem, subIndex) => {

                                return (
                                  <tbody key={subitem?.id}>
                                    <tr className="border-b" id={`accordion-collapse-body-${index}`} >
                                      <td className="px-6 py-4">{subitem?.earring}</td>
                                      <td className="px-6 py-4">{subitem?.name}</td>
                                      <td className="px-6 py-4">{setDateBrazilianFormat(subitem?.birth_date)}</td>
                                      <td className="px-6 py-4">{ageCalculate(subitem?.birth_date)}</td>
                                      <td className="px-6 py-4">{subitem?.sex}</td>
                                      <td className="px-6 py-4">{subitem?.breed}</td>
                                      <td className="px-6 py-4">{subitem?.blood_degrees}</td>
                                      <td className="px-6 py-4">{subitem?.Cow ? `${subitem?.Cow?.earring} - ${subitem?.Cow?.name}` : ""}</td>
                                      <td className="px-6 py-4">{subitem?.Bull ? `${subitem?.Bull?.earring} - ${subitem?.Bull?.name}` : ""}</td>
                                    </tr>
                                  </tbody>
                                )
                              })
                            }

                          </table>
                        </td>
                      </tr>

                    </tbody>
                  )
                })}

              </table >

            </div>
          </BackgroundData>

        </div>

        {showModalAddProperty &&
          <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
            <PropertyAdd setOpenModal={setShowModalAddProperty} />
          </ModalSide>
        }

        {showModalAddAnimal &&
          <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
            <AnimalAdd setOpenModal={setShowModalAddAnimal} />
          </ModalSide>
        }

      </div>
    </Menu>
  )
}

export default ReportAnimalByBatch