import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetAnimals } from '../../queries/AnimalQueries'
import { useAddAnimalDisposal } from '../../queries/AnimalDisposalQueries'


function AnimalDisposalAdd(props) {

  const { selectedProperty } = usePropertyStore()

  const { addAnimalDisposal, addAnimalDisposalRest } = useAddAnimalDisposal()

  const reasonList = [
    "Morte",
    "Venda",
    "Roubo",
    "Abate",
    "Outros"
  ]

  const reasonDeathList = [
    "Babesia",
    "Clostridioses",
    "Descarga Elétrica",
    "Deslocamento de abomaso",
    "Desnutrição",
    "Diarréia",
    "Distocia / Problemas no Parto",
    "Doenças metabólicas",
    "Hipocalcemia",
    "Indefinida",
    "Intoxicação",
    "Mastite",
    "Morte Natural",
    "Pneumonia",
    "Problemas de casco",
    "Queda / Acidente",
    "Raiva",
    "Rinotraqueíte infecciosa bovina",
    "Tristeza parasitária bovina",
    "Verminose",
    "Outras",
    "Timpanismo",
    "Picada de cobra",
    "Leucose Bovina",
    "Toxemia",
    "CAE - Artrite Encefalite Caprina",
    "Linfadenite",
    "Consanguinidade",
    "Malformação Congênita",
    "Leptospirose",
    "Abandono Materno",
    "Neosporose"
  ]

  const reasonSaleList = [
    "Aprumos",
    "Baixa produção",
    "Genética",
    "Idade",
    "Mastite Crônica",
    "Número de tetas",
    "Preço",
    "Problemas de casco",
    "Problemas reprodutivos",
    "Temperamento",
    "Outras",
    "Preço alto",
    "Preço baixo",
    "Descarte genético"
  ]

  const [date, setDate] = useState("")
  const [reason, setReason] = useState(reasonList[0] || "")

  const [causeList, setCauseList] = useState([])
  const [cause, setCause] = useState("")
  const [disabledCause, setDisabledCause] = useState(true)


  const [animal, setAnimal] = useState("")
  const [animalListName, setAnimalListName] = useState([])
  const [animalName, setAnimalName] = useState("")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Animals, getAnimalsRest } = useGetAnimals(property_id, 1, 1000000) 
  

  useEffect(() => {

    if (Animals.length > 0) {
      const animalListData = Animals.map(x => x?.name ? `${x?.earring} - ${x?.name}` : x?.earring)
      setAnimalListName(animalListData)
      setAnimalName(animalListData[0])
    }

  }, [Animals])

  useEffect(() => {

    if (animalName) {

      const animalSelected = Animals.find(x => {

        if (animalName.indexOf(x.name) > 0 || animalName && animalName === x?.earring) {
          return x
        }
      })

      setAnimal(animalSelected)

    }

  }, [animalName])

  useEffect(() => {

    if (reason === "Morte") {
      setDisabledCause(false)

      setCauseList(reasonDeathList)
    } else if (reason === "Venda") {
      setDisabledCause(false)

      setCauseList(reasonSaleList)
    } else {
      setDisabledCause(true)
    }

  }, [reason])


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (reason) {
      data.reason = reason
    } else {
      allFieldsFilled = false
    }

    if (cause) {
      data.cause = cause
    }

    if (!animal) {
      allFieldsFilled = false
    }

    if (date) {
      const textDate = date?.replace(/-/g, "/")
      data.date = textDate
    } else {
      allFieldsFilled = false
    }

    if (allFieldsFilled) {

      addAnimalDisposal({ data, animal_id: animal?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (getAnimalsRest?.isPending || addAnimalDisposalRest?.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Animal*"
            value={animalName}
            list={animalListName}
            setValue={(e) => { setAnimalName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Motivo*"
          value={reason}
          list={reasonList}
          setValue={(e) => { setReason(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Causa"
          disabled={disabledCause}
          value={cause}
          list={causeList}
          setValue={(e) => { setCause(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data do descarte*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default AnimalDisposalAdd