import React, { useRef, useEffect, useState } from 'react'

import { Icon } from '@iconify/react'

function Input(props) {

  const [labelClassName, setLabelClassName] = useState("")
  const [passwordView, setPasswordView] = useState(false)
  const [value, setValue] = useState("")
  const [type, setType] = useState(props.type)

  const borderColor = props.borderColor ? props.borderColor : "white"
  const textColor = props.textColor ? props.textColor : "white"
  const backColor = props.backColor ? props.backColor : "white"


  useEffect(() => {

    if (props.value) {
      setValue(props.value)
      setLabelClassName(`absolute bg-${backColor} z-0 text-xs text-${textColor} opacity-100 left-2 top-[-5px] px-2 cursor-text  transform transition-all`)
    } else {
      setLabelClassName(`absolute bg-${backColor}  text-${textColor} ${props.type == "date" ? "opacity-100 top-[-6px] text-xs" : "opacity-80 -z-10 text-base"} left-2 top-3 px-2 cursor-text peer-focus:opacity-100 peer-focus:text-xs peer-focus:top-[-6px] peer-focus:z-0  transform transition-all`)
    }

  }, [])

  useEffect(() => {

    if (props.value || type === 'month') {
      setLabelClassName(`absolute bg-${backColor} z-0 text-xs text-${textColor} opacity-100 left-2 top-[-5px] px-2 cursor-text  transform transition-all`)
    } else {
      setLabelClassName(`absolute bg-${backColor}  text-${textColor} ${props.type == "date" ? "opacity-100 top-[-6px] text-xs" : "opacity-80 -z-10 text-base"} left-2 top-3 px-2 cursor-text peer-focus:opacity-100 peer-focus:text-xs peer-focus:top-[-6px] peer-focus:z-0  transform transition-all`)
    }

  }, [props.value, type])

  useEffect(() => {
    props.setValue(value)
  }, [value])


  useEffect(() => {
    if (props.type === "password") {
      if (passwordView) {
        setType("text")
      } else {
        setType("password")
      }
    }

  }, [passwordView])

  return (
    <div className={`relative group -z-0 w-full h-12 bg-transparent`}>
      <input
        type={type}
        className={`w-full h-full bg-transparent border border-${borderColor} outline-none placeholder:italic pl-4 pr-2 placeholder:text-${textColor} text-${textColor} peer rounded focus:border-2`}
        value={props.value}
        onChange={e => setValue(e.target.value)}
        disabled={props.disabled ? true : false}
        placeholder=''
      />

      <label
        id={`label${props.placeholder}`}
        className={labelClassName}
      >
        {props.placeholder}
      </label>
      {
        props?.type === "password" &&
        <button className="absolute top-2 right-4 p-1" onClick={() => setPasswordView(prev => !prev)}>
          {
            passwordView ?
              <Icon icon="mdi:eye-outline" className={`text-lg font-bold text-${textColor}`} /> :
              <Icon icon="mdi:eye-off-outline" className={`text-lg font-bold text-${textColor}`} />
          }

        </button>
      }

    </div>
  )
}

export default Input



