import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelectMultiple from '../../components/InputSelectMultiple'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import InputBoolean from '../../components/InputBoolean'
import InputSelect from '../../components/InputSelect'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useGetProperties } from '../../queries/PropertyQueries'
import { useUpdateUser } from '../../queries/UserQueries'
import { useGetUserTypes } from '../../queries/UserTypeQueries'

import { phoneMask, cpfMask } from '../../utils'


function UserUpdate(props) {

  const selectedUser = props.data || {}

  const { properties, getPropertiesRest } = useGetProperties()
  const { updateUser, updateUserRestI } = useUpdateUser()
  const { UserTypes, getUserTypesRest } = useGetUserTypes()

  const [propertyListName, setPropertyListName] = useState([])
  const [propertyNames, setPropertyNames] = useState("")
  const [propertyArr, setPropertyArr] = useState([])
  const [selectedList, setSelectedList] = useState([])

  const [userTypeListName, setUserTypeListName] = useState([])
  const [userTypeName, setUserTypeName] = useState("")
  const [userType, setUserType] = useState([])

  const [name, setName] = useState(selectedUser?.name || "")
  const [email, setEmail] = useState(selectedUser?.email || "")
  const [phone, setPhone] = useState(selectedUser?.phone || "")
  const [cpf, setCpf] = useState(selectedUser?.cpf || "")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [active, setActive] = useState(true)

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  useEffect(() => {

    if(properties.length > 0) {
      const list = properties.map(x => x.name)
      setPropertyListName(list)
    }   

    const selectedItems = selectedUser.PropertyManagers.map(x => x.Property.name)

    setSelectedList(selectedItems)

  }, [])

  useEffect(() => {
    if (properties.length > 0) {
      const list = properties.map(x => x.name)
      setPropertyListName(list)
    }
  }, [properties])

  useEffect(() => {
    if (UserTypes.length > 0) {
      const list = UserTypes.map(x => x.name)
      setUserTypeListName(list)
      setUserTypeName(list[0])
    }
  }, [UserTypes])


  useEffect(() => {

    if (propertyNames) {

      const propertySelected = properties.filter(x => propertyNames.indexOf(x.name) !== -1).map(y => ({ property_id: y.id }))

      //console.log(propertySelected)

      setPropertyArr(propertySelected)

    }

  }, [propertyNames])


  useEffect(() => {

    if (userTypeName) {

      const userTypeSelected = UserTypes.find(x => x.name === userTypeName)

      setUserType(userTypeSelected)

    }

  }, [userTypeName])


  const submit = async () => {

    let data = {}
    let dataUpdated = false

    const listSelected = properties.filter(x => selectedList.indexOf(x.name) !== -1).map(y => ({ property_id: y.id }))

    const is_same = JSON.stringify(listSelected) === JSON.stringify(propertyArr)

    if (propertyArr.length > 0 && !is_same) {
      data.properties = propertyArr
      dataUpdated = true
    }

    if (userType?.id && userType?.id !== selectedUser?.user_type_id) {
      data.user_type_id = userType?.id
      dataUpdated = true
    }

    if (name && name !== selectedUser?.name) {
      data.name = name
      dataUpdated = true
    }

    if (email && email !== selectedUser?.email) {
      data.email = email
      dataUpdated = true
    }

    if (phone && phone.replace(/[^0-9]/g, '') !== selectedUser?.phone) {
      data.phone = phone
      dataUpdated = true
    }

    if (cpf && cpf.replace(/[^0-9]/g, '') !== selectedUser?.cpf) {
      data.cpf = cpf
      dataUpdated = true
    }

    if ((password || confirmPassword) && password !== confirmPassword) {
      alert("As senhas devem ser iguais!")
      return
    }

    if (password) {
      data.password = password
      dataUpdated = true
    }

    if (confirmPassword) {
      data.confirm_password = confirmPassword
      dataUpdated = true
    }

    if (typeof active == "boolean" && active !== selectedUser?.active) {
      data.active = active
      dataUpdated = true
    }

    if (dataUpdated) {

      updateUser({ data, user_id: selectedUser?.id })

      props.setOpenModal(false)


    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelectMultiple
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Propriedade*"
            value={propertyNames}
            list={propertyListName}
            selectedList={selectedList}
            setValue={(e) => { setPropertyNames(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddProperty(true)} />
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tipo*"
          value={userTypeName}
          list={userTypeListName}
          setValue={(e) => { setUserTypeName(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="E-mail*"
          value={email}
          setValue={(e) => { setEmail(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Phone*"
          value={phone}
          setValue={(e) => { setPhone(phoneMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="CPF"
          value={cpf}
          setValue={(e) => { setCpf(cpfMask(e)) }}
        />

        <Input
          type="password"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Senha*"
          value={password}
          setValue={(e) => { setPassword(e) }}
        />

        <Input
          type="password"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Confirme a senha*"
          value={confirmPassword}
          setValue={(e) => { setConfirmPassword(e) }}
        />

        <InputBoolean
          title="Ativo?"
          value={active}
          setValue={(e) => { setActive(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default UserUpdate