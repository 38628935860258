import React from 'react'
import { Icon } from '@iconify/react'

function ModalSide(props) {
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto flex justify-end transition-all mt-1">

      <div
        className="fixed inset-0 w-full h-full bg-black opacity-40"
        onClick={() => props.setOpenModal(false)}
      ></div>

      <div className="flex items-center justify-end min-h-screen">

        <div className="absolute max-w-lg min-h-screen p-4 top-0 bg-white overflow-auto">

          <div className='flex items-center border-b border-primaryBackground space-x-4 pb-6 pt-2 mb-4'>
            <button onClick={() => props.setOpenModal(false)}>
              <Icon icon="carbon:close-outline" className='text-3xl text-primaryBackground' />
            </button>
            <span className='text-lg font-semibold text-primaryBackground'>{props.title}</span>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default ModalSide