import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'
import PropertyUpdate from '../PropertyUpdate'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetProperties, useDestroyProperty } from '../../queries/PropertyQueries'

function PropertyList() {

  const { properties, getPropertiesRest } = useGetProperties()
  const { destroyProperty, destroyPropertyRest } = useDestroyProperty()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [selectedProperty, setSelectedProperty] = useState()


  const handleDeleteProperty = async () => {
    destroyProperty(selectedProperty?.id)
    setShowModalConfirm(false)
  }

  if (getPropertiesRest.isPending || destroyPropertyRest?.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Propriedades" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>

          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>

        </BackgroundData>


        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Nome</th>
                  <th scope="col" className="px-6 py-3">Proprietário</th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {properties?.length > 0 && properties.map((item, index) => {

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{item?.name}</th>
                      <td className="px-6 py-4">{item?.owner}</td>
                      <th>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedProperty(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedProperty(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

          </div>

        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Nova propriedade" ><PropertyAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar propriedade"><PropertyUpdate setOpenModal={setShowModalUpdate} data={selectedProperty} /></ModalSide>}

      {showModalConfirm &&
        <ModalConfirm
          setOpenModal={setShowModalConfirm}
          message="Tem certeza que deseja excluir?"
          confirmFunction={() => { handleDeleteProperty() }}
        />}
    </Menu>
  )
}

export default PropertyList