import React, { useState } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import Loading from '../../components/Loading'

import BankAccountAdd from '../BankAccountAdd'
import BankAccountUpdate from '../BankAccountUpdate'


import { usePropertyStore } from '../../stores/propertyStore'

import { useDestroyBankAccount, useGetBankAccounts } from '../../queries/BankAccountQueries'

import { brazilianCurrencyMask } from '../../utils'

function BankAccountList() {

  const { selectedProperty } = usePropertyStore()

  const { destroyBankAccount, destroyBankAccountRest } = useDestroyBankAccount()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [selectedBankAccount, setSelectedBankAccount] = useState()

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { BankAccounts, getBankAccountsRest } = useGetBankAccounts(property_id)


  const handleDeleteBankAccount = async () => {

    destroyBankAccount(selectedBankAccount.id)

    setShowModalConfirm(false)
  }

  if (getBankAccountsRest.isPending || destroyBankAccountRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Conta Bancária" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>

        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Descrição</th>
                  <th scope="col" className="px-6 py-3">Banco</th>
                  <th scope="col" className="px-6 py-3">Saldo</th>

                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(BankAccounts) && BankAccounts.map((item, index) => {

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <td className="px-6 py-4">{item?.description}</td>
                      <td className="px-6 py-4">{item?.bank}</td>
                      <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(item?.balance)}`}</td>

                      <th className=''>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedBankAccount(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedBankAccount(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

          </div>


        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Nova conta bancária" ><BankAccountAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar da Conta Bancária"><BankAccountUpdate setOpenModal={setShowModalUpdate} data={selectedBankAccount} /></ModalSide>}

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteBankAccount() }} />}
    </Menu>
  )
}

export default BankAccountList