
import { DateTime } from 'luxon'

export function setDatePostFormat(oldDate) {

  return oldDate?.replace(/-/g, "/")
}

export function setDateBrazilianFormat(oldDate) {

  if (oldDate) {
    const date = new Date(oldDate)
    //const date = DateTime.fromISO(oldDate, { zone: 'America/Chicago' }).toJSDate()

    return `${date?.getDate() < 10 ? '0' + date?.getDate() : date?.getDate()}/${(date?.getMonth() + 1) < 10 ? '0' + (date?.getMonth() + 1) : date?.getMonth() + 1}/${date?.getFullYear()}`
  } else {
    return "00/00/0000"
  }

}

export function setInputDateFormat(oldDate) {

  if (oldDate) {

    oldDate = new Date(oldDate)

    const date = DateTime.fromJSDate(oldDate).toJSDate()

    return `${date?.getFullYear()}-${(date?.getMonth() + 1) < 10 ? '0' + (date?.getMonth() + 1) : date?.getMonth() + 1}-${date?.getDate() < 10 ? '0' + date?.getDate() : date?.getDate()}`
  } else {
    return "00-00-0000"
  }

}

export const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]


export const ageCalculate = (date) => {
  const now = new Date()
  const birthDate = new Date(date)

  let milliDay = 1000 * 60 * 60 * 24 // a day in milliseconds

  let ageInDays = parseInt((now - birthDate) / milliDay)

  if (ageInDays >= 365.25) {
    let years = parseInt(parseInt(ageInDays) / 365.25)
    return years > 1 ? years + " anos" : years + " ano"
  } else {
    let months = parseInt(parseInt(ageInDays) / 30)
    return months > 1 ? months + " meses" : months + " mês"
  }

}

export const setMonthAndYearBrazilianFormat = (date) => {

  if (date) {
    const newDate = new Date(date)
    const month = newDate.getMonth()
    const fullYear = newDate.getFullYear()

    return `${months[month]} de ${fullYear}`
  } else {
    return '-'
  }

}

export const setInputMonthAndYearFormat = (date) => {

  if (date) {
    const newDate = new Date(date)
    const month = ((newDate.getMonth() + 1) < 10) ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1
    const fullYear = newDate.getFullYear()

    return `${fullYear}-${month}`
  } else {
    return '-'
  }

}
