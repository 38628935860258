import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import LactationInductionAdd from '../LactationInductionAdd'
import LactationInductionUpdate from '../LactationInductionUpdate'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetLactationInductions, useDestroyLactationInduction } from '../../queries/LactationInductionQueries'

import { setDateBrazilianFormat } from '../../utils'


function LactationInductionList() {

  const { selectedProperty } = usePropertyStore()

  const { destroyLactationInduction, destroyLactationInductionRest } = useDestroyLactationInduction()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [selectedLactationInduction, setSelectedLactationInduction] = useState()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { LactationInductions, getLactationInductionsRest, lactationInductionsPagination } = useGetLactationInductions(property_id, page, limit)


  const handleDeleteLactationInduction = async () => {

    destroyLactationInduction(selectedLactationInduction?.id)

    setShowModalConfirm(false)
  }

  if (getLactationInductionsRest.isPending || destroyLactationInductionRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Indução de Lactação" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>

        <BackgroundData>


          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Animal</th>
                  <th scope="col" className="px-6 py-3">Início da Lactação</th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(LactationInductions) && LactationInductions.map((item, index) => {

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <td className="px-6 py-4">{item?.Animal?.name ? `${item?.Animal?.earring} - ${item?.Animal?.name}` : item?.Animal?.earring}</td>
                      <td className="px-6 py-4">{setDateBrazilianFormat(item?.date)}</td>

                      <th className=''>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedLactationInduction(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedLactationInduction(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

            <div className='flex items-center justify-center'>
              <ButtonsPagination

                data={lactationInductionsPagination}
                page={(page) => { setPage(page) }}
                limit={(limit) => { setLimit(limit) }}
              />
            </div>

          </div>

        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Nova indução de Lactação" ><LactationInductionAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar indução de Lactação"><LactationInductionUpdate setOpenModal={setShowModalUpdate} data={selectedLactationInduction} /></ModalSide>}

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteLactationInduction() }} />}
    </Menu>
  )
}

export default LactationInductionList