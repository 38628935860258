import { useQuery, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetCostCenters = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getCostCenters', property_id, page, limit]

  const { data: { data: CostCenters = [] } = [], ...getCostCenterRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get('cost_centers')),
    placeholderData: keepPreviousData,
  })  

  return { CostCenters, getCostCenterRest }
}


