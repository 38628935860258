import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import Switch from '../../components/Switch'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useUpdateBankAccount } from '../../queries/BankAccountQueries'

import { brazilianCurrencyMask, toUSACurrency, banksList } from '../../utils'

function BankAccountUpdate(props) {

  const selectedBankAccount = props.data || {}
  
  const { updateBankAccount, updateBankAccountRest } = useUpdateBankAccount() 

  const [banksListName, setBanksListName] = useState([])
  const [typeList, setTypeList] = useState([])

  const [description, setDescription] = useState(selectedBankAccount?.description || "")
  const [type, setType] = useState(selectedBankAccount?.type || "")
  const [bank, setBank] = useState(selectedBankAccount?.bank || "")
  const [owner, setOwner] = useState(selectedBankAccount?.owner || "")
  const [agency, setAgency] = useState(selectedBankAccount?.agency || "")
  const [digitAgency, setDigitAgency] = useState(selectedBankAccount?.digit_agency || "")
  const [account, setAccount] = useState(selectedBankAccount?.account || "")
  const [digitAccount, setDigitAccount] = useState(selectedBankAccount?.digit_account || "")
  const [balance, setBalance] = useState(selectedBankAccount?.balance || "")
  const [observation, setObservation] = useState(selectedBankAccount?.observation || "")
  const [active, setActive] = useState(selectedBankAccount?.active)

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  useEffect(() => {   

    const banks = banksList.map(x => x.label)
    setBanksListName(banks)   

    const types = [
      "Conta Corrente",
      "Conta Poupança",
      "Conta Salário",
      "Saldo em Caixa"
    ]

    setTypeList(types)   

  }, [])

  const submit = async () => {

    let data = {}
    let dataUpdated = false    

    if (description && description !== selectedBankAccount?.description) {
      data.description = description
      dataUpdated = true
    }

    if (type && type !== selectedBankAccount?.type) {
      data.type = type
      dataUpdated = true
    }

    if (bank && bank !== selectedBankAccount?.bank) {
      data.bank = bank
      dataUpdated = true
    }

    if (owner && owner !== selectedBankAccount?.owner) {
      data.owner = owner
      dataUpdated = true
    }

    if (agency && agency !== selectedBankAccount?.agency) {
      data.agency = parseInt(agency)
      dataUpdated = true
    }

    if (digitAgency && digitAgency !== selectedBankAccount?.digitAgency) {
      data.digit_agency = parseInt(digitAgency)
      dataUpdated = true
    }

    if (account && account !== selectedBankAccount?.account) {
      data.account = parseInt(account)
      dataUpdated = true
    }

    if (digitAccount && digitAccount !== selectedBankAccount?.digitAccount) {
      data.digit_account = parseInt(digitAccount)
      dataUpdated = true
    }

    if (balance && Math.trunc(toUSACurrency(balance) * 100) !== (toUSACurrency(selectedBankAccount?.balance))) {
      data.balance = Math.trunc(toUSACurrency(balance) * 100)
      dataUpdated = true
    }

    if (active !== selectedBankAccount?.active) {
      data.active = active
      dataUpdated = true
    }

    if (observation && observation !== selectedBankAccount?.observation) {
      data.observation = observation
      dataUpdated = true
    }

    if (dataUpdated) {

      updateBankAccount({ data, bank_account_id: selectedBankAccount?.id })

      props.setOpenModal(false)
    }

  }

  if (updateBankAccountRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Descrição*"
          value={description}
          setValue={(e) => { setDescription(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tipo*"
          value={type}
          list={typeList}
          setValue={(e) => { setType(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Banco*"
          value={bank}
          list={banksListName}
          setValue={(e) => { setBank(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Agência*"
          value={agency}
          setValue={(e) => { setAgency(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Dígito da Agência"
          value={digitAgency}
          setValue={(e) => { setDigitAgency(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Conta*"
          value={account}
          setValue={(e) => { setAccount(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Dígito da conta"
          value={digitAccount}
          setValue={(e) => { setDigitAccount(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Saldo inicial"
            value={balance}
            setValue={(e) => { setBalance(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Proprietário da conta"
          value={owner}
          setValue={(e) => { setOwner(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Observação"
          value={observation}
          setValue={(e) => { setObservation(e) }}
        />

        <Switch
          active={active}
          setValue={(e) => { setActive(e) }}
          activeText="Ativo"
          inactiveText="Inativo"
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Property" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default BankAccountUpdate