import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { brazilianCurrencyMask, brazilianCurrencyToFloat } from '../../utils'

import { useUpdateWeighing } from '../../queries/WeighingQueries'
import { useGetAnimals } from '../../queries/AnimalQueries'

import { usePropertyStore } from '../../stores/propertyStore'

function WeighingUpdate(props) {

  const selectedWeighing = props?.data || {}

  const { selectedProperty } = usePropertyStore()

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { updateWeighing, updateWeighingRest } = useUpdateWeighing()
  const { Animals, getAnimalsRest } = useGetAnimals(property_id, 1, 1000000)

  const [weight, setWeight] = useState(selectedWeighing?.weight * 100 || "")
  const [height, setHeight] = useState(selectedWeighing?.height * 100 || "")
  const [observation, setObservation] = useState(selectedWeighing?.observation || "")

  const [animal, setAnimal] = useState("")
  const [animalListName, setAnimalListName] = useState([])
  const [animalName, setAnimalName] = useState("")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  useEffect(() => {

    if (Animals.length > 0) {

      const animalListData = Animals.map(x => x?.name ? `${x?.earring} - ${x?.name}` : x?.earring)
      setAnimalListName(animalListData)

      if (selectedWeighing?.animal_id) {
        const animalNameStart = Animals.find((x) => {
          if (x?.id === selectedWeighing?.animal_id) {
            return x
          }
        })

        setAnimalName(`${animalNameStart.earring} - ${animalNameStart.name}`)
      }

    }

  }, [])

  useEffect(() => {

    if (Animals) {
      const animalListData = Animals.map(x => x?.name ? `${x?.earring} - ${x?.name}` : x?.earring)
      setAnimalListName(animalListData)
    }

  }, [Animals])

  useEffect(() => {

    if (animalName) {

      const animalSelected = Animals.find(x => {

        if (animalName.indexOf(x.name) > 0 || animalName && animalName === x?.earring) {
          return x
        }
      })

      setAnimal(animalSelected)

    }

  }, [animalName])

  const changeWeight = (value) => {
    const newWeight = brazilianCurrencyMask(value)

    setWeight(newWeight)
  }

  const changeHeight = (value) => {
    const newPrice = brazilianCurrencyMask(value)

    setHeight(newPrice)
  }


  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (weight && weight !== selectedWeighing?.weight) {
      data.weight = brazilianCurrencyToFloat(weight)
      dataUpdated = true
    }

    if (height && height !== selectedWeighing?.height) {
      data.height = brazilianCurrencyToFloat(height)
      dataUpdated = true
    }

    if (animal.id !== selectedWeighing?.animal_id) {
      data.animal_id = animal.id
      dataUpdated = true
    }

    if (observation && observation !== selectedWeighing?.observation) {
      data.observation = observation
    }

    if (dataUpdated) {

      updateWeighing({ data, weighing_id: selectedWeighing.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }


  if (getAnimalsRest.isPending || updateWeighingRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Animal"
            value={animalName}
            list={animalListName}
            setValue={(e) => { setAnimalName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Peso"
            value={weight}
            setValue={(e) => { changeWeight(e) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>KG</p>
          </div>
        </div>


        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Altura"
            value={height}
            setValue={(e) => { changeHeight(e) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>M</p>
          </div>
        </div>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Observação"
          value={observation}
          setValue={(e) => { setObservation(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default WeighingUpdate