import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { useGetAnimals } from '../../queries/AnimalQueries'
import { useAddLactationInduction } from '../../queries/LactationInductionQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { setDatePostFormat } from '../../utils'


function LactationInductionAdd(props) {

  const { selectedProperty } = usePropertyStore()
 
  const { addLactationInduction, addLactationInductionRest } = useAddLactationInduction()

  const [date, setDate] = useState("")
  const [observation, setObservation] = useState("")

  const [mother, setMother] = useState("")
  const [motherListName, setMotherListName] = useState([])
  const [motherName, setMotherName] = useState("")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Animals, getAnimalsRest } = useGetAnimals(property_id, 1, 1000000) 


  useEffect(() => {

    const motherListData = Animals.filter((x) => {
      if (x?.sex === "Fêmea") {
        return true
      }
    }).map(x => x?.name ? `${x?.earring} - ${x?.name}` : x?.earring)

    setMotherListName(motherListData)
    setMotherName(motherListData[0])

  }, [Animals])


  useEffect(() => {

    if (motherName) {

      const animalSelected = Animals.find(x => {

        if (motherName.indexOf(x.name) > 0 || motherName && motherName === x?.earring) {
          return x
        }
      })

      setMother(animalSelected)

    }

  }, [motherName])


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (date) {
      data.date = setDatePostFormat(date)
    } else {
      allFieldsFilled = false
    }

    if (!mother) {
      allFieldsFilled = false
    }

    if (observation) {
      data.observation = observation
    }


    if (allFieldsFilled) {

      addLactationInduction({ data, animal_id: mother.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if(getAnimalsRest.isPending || addLactationInductionRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Animal*"
            value={motherName}
            list={motherListName}
            setValue={(e) => { setMotherName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data do início da lactação*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Observação"
          value={observation}
          setValue={(e) => { setObservation(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default LactationInductionAdd