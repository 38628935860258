import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'


// ### UPDATE

export const useUpdateInstallment = () => {

  const queryClient = useQueryClient()

  const { mutate: updateInstallment, ...updateInstallmentRest } = useMutation({
    mutationFn: async ({ data, revenue_installment_id }) => (await api.put(`revenue_installments/${revenue_installment_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRevenues'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateInstallment, updateInstallmentRest }
}
