import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import MatingAdd from '../MatingAdd'

import { useGetMatings } from '../../queries/MatingQueries'
import { useAddCalving } from '../../queries/CalvingQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { setDateBrazilianFormat, setDatePostFormat } from '../../utils'

function CalvingAdd(props) {

  const calvingTypeList = [
    "Único",
    "Gemelar",
    "Triplo",
    "Quadruplo",
    "Inconclusivo"
  ]

  const situationList = [
    "Natural",
    "Com retenção",
    "Com auxílio",
    "Aborto",
    "Mumificado",
    "Natimorto"
  ]

  const sexList = [
    "Macho",
    "Fêmea"
  ]

  const { selectedProperty } = usePropertyStore()

  const { addCalving, addCalvingRest } = useAddCalving()

  const [sex, setSex] = useState(sexList[0] || "")
  const [date, setDate] = useState("")
  const [situation, setSituation] = useState(situationList[0] || "")
  const [type, setType] = useState(calvingTypeList[0] || "")

  const [mating, setMating] = useState("")
  const [matingListName, setMatingListName] = useState([])
  const [matingName, setMatingName] = useState("")

  const [showModalAddMating, setShowModalAddMating] = useState(false)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Matings, getMatingsRest } = useGetMatings(property_id, 1, 1000000)

  useEffect(() => {

    if (Matings[0]?.id) {

      const matingListData = Matings.map((x) => `${x.id} - ${x?.Cow?.name} - ${setDateBrazilianFormat(x?.date)}`)

      setMatingListName(matingListData)
      setMatingName(matingListData[0])

    }

  }, [Matings])


  useEffect(() => {

    if (matingName) {

      const matingSelected = Matings.find(x => {

        if (matingName === `${x.id} - ${x?.Cow?.name} - ${setDateBrazilianFormat(x.date)}`) {
          return x
        }
      })

      setMating(matingSelected)

    }

  }, [matingName])


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (mating) {
      data.mating_id = mating.id
    } else {
      allFieldsFilled = false
    }

    if (type) {
      data.type = type
    } else {
      allFieldsFilled = false
    }

    if (situation) {
      data.situation = situation
    } else {
      allFieldsFilled = false
    }

    if (sex) {
      data.sex = sex
    } else {
      allFieldsFilled = false
    }

    if (date) {
      data.date = setDatePostFormat(date)
    } else {
      allFieldsFilled = false
    }

    if (allFieldsFilled) {

      addCalving({ data, mating_id: mating?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (getMatingsRest.isPending || addCalvingRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Cobertura / Inseminação*"
            value={matingName}
            list={matingListName}
            setValue={(e) => { setMatingName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddMating(true)} />
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Sexo*"
          value={sex}
          list={sexList}
          setValue={(e) => { setSex(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Situação*"
          value={situation}
          list={situationList}
          setValue={(e) => { setSituation(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data do Nascimento*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tipo*"
          value={type}
          list={calvingTypeList}
          setValue={(e) => { setType(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddMating &&
        <ModalSide setOpenModal={setShowModalAddMating} title="Nova Cobertura" >
          <MatingAdd setOpenModal={setShowModalAddMating} />
        </ModalSide>
      }



    </div >

  )
}

export default CalvingAdd