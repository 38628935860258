import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelectMultiple from '../../components/InputSelectMultiple'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import InputBoolean from '../../components/InputBoolean'
import InputSelect from '../../components/InputSelect'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useAddUserByUser } from '../../queries/UserQueries'
import { useGetProperties } from '../../queries/PropertyQueries'
import { useGetUserTypes } from '../../queries/UserTypeQueries'

import { phoneMask, cpfMask } from '../../utils'


function UserAdd(props) {

  const { getPropertiesRest, properties } = useGetProperties()
  const { addUserByUser, addUserByUserRest } = useAddUserByUser()
  const { UserTypes, getUserTypesRest } = useGetUserTypes()

  const [propertyListName, setPropertyListName] = useState([])
  const [propertyNames, setPropertyNames] = useState("")
  const [propertyArr, setPropertyArr] = useState([])

  const [userTypeListName, setUserTypeListName] = useState([])
  const [userTypeName, setUserTypeName] = useState("")
  const [userType, setUserType] = useState([])

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [cpf, setCpf] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [active, setActive] = useState(true)


  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  useEffect(() => {

    if (properties.length > 0) {
      const list = properties.map(x => x.name)

      setPropertyListName(list)
    }

  }, [])

  useEffect(() => {
    
    if (properties.length > 0) {
      const list = properties.map(x => x.name)
      setPropertyListName(list)
    }
  }, [properties])

  useEffect(() => {

    if (UserTypes.length > 0) {
      const list = UserTypes.map(x => x.name)
      setUserTypeListName(list)
      setUserTypeName(list[0])
    }
  }, [UserTypes])


  useEffect(() => {

    if (propertyNames) {

      const propertySelected = properties.filter(x => propertyNames.indexOf(x.name) !== -1).map(y => ({ property_id: y.id }))

      setPropertyArr(propertySelected)

    }

  }, [propertyNames])


  useEffect(() => {

    if (userTypeName) {

      const userTypeSelected = UserTypes.find(x => x.name === userTypeName)

      setUserType(userTypeSelected)

    }

  }, [userTypeName])


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (propertyArr.length > 0) {
      data.properties = propertyArr
    } else {
      allFieldsFilled = false
    }

    if (!userType?.id) {
      allFieldsFilled = false
    }

    if (name) {
      data.name = name
    } else {
      allFieldsFilled = false
    }

    if (confirmEmail !== email) {
      alert("O e-mail e a confirmação devem ser iguais")
      return
    }

    if (email) {
      data.email = email
    } else {
      allFieldsFilled = false
    }

    if (confirmEmail) {
      data.confirm_email = confirmEmail
    } else {
      allFieldsFilled = false
    }

    if (phone) {
      data.phone = phone
    } else {
      allFieldsFilled = false
    }

    if (cpf) {
      data.cpf = cpf
    }

    if (password !== confirmPassword) {
      alert("As senhas devem ser iguais!")
      return
    }

    if (password) {
      data.password = password
    } else {
      allFieldsFilled = false
    }

    if (confirmPassword) {
      data.confirm_password = confirmPassword
    } else {
      allFieldsFilled = false
    }

    if (typeof active == "boolean") {
      data.active = active
    } else {
      allFieldsFilled = false
    }

    if (allFieldsFilled) {

      addUserByUser({ data, user_type_id: userType?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (getPropertiesRest.isPending || addUserByUserRest.isPending || getUserTypesRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelectMultiple
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Propriedade*"
            value={propertyNames}
            list={propertyListName}
            setValue={(e) => { setPropertyNames(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddProperty(true)} />
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tipo*"
          value={userTypeName}
          list={userTypeListName}
          setValue={(e) => { setUserTypeName(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="E-mail*"
          value={email}
          setValue={(e) => { setEmail(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Confirmar e-mail*"
          value={confirmEmail}
          setValue={(e) => { setConfirmEmail(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Phone*"
          value={phone}
          setValue={(e) => { setPhone(phoneMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="CPF"
          value={cpf}
          setValue={(e) => { setCpf(cpfMask(e)) }}
        />

        <Input
          type="password"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Senha*"
          value={password}
          setValue={(e) => { setPassword(e) }}
        />

        <Input
          type="password"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Confirme a senha*"
          value={confirmPassword}
          setValue={(e) => { setConfirmPassword(e) }}
        />

        <InputBoolean
          title="Ativo?"
          value={active}
          setValue={(e) => { setActive(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default UserAdd