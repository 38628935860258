import React, { useRef, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'


function InputSelect(props) {

  const searchElement = useRef(null)

  const [showOptions, setShowOptions] = useState(false)
  const [list, setList] = useState(props.list || [])
  const [disabled, setDisabled] = useState(false)
  const [value, setValue] = useState("")

  const borderColor = props.borderColor ? props.borderColor : "white"
  const textColor = props.textColor ? props.textColor : "white"
  const backColor = props.backColor ? props.backColor : "white"

  const inputElement = useRef(null)
  useEffect(() => {
    inputElement.current?.focus()
  }, [])

  useEffect(() => {

    function handleClickOutside(event) {
      if (searchElement.current && !searchElement.current.contains(event.target)) {
        setShowOptions(false)
      } else {
        if (!disabled) {
          setShowOptions(true)
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside)


    return () => {

      document.removeEventListener("mousedown", handleClickOutside)
    }

  }, [searchElement, disabled])

  useEffect(() => {
    if (value) {
      props.setValue(value)
    }

  }, [value])

  useEffect(() => {
    setList(props.list)
  }, [props.list])

  useEffect(() => {
    setDisabled(props.disabled)
  }, [props.disabled])



  const updateValue = (event) => {

    var searchValue = event.target.value

    setValue(event.target.value)

    var newValue = props.list.filter((v, i) => {

      const lowerCaseItem = v.toString().toLowerCase()
      const lowerCaseValue = searchValue.toString().toLowerCase()

      return (lowerCaseItem.includes(lowerCaseValue))
    })   

    setList(newValue)

  }

  const selectedValue = (item) => {
    props.setValue(item)
    setShowOptions(false)
  }


  return (

    <div className={`relative group w-full h-12 bg-transparent`} >

      <div className='w-full min-h-12 flex cursor-pointer'
        ref={searchElement}
        disabled={disabled}
      >
        <input
          type="text"
          className={`w-full h-12 bg-transparent ${disabled ? "opacity-50 cursor-default" : "cursor-pointer"} border border-${borderColor} outline-none placeholder:italic pl-4 pr-2 placeholder:text-${textColor} text-${textColor} peer rounded focus:border-2`}
          value={props.value || ""}
          onChange={e => props.setValue(e.target.value)}
          disabled={disabled}
        />
        <label
          id={`label${props.placeholder}`}
          className={`absolute bg-${backColor} text-xs text-${textColor} ${disabled ? "opacity-80" : "opacity-100"} left-2 top-[-5px] px-2 cursor-text transform transition-all`}>
          {props.placeholder}
        </label>

        <Icon icon="icon-park-solid:down-one" className={`absolute text-${textColor} ${disabled ? "opacity-70" : ""} text-sm right-4 top-5`} />

        {showOptions &&
          <div className={`absolute w-full h-60 overflow-y-auto overflow-x-hidden mt-2 bg-white top-1 left-0 drop-shadow-lg z-[100]`}>
            <div className='flex w-full h-12 bg-gray-50 mb-4'>
              <input
                autoFocus="autofocus"
                type="text"
                className='`w-full h-12 bg-transparent border-b border-gray-600 outline-none placeholder:italic pl-4 pr-2 placeholder:text-${textColor} text-${textColor} peer'
                placeholder='Pesquisar'
                value={value}
                onChange={event => updateValue(event)}
                ref={inputElement}
              />
            </div>

            <div className='flex flex-col'>

              {Array.isArray(list) && list.length > 0 && list.map((item, index) => {
                return (
                  <div key={index}>
                    {item == props.value ?
                      <button className='w-full py-2 pl-4 text-left bg-gray-200 text-tertiaryBackground' onClick={() => { selectedValue(item) }}>
                        {item}
                      </button>
                      :
                      <button className='w-full py-2 pl-4 text-left hover:bg-gray-100' onClick={() => { selectedValue(item) }}>
                        {item}
                      </button>
                    }
                  </div>
                )
              })}


            </div>

          </div>

        }
      </div>
    </div>



  )
}

export default InputSelect



