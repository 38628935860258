import React from 'react'
import { Route, Routes, BrowserRouter } from "react-router-dom"

import { useTokenStore } from '../stores/authStore'

import Login from "../pages/Login"
import PropertyList from "../pages/PropertyList"
import ProductiveAreaList from '../pages/ProductiveAreaList'
import BatchList from '../pages/BatchList'
import AnimalList from '../pages/AnimalList'
import WeighingList from '../pages/WeighingList'
import AnimalDisposalList from '../pages/AnimalDisposalList'
import MatingList from '../pages/MatingList'
import CalvingList from '../pages/CalvingList'
import MilkingList from '../pages/MilkingList'
import MilkingAdd from '../pages/MilkingAdd'
import DailyProductionList from '../pages/DailyProductionList'
import StopLactationList from '../pages/StopLactationList'
import LactationInductionList from '../pages/LactationInductionList'
import MilkQualityList from '../pages/MilkQualityList'
import BankAccountList from '../pages/BankAccountList'
import CustomerSupplierList from '../pages/CustomerSupplierList'
import RevenueList from '../pages/RevenueList'
import ExpenseList from '../pages/ExpenseList'
import UserList from '../pages/UserList'
import InvestimentList from '../pages/InvestimentList'
import ReportProductiveList from '../pages/ReportProductiveList'
import ReportProductiveYearAnimalProduction from '../pages/ReportProductiveYearAnimalProduction'
import ReportProductiveAnimalMilkQuality from '../pages/ReportProductiveAnimalMilkQuality'
import ReportProductiveAnimalStopLactation from '../pages/ReportProductiveAnimalStopLactation'
import ReportReproductiveList from '../pages/ReportReproductiveList'
import ReportReproductiveCalvingPrediction from '../pages/ReportReproductiveCalvingPrediction'
import ReportReproductiveBirthByPeriod from '../pages/ReportReproductiveBirthByPeriod'
import ReportSalesList from '../pages/ReportSalesList'
import ReportRevenueByCategory from '../pages/ReportRevenuebyCategory'
import ReportMilkSales from '../pages/ReportMilkSales'
import ReportAnimalByBatch from '../pages/ReportAnimalByBatch'
import ReportBatchList from '../pages/ReportBatchList'
import ReportFinancialList from '../pages/ReportFinancialList'
import ReportFinancialDRE from '../pages/ReportFinancialDRE'
import UserSignUp from '../pages/UserSignUp'
import ReportProductiveMonthMilkQuality from '../pages/ReportProductiveMonthMilkQuality'
import ReportProductiveAnimalProductionByDay from '../pages/ReportProductiveAnimalProductionByDay'

import PrivateRoutes from './privateRoutes'

function MyRoutes() {

  const { token } = useTokenStore()

  return (

    <BrowserRouter>

      <Routes>

        <Route path="/" element={
          <PrivateRoutes>
            <PropertyList />
          </PrivateRoutes>
        }
        />

        <Route path="/productive_area" element={
          <PrivateRoutes>
            <ProductiveAreaList />
          </PrivateRoutes>
        }
        />

        <Route path="/batches" element={
          <PrivateRoutes>
            <BatchList />
          </PrivateRoutes>
        }
        />

        <Route path="/animals" element={
          <PrivateRoutes>
            <AnimalList />
          </PrivateRoutes>
        }
        />

        <Route path="/weighings" element={
          <PrivateRoutes>
            <WeighingList />
          </PrivateRoutes>
        }
        />

        <Route path="/animal_disposal" element={
          <PrivateRoutes>
            <AnimalDisposalList />
          </PrivateRoutes>
        }
        />

        <Route path="/matings" element={
          <PrivateRoutes>
            <MatingList />
          </PrivateRoutes>
        }
        />

        <Route path="/calvings" element={
          <PrivateRoutes>
            <CalvingList />
          </PrivateRoutes>
        }
        />

        <Route path="/milkings" element={
          <PrivateRoutes>
            <MilkingList />
          </PrivateRoutes>
        }
        />

        <Route path="/milkingsAdd" element={
          <PrivateRoutes>
            <MilkingAdd />
          </PrivateRoutes>
        }
        />

        <Route path="/daily_production" element={
          <PrivateRoutes>
            <DailyProductionList />
          </PrivateRoutes>
        }
        />

        <Route path="/stop_lactations" element={
          <PrivateRoutes>
            <StopLactationList />
          </PrivateRoutes>
        }
        />

        <Route path="/lactation_inductions" element={
          <PrivateRoutes>
            <LactationInductionList />
          </PrivateRoutes>
        }
        />


        <Route path="/milk_quality" element={
          <PrivateRoutes>
            <MilkQualityList />
          </PrivateRoutes>
        }
        />


        <Route path="/bank_accounts" element={
          <PrivateRoutes>
            <BankAccountList />
          </PrivateRoutes>
        }
        />


        <Route path="/customer_suppliers" element={
          <PrivateRoutes>
            <CustomerSupplierList />
          </PrivateRoutes>
        }
        />

        <Route path="/revenues" element={
          <PrivateRoutes>
            <RevenueList />
          </PrivateRoutes>
        }
        />

        <Route path="/expenses" element={
          <PrivateRoutes>
            <ExpenseList />
          </PrivateRoutes>
        }
        />

        <Route path="/users" element={
          <PrivateRoutes>
            <UserList />
          </PrivateRoutes>
        }
        />

        <Route path="/investiments" element={
          <PrivateRoutes>
            <InvestimentList />
          </PrivateRoutes>
        }
        />

        <Route path="/report_productive" element={
          <PrivateRoutes>
            <ReportProductiveList />
          </PrivateRoutes>
        }
        />

        <Route path="/report_year_animal_production" element={
          <PrivateRoutes>
            <ReportProductiveYearAnimalProduction />
          </PrivateRoutes>
        }
        />

        <Route path="/report_milk_quality" element={
          <PrivateRoutes>
            <ReportProductiveMonthMilkQuality />
          </PrivateRoutes>
        }
        />

        <Route path="/report_animal_stop_lactation" element={
          <PrivateRoutes>
            <ReportProductiveAnimalStopLactation />
          </PrivateRoutes>
        }
        />

        <Route path="/report_reproductive" element={
          <PrivateRoutes>
            <ReportReproductiveList />
          </PrivateRoutes>
        }
        />

        <Route path="/report_calving_prediction" element={
          <PrivateRoutes>
            <ReportReproductiveCalvingPrediction />
          </PrivateRoutes>
        }
        />

        <Route path="/report_calving_birth_by_period" element={
          <PrivateRoutes>
            <ReportReproductiveBirthByPeriod />
          </PrivateRoutes>
        }
        />

        <Route path="/report_sales" element={
          <PrivateRoutes>
            <ReportSalesList />
          </PrivateRoutes>
        }
        />

        <Route path="/report_revenue_by_category" element={
          <PrivateRoutes>
            <ReportRevenueByCategory />
          </PrivateRoutes>
        }
        />

        <Route path="/report_milk_sales" element={
          <PrivateRoutes>
            <ReportMilkSales />
          </PrivateRoutes>
        }
        />

        <Route path="/report_batch_list" element={
          <PrivateRoutes>
            <ReportBatchList />
          </PrivateRoutes>
        }
        />

        <Route path="/report_animal_by_batch" element={
          <PrivateRoutes>
            <ReportAnimalByBatch />
          </PrivateRoutes>
        }
        />

        <Route path="/report_financial_list" element={
          <PrivateRoutes>
            <ReportFinancialList />
          </PrivateRoutes>
        }
        />

        <Route path="/report_financial_dre" element={
          <PrivateRoutes>
            <ReportFinancialDRE />
          </PrivateRoutes>
        }
        />

        <Route path="/report_animal_production_by_day" element={
          <PrivateRoutes>
            <ReportProductiveAnimalProductionByDay />
          </PrivateRoutes>
        }
        />

        <Route path="/signUp" element={<UserSignUp />} />

        <Route path="/login" element={
          !token || Object.keys(token).length <= 0
            ? <Login />
            :
            <PrivateRoutes>
              <PropertyList />
            </PrivateRoutes>
        } />

      </Routes>
    </BrowserRouter>


  )
}

export default MyRoutes