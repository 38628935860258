import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useTokenStore = create(
  persist(
    (set) => ({
      token: {},
      setToken: (token) => set(() => ({ token }))      
    }),
    {
      name: 'Token'
    }
  )
)

export const useUserStore = create(
  persist(
    (set) => ({
      user: {},
      setUser: (user) => set(() => ({ user }))      
    }),
    {
      name: 'User'
    }
  )
)

