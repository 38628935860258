import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import InputBoolean from '../../components/InputBoolean'

import PropertyAdd from '../PropertyAdd'
import CustomerSupplierAdd from '../CustomerSupplierAdd'
import BankAccountAdd from '../BankAccountAdd'
import Loading from '../../components/Loading'

import { usePropertyStore } from '../../stores/propertyStore'

import { useUpdateInstallment } from '../../queries/ExpenseInstallmentsQueries'

import { brazilianCurrencyMask, toUSACurrency, setDatePostFormat, setInputDateFormat } from '../../utils'

function ExpenseInstallmentsUpdate(props) {

  const selectedInstallment = props?.data || {}

  const { updateInstallment, updateInstallmentRest } = useUpdateInstallment()
  
  const [dueDate, setDueDate] = useState("")
  const [value, setValue] = useState(selectedInstallment?.value || "")  
  const [paid, setPaid] = useState(selectedInstallment?.paid || false)
  const [paymentDate, setPaymentDate] = useState("") 

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)
  const [showModalAddCustomerSupllier, setShowModalAddCustomerSupllier] = useState(false)
  const [showModalAddBankAccount, setShowModalAddBankAccount] = useState(false)

  useEffect(() => {

  //  console.log(selectedInstallment?.payment_date)

    if (selectedInstallment?.due_date) {
      setDueDate(setInputDateFormat(selectedInstallment?.due_date))
    }

    if (selectedInstallment?.payment_date) {
      setPaymentDate(setInputDateFormat(selectedInstallment?.payment_date))
    }

  }, [])

  const submit = async () => {

    let data = {}
    let dataUpdated = false


    if (dueDate && dueDate !== setInputDateFormat(selectedInstallment?.due_date)) {
      data.due_date = setDatePostFormat(dueDate)
      dataUpdated = true
    }

    if (value && parseInt(selectedInstallment?.value) !== Math.trunc(toUSACurrency(value) * 100)) {
      data.value = Math.trunc((toUSACurrency(value) * 100))
      dataUpdated = true
    }

    if (typeof paid == "boolean" && paid !== selectedInstallment?.paid) {
      data.paid = paid
      dataUpdated = true
    }

    if (paid) {
      if (paymentDate) {
        data.payment_date = setDatePostFormat(paymentDate)
      } else {
        alert('A data de pagamento deve ser informada!')
        return
      }
    }

    if (paymentDate && paymentDate !== setInputDateFormat(selectedInstallment?.payment_date)) {
      data.payment_date = setDatePostFormat(paymentDate)
      dataUpdated = true
    }


    if (dataUpdated) {

     // console.log(data)

      updateInstallment({ data, expense_installment_id: selectedInstallment?.id })

      props.setOpenModal(false)

    } else {
      alert('Nenhum dado para ser atualizado!')
    }

  }

  if (updateInstallmentRest?.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>
        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data de vencimento*"
          value={dueDate}
          setValue={(e) => { setDueDate(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Valor*"
            value={value}
            setValue={(e) => { setValue(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <InputBoolean
          title="Paga?"
          value={paid}
          setValue={(e) => { setPaid(e) }}
        />

        {paid &&
          <Input
            type="date"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Data do Pagamento"
            value={paymentDate}
            setValue={(e) => { setPaymentDate(e) }}
          />
        }

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }
      {showModalAddCustomerSupllier &&
        <ModalSide setOpenModal={setShowModalAddCustomerSupllier} title="Novo Cliente / Fornecedor" >
          <CustomerSupplierAdd setOpenModal={setShowModalAddCustomerSupllier} />
        </ModalSide>
      }
      {showModalAddBankAccount &&
        <ModalSide setOpenModal={setShowModalAddBankAccount} title="Nova Conta Bancária" >
          <BankAccountAdd setOpenModal={setShowModalAddBankAccount} />
        </ModalSide>
      }

    </div >

  )
}

export default ExpenseInstallmentsUpdate