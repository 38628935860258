import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ModalSide from '../../components/ModalSide'
import BackgroundData from '../../components/BackgroundData'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useGetReportProductiveYearAnimalProductions } from '../../queries/ReportProductiveYearAnimalProductionQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { months, brazilianCurrencyMask } from '../../utils'

function ReportProductiveYearAnimalProduction() {

  const { selectedProperty } = usePropertyStore()  

  const [year, setYear] = useState(new Date().getFullYear())
  const [totalLitersDelivered, setTotalLitersDelivered] = useState(0)
  const [totalLitersConsumed, setTotalLitersConsumed] = useState(0)
  const [totalLitersDiscarted, setTotalLitersDiscarted] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalAnnual, setTotalAnnual] = useState(0)

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  const { YearAnimalProductions, getReportProductiveYearAnimalProductionsRest } = useGetReportProductiveYearAnimalProductions(selectedProperty?.id, year)

  useEffect(() => {

    if (YearAnimalProductions.length > 0) {
      setTotalLitersDelivered(YearAnimalProductions?.reduce((acc, item) => acc + item.liters_delivered, 0))
      setTotalLitersConsumed(YearAnimalProductions?.reduce((acc, item) => acc + item.liters_consumed, 0))
      setTotalLitersDiscarted(YearAnimalProductions?.reduce((acc, item) => acc + item.liters_discarted, 0))
      setTotalPrice(YearAnimalProductions?.reduce((acc, item) => acc + item.price, 0))
      setTotalAnnual(YearAnimalProductions?.reduce((acc, item) => acc + (parseInt(((item.liters_delivered / 100) * (item.price / 100)) * 100)), 0))

    } else {
      setTotalLitersDelivered(0)
      setTotalLitersConsumed(0)
      setTotalLitersDiscarted(0)
      setTotalPrice(0)

      setTotalAnnual(0)
    }

  }, [YearAnimalProductions])

  if (getReportProductiveYearAnimalProductionsRest?.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Produção Anual da Propriedade">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <div className='w-full flex flex-col justify-start space-y-6'>

          <BackgroundData>
            <div className='w-full flex flex-col lg:flex-row space-y-8 lg:space-y-0 items-center justify-around'>

              <div className='flex lg:w-1/3 w-full items-center justify-around text-3xl'>

                <button className='flex-1 flex justify-center items-center hover:text-4xl text-primaryBackground' onClick={() => setYear(parseInt(year) - 1)}>
                  <Icon icon="ep:arrow-left-bold" />
                </button>

                <span className='flex-1 flex justify-center items-center text-tertiaryBackground'>{year}</span>

                <button className='flex-1 flex justify-center items-center hover:text-4xl text-primaryBackground' onClick={() => setYear(parseInt(year) + 1)}>
                  <Icon icon="ep:arrow-right-bold" />
                </button>

              </div>

              {/* <div className='flex items-center space-x-2 lg:space-x-1'>
                <InputSelect
                  type="text"
                  borderColor="primaryBackground"
                  textColor="primaryBackground"
                  placeholder="Propriedade*"
                  value={propertyName}
                  list={propertyListName}
                  setValue={(e) => { setPropertyName(e) }}
                />
                <ButtonAdd onClick={() => setShowModalAddProperty(true)} />
              </div> */}

            </div>
          </BackgroundData>

          <BackgroundData>

            <div className="relative overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ">
                <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                  <tr>
                    <th scope="col" className="px-6 py-3">Mês</th>
                    <th scope="col" className="px-6 py-3">Litros Entregues</th>
                    <th scope="col" className="px-6 py-3">Litros Consumidos</th>
                    <th scope="col" className="px-6 py-3">Listros Descartados</th>
                    <th scope="col" className="px-6 py-3">Preço</th>
                    <th scope="col" className="px-6 py-3">Valor Recebido no Mês</th>
                  </tr>
                </thead>

                {months?.length > 0 && months.map((item, index) => {

                  let receivedValue

                  if (YearAnimalProductions.length > 0) {
                    receivedValue = parseInt((Number(YearAnimalProductions[index]?.liters_delivered) / 100) * (Number(YearAnimalProductions[index]?.price) / 100) * 100)
                  } else {
                    receivedValue = 0
                  }



                  return (
                    <tbody key={index}>
                      <tr className="bg-white border-b">
                        <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{item}</th>
                        <td className="px-6 py-4">{brazilianCurrencyMask(YearAnimalProductions[index]?.liters_delivered) || 0}</td>
                        <td className="px-6 py-4">{brazilianCurrencyMask(YearAnimalProductions[index]?.liters_consumed) || 0}</td>
                        <td className="px-6 py-4">{brazilianCurrencyMask(YearAnimalProductions[index]?.liters_discarted) || 0}</td>
                        <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(YearAnimalProductions[index]?.price)}` || 'R$ 0,00'}</td>
                        <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(receivedValue)}` || 'R$ 0,00'}</td>
                      </tr>
                    </tbody>
                  )
                })}

                <tfoot className='uppercase'>
                  <tr className='font-bold bg-gray-50 border-b '>
                    <th scope="row" className="px-6 py-4 whitespace-nowrap">Total do Ano:</th>
                    <td className="px-6 py-4">{brazilianCurrencyMask(totalLitersDelivered)}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(totalLitersConsumed)}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(totalLitersDiscarted)}</td>
                    <td className="px-6 py-4">-</td>
                    <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(totalAnnual)}`}</td>
                  </tr>
                  <tr className='font-bold bg-gray-50'>
                    <th scope="row" className="px-6 py-4 whitespace-nowrap">Média do ano:</th>
                    <td className="px-6 py-4">{brazilianCurrencyMask(parseInt(totalLitersDelivered / 12))}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(parseInt(totalLitersConsumed / 12))}</td>
                    <td className="px-6 py-4">{brazilianCurrencyMask(parseInt(totalLitersDiscarted / 12))}</td>
                    <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(parseInt(totalPrice / 12))}`}</td>
                    <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(parseInt(totalAnnual / 12))}`}</td>
                  </tr>
                </tfoot>

              </table>

            </div>

          </BackgroundData>

        </div>

        {showModalAddProperty &&
          <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
            <PropertyAdd setOpenModal={setShowModalAddProperty} />
          </ModalSide>
        }

      </div>
    </Menu>
  )
}

export default ReportProductiveYearAnimalProduction