import { useQuery, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetUserTypes = () => {

  const QUERY_KEY = ['getUsers']

  const { data: { data: UserTypes = [] } = [], ...getUserTypesRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get('user_types')),
    placeholderData: keepPreviousData,
  })

  return { UserTypes, getUserTypesRest }
}

