import React from 'react'
import { Icon } from '@iconify/react'

function Item(props) {

  const toggleElement = (id) => {

    let element = document.getElementById(id)

    let nowClassName = element.className

    if (nowClassName.search("hidden") !== -1) {
      nowClassName = nowClassName.replace("hidden", "flex")
      element.className = nowClassName
    } else if (nowClassName.search("flex") !== -1) {
      nowClassName = nowClassName.replace("flex", "hidden")
      element.className = nowClassName
    }

  }


  return (
    <div className='flex flex-col items-center justify-center w-[95%] group hover:bg-tertiaryBackground rounded-b'>
      <button
        className="flex items-center justify-start py-6 lg:py-2 px-4 w-full rounded group hover:shadow-md"
        onClick={() => {
          toggleElement(props.id)
          toggleElement(`${props.id}ArrowDownIcon`)
          toggleElement(`${props.id}ArrowUpIcon`)
        }}
      >
        <div>
          <Icon icon={props.iconName} className="flex-1 text-white lg:text-xl text-2xl group-hover:font-semibold" />
        </div>
        <span className='text-white lg:text-lg text-xl pl-4 pr-2 group-hover:font-semibold'>{props.title}</span>

        { props.children &&
          <>
            <div className='flex w-full justify-end' id={`${props.id}ArrowDownIcon`}>
              <Icon icon="ep:arrow-down" className="text-white text-sm group-hover:font-semibold" />
            </div>
            <div className='hidden w-full justify-end' id={`${props.id}ArrowUpIcon`}>
              <Icon icon="ep:arrow-up" className="text-white text-sm group-hover:font-semibold" />
            </div>
          </>
        }
      </button>

      <div className='hidden flex-col bg-transparent w-full bg-secondaryBackground' id={props.id}>
        {props.children}
      </div>
    </div>
  )
}

export default Item