import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetProductiveAreas = (property_id = 0) => {

  const QUERY_KEY = ['getProductiveAreas', property_id]

  const { data: { data: productiveAreas = [] } = [], ...getProductiveAreasRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`productive_areas?property_id=${property_id}`)),
    placeholderData: keepPreviousData,
  })

  return { productiveAreas, getProductiveAreasRest }
}

// ### ADD

export const useAddProductiveArea = () => {

  const queryClient = useQueryClient()

  const { mutate: addProductiveArea, ...addProductiveAreaRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`productive_areas/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProductiveAreas'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addProductiveArea, addProductiveAreaRest }
}

// ### UPDATE

export const useUpdateProductiveArea = () => {

  const queryClient = useQueryClient()

  const { mutate: updateProductiveArea, ...updateProductiveAreaRest } = useMutation({
    mutationFn: async ({ data, productive_area_id }) => (await api.put(`productive_areas/${productive_area_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProductiveAreas'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateProductiveArea, updateProductiveAreaRest }
}

// ### DELETE

export const useDestroyProductiveArea = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyProductiveArea, ...destroyProductiveAreaRest } = useMutation({
    mutationFn: async (productive_area_id) => (await api.delete(`productive_areas/${productive_area_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProductiveAreas'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyProductiveArea, destroyProductiveAreaRest }

}