import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetStopLactations = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getStopLactations', property_id, page, limit]

  const { data: { data: { StopLactations = [], pagination: stopLactationsPagination = {} } = [] } = [], ...getStopLactationsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`stop_lactations?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { StopLactations, stopLactationsPagination, getStopLactationsRest }
}


// ### ADD

export const useAddStopLactation = () => {

  const queryClient = useQueryClient()

  const { mutate: addStopLactation, ...addStopLactationRest } = useMutation({
    mutationFn: async ({ data, mother_id }) => (await api.post(`stop_lactations/${mother_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getStopLactations'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addStopLactation, addStopLactationRest }
}

// ### UPDATE

export const useUpdateStopLactation = () => {

  const queryClient = useQueryClient()

  const { mutate: updateStopLactation, ...updateStopLactationRest } = useMutation({
    mutationFn: async ({ data, stop_lactation_id }) => (await api.put(`stop_lactations/${stop_lactation_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getStopLactations'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateStopLactation, updateStopLactationRest }
}

// ### DELETE

export const useDestroyStopLactation = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyStopLactation, ...destroyStopLactationRest } = useMutation({
    mutationFn: async (stop_lactation_id) => (await api.delete(`stop_lactations/${stop_lactation_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getStopLactations'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyStopLactation, destroyStopLactationRest }

}
