import React from 'react'
import { Icon } from '@iconify/react'

import Modal from '../Modal'


function ModalConfirm(props) {  


  return (
    <Modal setOpenModal={props.setOpenModal}>
      <div className='flex flex-col items-center py-6 space-y-8'>

        <div>
          <Icon icon="pajamas:question" className='text-6xl text-primaryBackground' />
        </div>

        <div className='pb-6'>
          <p className='text-3xl text-center font-bold text-primaryBackground'>{props.message}</p>
        </div>

        <div className='flex w-full h-16 items-center justify-center'>
          <button
            className='h-full w-full bg-tertiaryBackground text-white text-xl font-bold border-r border-white'
            onClick={() => props.confirmFunction()}
          >
            Sim
          </button>

          <button
            className='h-full w-full bg-primaryAlert text-white text-xl font-bold'
            onClick={() => props.setOpenModal(false)}
          >
            Não
          </button>
        </div>

      </div>
    </Modal>
  )
}

export default ModalConfirm