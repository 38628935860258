import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'

import { countryList, brazilianStatesAndCities } from '../../utils'

import { useAddProperty } from '../../queries/PropertyQueries'

function PropertyAdd(props) {

  const { addProperty } = useAddProperty()

  const [name, setName] = useState("")
  const [size, setSize] = useState("")
  const [country, setCountry] = useState(countryList[0])
  const [state, setState] = useState([])
  const [stateList, setStateList] = useState([])
  const [city, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  const [disabledState, setDisabledState] = useState(false)
  const [owner, setOwner] = useState("")
  const [phone, setPhone] = useState("")

  useEffect(() => {
    const list = brazilianStatesAndCities.map(x => x.nome)
    setStateList(list)
    setState(list[0])
  }, [])

  useEffect(() => {
    if (country !== "Brasil") {
      setDisabledState(true)
    } else {
      setDisabledState(false)
    }
  }, [country])

  useEffect(() => {

    if (state) {
      const list = brazilianStatesAndCities.find((x) => {
        if (x.nome === state) {
          return x
        }
      })

      if (list?.cidades) {
        setCityList(list.cidades)
        setCity(list.cidades[0])
      } else {
        setCityList([])
        setCity("")
      }
    }

  }, [state])


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    name ? data.name = name : allFieldsFilled = false
    size ? data.size = size : allFieldsFilled = false
    owner ? data.owner = owner : allFieldsFilled = false

    if (country) {
      data.country = country

      if (country === "Brasil") {
        state ? data.state = state : allFieldsFilled = false
        city ? data.city = city : allFieldsFilled = false
      }

    } else {
      allFieldsFilled = false
    }


    if (allFieldsFilled) {

      addProperty(data)

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>
        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome da propriedade*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tamanho em hectares*"
          value={size}
          setValue={(e) => { setSize(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome do proprietário*"
          value={owner}
          setValue={(e) => { setOwner(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Celular"
          value={phone}
          setValue={(e) => { setPhone(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="País*"
          value={country}
          list={countryList}
          setValue={(e) => { setCountry(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Estado*"
          value={state}
          list={stateList}
          disabled={disabledState}
          setValue={(e) => { setState(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Cidade*"
          value={city}
          list={cityList}
          disabled={disabledState}
          setValue={(e) => { setCity(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

    </div>

  )
}

export default PropertyAdd