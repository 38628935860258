import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

import Menu from '../../components/Menu'
import ModalSide from '../../components/ModalSide'
import Input from '../../components/Input'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import InputSelect from '../../components/InputSelect'
import ButtonAdd from '../../components/ButtonAdd'
import Loading from '../../components/Loading'
import ChartBar from '../../components/ChartBar'

import PropertyAdd from '../PropertyAdd'

import { useGetReportReproductiveCalvingPredictions, useGetReportReproductiveCalvingPredictionsByMonth } from '../../queries/ReportReproductiveCalvingPredictionQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { setInputDateFormat, setDateBrazilianFormat } from '../../utils'

function ReportReproductiveCalvingPrediction() {

  const { selectedProperty } = usePropertyStore()

  var date = new Date()
  let locale = navigator.language || navigator.userLanguage

  var firstDay = new Date(DateTime.fromFormat(`${date.getFullYear()}-01-01`, "yyyy-LL-dd", { locale }))
  var lastDay = new Date(date.getFullYear(), 11, 31)

  const [startDate, setStartDate] = useState(setInputDateFormat(firstDay))
  const [endDate, setEndDate] = useState(setInputDateFormat(lastDay))

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  const {
    Matings,
    reportReproductiveCalvingPredictionsPagination,
    getReportReproductiveCalvingPredictionsRest
  } = useGetReportReproductiveCalvingPredictions(
    selectedProperty?.id,
    startDate || setInputDateFormat(new Date(DateTime.fromFormat(`${date.getFullYear()}-01-01`, "yyyy-LL-dd", { locale }))),
    endDate || setInputDateFormat(new Date(date.getFullYear(), 11, 31)),
    page,
    limit
  )

  const {
    MatingsByMonth,
    getReportReproductiveCalvingPredictionsByMonthRest
  } = useGetReportReproductiveCalvingPredictionsByMonth(
    selectedProperty?.id,
    startDate || setInputDateFormat(new Date(DateTime.fromFormat(`${date.getFullYear()}-01-01`, "yyyy-LL-dd", { locale }))),
    endDate || setInputDateFormat(new Date(date.getFullYear(), 11, 31))
  )

  const chartData = {
    labels: MatingsByMonth?.map(x => x.month),
    datasets: [
      {
        label: 'Partos',
        data: MatingsByMonth?.map(x => x.value),
        backgroundColor: 'rgba(40, 91, 75, 0.7)',
      },
    ],
  }

  if (getReportReproductiveCalvingPredictionsByMonthRest.isPending || getReportReproductiveCalvingPredictionsRest.isPending) {
    return <Loading />
  }


  return (
    <Menu title="Previsão de partos por período">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <div className='w-full flex flex-col justify-start space-y-4'>

          <BackgroundData>

            <div className='w-full flex flex-col lg:flex-row lg:space-y-0 gap-y-6 space-y-0 lg:space-x-4 items-center justify-start'>

              <div className='flex gap-x-0rt lg:gap-x-6 gap-y-4 lg:gap-y-0 flex-col lg:flex-row'>
                <div className='w-48'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="De"
                    value={startDate}
                    setValue={(e) => { setStartDate(e) }}
                    className="flex-1"
                  />
                </div>

                <div className='w-48'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="Até"
                    value={endDate}
                    setValue={(e) => { setEndDate(e) }}
                  />
                </div>
              </div>

              {/* <div className='flex items-center space-x-2 lg:space-x-1'>
                <InputSelect
                  type="text"
                  borderColor="primaryBackground"
                  textColor="primaryBackground"
                  placeholder="Propriedade*"
                  value={propertyName}
                  list={propertyListName}
                  setValue={(e) => { setPropertyName(e) }}
                />
                <ButtonAdd onClick={() => setShowModalAddProperty(true)} />
              </div> */}

            </div>
          </BackgroundData>

          <BackgroundData>

            <div className="overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ">
                <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                  <tr>
                    <th scope="col" className="px-6 py-3">Animal</th>
                    <th scope="col" className="px-6 py-3">Lote</th>
                    <th scope="col" className="px-6 py-3">Previsão do Parto</th>
                    <th scope="col" className="px-6 py-3">Dias p/ Parto</th>
                  </tr>
                </thead>

                {Matings?.length > 0 && Matings.map((item, index) => {

                  return (
                    <tbody key={index}>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">
                          {item?.Cow?.name ? `${item?.Cow?.earring} - ${item?.Cow?.name}` : item?.Cow?.earring}
                        </th>
                        <td className="px-6 py-4">
                          {item?.Cow?.Batch?.name}
                        </td>
                        <td className="px-6 py-4">
                          {setDateBrazilianFormat(item?.calving_prediction_date)}
                        </td>
                        <td className="px-6 py-4">
                          {item?.calvingPredictionInDays}
                        </td>
                      </tr>
                    </tbody>
                  )
                })}

              </table>

              <div className='flex items-center justify-center'>
                <ButtonsPagination
                  data={reportReproductiveCalvingPredictionsPagination}
                  page={(page) => { setPage(page) }}
                  limit={(limit) => { setLimit(limit) }}
                />
              </div>


            </div>
          </BackgroundData>

        </div>

        <BackgroundData>

          <div className='relative overflow-x-auto'>

            <ChartBar chartTitle="Previsão de partos por período" data={chartData} />

          </div>

        </BackgroundData>

        {showModalAddProperty &&
          <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
            <PropertyAdd setOpenModal={setShowModalAddProperty} />
          </ModalSide>
        }

      </div>
    </Menu>
  )
}

export default ReportReproductiveCalvingPrediction