import { useQuery, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetRevenueCategories = () => {

  const QUERY_KEY = ['getRevenueCategories']

  const { data: { data: RevenueCategories = [] } = [], ...getRevenueCategoryRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get('revenue_category')),
    placeholderData: keepPreviousData,
  })  

  return { RevenueCategories, getRevenueCategoryRest }
}


