import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetCustomerSuppliers = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getCustomerSuppliers', property_id, page, limit]

  const { data: { data: { CustomerSuppliers = [], pagination: customerSuppliersPagination = {} } = [] } = [], ...getCustomerSuppliersRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`customer_suppliers?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { CustomerSuppliers, customerSuppliersPagination, getCustomerSuppliersRest }
}


// ### ADD

export const useAddCustomerSupplier = () => {

  const queryClient = useQueryClient()

  const { mutate: addCustomerSupplier, ...addCustomerSupplierRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`customer_suppliers/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCustomerSuppliers'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addCustomerSupplier, addCustomerSupplierRest }
}

// ### UPDATE

export const useUpdateCustomerSupplier = () => {

  const queryClient = useQueryClient()

  const { mutate: updateCustomerSupplier, ...updateCustomerSupplierRest } = useMutation({
    mutationFn: async ({ data, customer_supplier_id }) => (await api.put(`customer_suppliers/${customer_supplier_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCustomerSuppliers'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateCustomerSupplier, updateCustomerSupplierRest }
}

// ### DELETE

export const useDestroyCustomerSupplier = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyCustomerSupplier, ...destroyCustomerSupplierRest } = useMutation({
    mutationFn: async (customer_supplier_id) => (await api.delete(`customer_suppliers/${customer_supplier_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCustomerSuppliers'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyCustomerSupplier, destroyCustomerSupplierRest }

}
