import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetBankAccounts = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getBankAccounts', property_id, page, limit]

  const { data: { data: BankAccounts = [] } = [], ...getBankAccountsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`bank_accounts?property_id=${property_id}`)),
    placeholderData: keepPreviousData,
  })  

  return { BankAccounts, getBankAccountsRest }
}


// ### ADD

export const useAddBankAccount = () => {

  const queryClient = useQueryClient()

  const { mutate: addBankAccount, ...addBankAccountRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`bank_accounts/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBankAccounts'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addBankAccount, addBankAccountRest }
}

// ### UPDATE

export const useUpdateBankAccount = () => {

  const queryClient = useQueryClient()

  const { mutate: updateBankAccount, ...updateBankAccountRest } = useMutation({
    mutationFn: async ({ data, bank_account_id }) => (await api.put(`bank_accounts/${bank_account_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBankAccounts'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateBankAccount, updateBankAccountRest }
}

// ### DELETE

export const useDestroyBankAccount = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyBankAccount, ...destroyBankAccountRest } = useMutation({
    mutationFn: async (bank_account_id) => (await api.delete(`bank_accounts/${bank_account_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBankAccounts'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyBankAccount, destroyBankAccountRest }

}
