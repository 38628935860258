import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetBatches = (property_id = 0) => {

  const QUERY_KEY = ['getBatches', property_id]

  const { data: { data: Batches = [] } = [], ...getBatchesRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`batches?property_id=${property_id}`)),
    placeholderData: keepPreviousData,
  })

  return { Batches, getBatchesRest }
}


// ### ADD

export const useAddBatch = () => {

  const queryClient = useQueryClient()

  const { mutate: addBatch, ...addBatchRest } = useMutation({
    mutationFn: async ({ data, property_id }) => (await api.post(`batches/${property_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBatches'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addBatch, addBatchRest }
}

// ### UPDATE

export const useUpdateBatch = () => {

  const queryClient = useQueryClient()

  const { mutate: updateBatch, ...updateBatchRest } = useMutation({
    mutationFn: async ({ data, batch_id }) => (await api.put(`batches/${batch_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBatches'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateBatch, updateBatchRest }
}

// ### DELETE

export const useDestroyBatch = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyBatch, ...destroyBatchRest } = useMutation({
    mutationFn: async (batch_id) => (await api.delete(`batches/${batch_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBatches'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyBatch, destroyBatchRest }

}
