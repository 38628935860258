import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportAnimalByBatch = (property_id = 0) => {

  const QUERY_KEY = ['getReportAnimalByBatch', property_id]  

  const { data: { data: AnimalByBatch = [] } = [], ...getReportAnimalByBatchRest } = useQuery({    
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_animal_by_batch/${property_id}`)),
    placeholderData: keepPreviousData,
  })   
    
  return { AnimalByBatch, getReportAnimalByBatchRest }
}