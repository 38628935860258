import api from './api'

import { useTokenStore } from '../stores/authStore'

const axiosInterceptorsGetToken = () => {
  api.interceptors.request.use(
    (config) => {
      const token = useTokenStore.getState()?.token

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

     // console.log("request config", config)
      return config
    },
    (error) => {
      // console.log("request error", error);
      return Promise.reject(error)
    }
  )
}

export default axiosInterceptorsGetToken