import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'


function ButtonSubmit(props) {

  const [backColorClass, setBackColorClass] = useState(props.backColor && props.backColor != "" ? props.backColor : 'bg-primaryBackground')
  const [textColorClass, setTextColorClass] = useState(props.textColor && props.textColor != "" ? props.textColor : 'text-white')
  const [disabled, setDisabled] = useState(props.disabled ? true : false)

  useEffect(() => {
    setBackColorClass(props.backColor && props.backColor != "" ? props.backColor : 'bg-primaryBackground')
    setTextColorClass(props.textColor && props.textColor != "" ? props.textColor : 'text-white')
  }, [props.backColor, props.textColor])

  useEffect(() => {
    if (props?.disabled === true) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [props.disabled])


  return (
    <button
      className={`flex-1 ${backColorClass} ${textColorClass} text-xl rounded flex items-center justify-center h-12 space-x-2 hover:opacity-80 ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
      onClick={() => props.onClick()}
    >
      {props.icon && <Icon icon={props.icon} className='text-3xl text-white' />}
      {props.loading ? <Icon icon="eos-icons:bubble-loading" className={`text-2xl`} />
        : <p className='text-lg'>{props.title}</p>}
    </button>
  )
}

export default ButtonSubmit