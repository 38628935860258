import React, { useEffect, useState } from 'react'

import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import InputSelect from '../../components/InputSelect'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Input from '../../components/Input'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'
import AnimalAdd from '../AnimalAdd'

import { useGetProperties } from '../../queries/PropertyQueries'
import { useGetReportProductiveAnimalStopLactations } from '../../queries/ReportProductiveAnimalStopLactationQueries'
import { useGetReportRevenueByCategory } from '../../queries/ReportRevenueByCategoryQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { setInputDateFormat, setDateBrazilianFormat, brazilianCurrencyMask, taggleAccordion } from '../../utils'

function ReportRevenueByCategory() {

  // const { properties, getPropertiesRest } = useGetProperties()
  const { selectedProperty } = usePropertyStore()

  var date = new Date()
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const [startDate, setStartDate] = useState(setInputDateFormat(firstDay))
  const [endDate, setEndDate] = useState(setInputDateFormat(lastDay))

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const [propertyListName, setPropertyListName] = useState([])
  const [propertyName, setPropertyName] = useState("")
  const [property, setProperty] = useState({})

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)
  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  // useEffect(() => {

  //   let list = properties.map(x => x.name)
  //   setPropertyListName(["Todas", ...list])
  //   setPropertyName("Todas")

  // }, [])

  // useEffect(() => {
  //   let list = properties.map(x => x.name)
  //   setPropertyListName(["Todas", ...list])
  // }, [properties])

  // useEffect(() => {

  //   if (propertyName && propertyName !== "Todas") {

  //     const propertySelected = properties.find(x => x.name === propertyName)

  //     setProperty(propertySelected)


  //   } else if (propertyName === "Todas") {
  //     setProperty({ id: 0 })
  //   }

  // }, [propertyName])

  const { RevenueByCategory, getReportRevenueByCategoryRest } = useGetReportRevenueByCategory(selectedProperty?.id, setInputDateFormat(startDate || new Date(date.getFullYear(), date.getMonth(), 1)), setInputDateFormat(endDate || new Date(date.getFullYear(), date.getMonth() + 1, 0)))


  if (getReportRevenueByCategoryRest.isPending) {
    return <Loading />
  }


  return (
    <Menu title="Receitas por Categoria">
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-8'>

        <div className='w-full flex flex-col justify-start space-y-6'>

          <BackgroundData>

            <div className='w-full flex flex-col lg:flex-row space-y-8 lg:space-y-0 space-x-4 items-start justify-start'>

              <div className='w-[50%] flex space-x-4'>

                <div className='flex-1'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="De"
                    value={startDate}
                    setValue={(e) => { setStartDate(e) }}
                    className="flex-1"
                  />
                </div>

                <div className='flex-1'>
                  <Input
                    type="date"
                    borderColor="primaryBackground"
                    textColor="primaryBackground"
                    placeholder="Até"
                    value={endDate}
                    setValue={(e) => { setEndDate(e) }}
                  />
                </div>

                {/* <div className='flex items-center space-x-2 lg:space-x-1'>
                <InputSelect
                  type="text"
                  borderColor="primaryBackground"
                  textColor="primaryBackground"
                  placeholder="Propriedade*"
                  value={propertyName}
                  list={propertyListName}
                  setValue={(e) => { setPropertyName(e) }}
                />
                <ButtonAdd onClick={() => setShowModalAddProperty(true)} />
              </div> */}

              </div>
            </div>
          </BackgroundData>

          <BackgroundData>

            <div className="relative overflow-x-auto space-y-4">
              <table className="w-full text-sm text-left rtl:text-righ border-separate border-spacing-y-1">               

                {Array.isArray(RevenueByCategory) && RevenueByCategory.map((item, index) => {

                  return (
                    <tbody key={item.name}>
                      <tr
                        className="bg-gray-100 border-b cursor-pointer uppercase hover:bg-gray-200 focus:bg-gray-200 focus:border focus:border-gray-200"
                        tabIndex="0"
                        onClick={() => { taggleAccordion(`accordion-collapse-body-${index}`) }}
                      >
                        <td className="px-6 py-4 font-semibold">{item?.name}</td>
                        <td className="px-6 py-4 font-semibold">{`${brazilianCurrencyMask(item?.percentage)}%`}</td>
                        <td className="px-6 py-4 font-semibold text-primaryAlert">{`R$ ${brazilianCurrencyMask(item?.total)}`}</td>

                        <th className='pr-4'>
                          <div className='flex items-center justify-end'>
                            <div className='hidden' id={`accordion-collapse-body-${index}`}>
                              <Icon icon="iconamoon:arrow-up-2" className='text-xl' />
                            </div>
                            <dir className='table-row' id={`accordion-collapse-body-${index}`}>
                              <Icon icon="iconamoon:arrow-down-2" className='text-xl' />
                            </dir>
                          </div>
                        </th>

                      </tr>

                      <tr className="hidden bg-white border border-separate border-gray-200" tabIndex="0" id={`accordion-collapse-body-${index}`} >
                        <td className="px-10 py-4" colSpan={4}>

                          <table className="w-full text-sm text-left rtl:text-righ" colSpan={4}>
                            <thead className="text-xs uppercase border-b border-gray-400 text-primaryBackground" colSpan={4}>
                              <tr>
                                <th scope="col" className="px-6 py-3">Descrição</th>
                                <th scope="col" className="px-6 py-3">Centro de Custo</th>
                                <th scope="col" className="px-6 py-3">Cliente/Fornecedor</th>                                
                                <th scope="col" className="px-6 py-3">Forma de pagamento</th>
                                <th scope="col" className="px-6 py-3">Valor</th>

                                <th scope="col" className="px-6 py-3"></th>
                              </tr>
                            </thead>
                            {
                              item.Revenues?.length > 0 && item?.Revenues.map((subitem, subIndex) => {

                                return (
                                  <tbody key={subitem?.id}>
                                    <tr className="border-b" id={`accordion-collapse-body-${index}`} >
                                      <td className="px-6 py-4">{subitem?.description}</td>
                                      <td className="px-6 py-4">{subitem?.CostCenter?.name}</td>
                                      <td className="px-6 py-4">{subitem?.CustomerSupplier?.name}</td>
                                      <td className="px-6 py-4">{subitem?.form_of_payment}</td>
                                      <td className="px-6 py-4">{`R$ ${brazilianCurrencyMask(subitem?.value)}`}</td>
                                    </tr>
                                  </tbody>
                                )
                              })
                            }

                          </table>
                        </td>
                      </tr>

                    </tbody>
                  )
                })}

              </table >

            </div>
          </BackgroundData>

        </div>

        {showModalAddProperty &&
          <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
            <PropertyAdd setOpenModal={setShowModalAddProperty} />
          </ModalSide>
        }

        {showModalAddAnimal &&
          <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
            <AnimalAdd setOpenModal={setShowModalAddAnimal} />
          </ModalSide>
        }

      </div>
    </Menu>
  )
}

export default ReportRevenueByCategory