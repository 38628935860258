import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useUpdateCustomerSupplier } from '../../queries/CustomerSupplierQueries'

import { phoneMask, cepMask, cpfAndCnpjMask, countryList, brazilianStatesAndCities } from '../../utils'

function CustomerSupplierUpdate(props) {

  const selectedCustomerSupplier = props?.data || {}
 
  const { updateCustomerSupplier, updateCustomerSupplierRest } = useUpdateCustomerSupplier() 

  const [typeList, setTypeList] = useState([])
  const [type, setType] = useState("")

  const [name, setName] = useState(selectedCustomerSupplier?.name || "")
  const [phone, setPhone] = useState(selectedCustomerSupplier?.phone || "")
  const [email, setEmail] = useState(selectedCustomerSupplier?.email || "")
  const [cnpj, setCnpj] = useState(selectedCustomerSupplier?.cnpj || "")
  const [street, setStreet] = useState(selectedCustomerSupplier?.street || "")
  const [number, setNumber] = useState(selectedCustomerSupplier?.number || "")
  const [district, setDistrict] = useState(selectedCustomerSupplier?.district || "")
  const [country, setCountry] = useState(selectedCustomerSupplier?.country || "")
  const [cep, setCep] = useState(selectedCustomerSupplier?.cep || "")

  const [state, setState] = useState("")
  const [stateList, setStateList] = useState([])

  const [city, setCity] = useState("")
  const [cityList, setCityList] = useState([])

  const [disabledState, setDisabledState] = useState(false)


  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  useEffect(() => {    

    const types = [
      "Cliente",
      "Fornecedor"
    ]

    setTypeList(types)

    setType(types[0])

    const listState = brazilianStatesAndCities.map(x => x.nome)
    setStateList(listState)
    setState(selectedCustomerSupplier?.state || listState[0])

  }, []) 

  useEffect(() => {

    if (state) {
      const list = brazilianStatesAndCities.find((x) => {
        if (x.nome === state) {
          return x
        }
      })

      if (list?.cidades) {
        setCityList(list.cidades)
        setCity(state != selectedCustomerSupplier?.state ? list.cidades[0] : selectedCustomerSupplier?.city)
      } else {
        setCityList([])
        setCity("")
      }
    }

  }, [state])

  useEffect(() => {
    if (country !== "Brasil") {
      setDisabledState(true)
    } else {
      setDisabledState(false)
    }
  }, [country])  

  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (name && name !== selectedCustomerSupplier?.name) {
      data.name = name
      dataUpdated = true
    }

    if (phone && phone.toString().replace(/\D/g, "") !== selectedCustomerSupplier?.phone) {
      data.phone = phone.toString().replace(/\D/g, "")
      dataUpdated = true
    }

    if (email && email !== selectedCustomerSupplier?.email) {
      data.email = email
      dataUpdated = true
    }

    if (cnpj && cnpj.toString().replace(/\D/g, "") !== selectedCustomerSupplier?.cnpj) {
      data.cnpj = cnpj.toString().replace(/\D/g, "")
      dataUpdated = true
    }

    if (type && type !== selectedCustomerSupplier?.type) {
      data.type = type
      dataUpdated = true
    }

    if (street && street !== selectedCustomerSupplier?.street) {
      data.street = street
      dataUpdated = true
    }

    if (number && parseInt(number) !== selectedCustomerSupplier?.number) {
      data.number = parseInt(number)
      dataUpdated = true
    }

    if (district && district !== selectedCustomerSupplier?.district) {
      data.district = district
      dataUpdated = true
    }


    if (cep && cep.toString().replace(/\D/g, "") !== selectedCustomerSupplier?.cep) {
      data.cep = cep.toString().replace(/\D/g, "")
      dataUpdated = true
    }

    if (country && country != selectedCustomerSupplier?.country) {
      data.country = country
      dataUpdated = true
    }

    if (country === "Brasil") {

      if (state != selectedCustomerSupplier?.state) {
        data.state = state
        dataUpdated = true
      }

      if (city != selectedCustomerSupplier?.city) {
        data.city = city
        dataUpdated = true
      }
    }

    if (dataUpdated) {

      updateCustomerSupplier({ data, customer_supplier_id: selectedCustomerSupplier.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (updateCustomerSupplierRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>      

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome*"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Categoria*"
          value={type}
          list={typeList}
          setValue={(e) => { setType(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Celular"
          value={phone}
          setValue={(e) => { setPhone(phoneMask(e)) }}
        />


        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="E-mail"
          value={email}
          setValue={(e) => { setEmail(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="CPF/CNPJ"
          value={cnpj}
          setValue={(e) => { setCnpj(cpfAndCnpjMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Endereço"
          value={street}
          setValue={(e) => { setStreet(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Número"
          value={number}
          setValue={(e) => { setNumber(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Bairro"
          value={district}
          setValue={(e) => { setDistrict(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Cep"
          value={cep}
          setValue={(e) => { setCep(cepMask(e)) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="País"
          value={country}
          list={countryList}
          setValue={(e) => { setCountry(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Estado"
          value={state}
          list={stateList}
          disabled={disabledState}
          setValue={(e) => { setState(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Cidade"
          value={city}
          list={cityList}
          disabled={disabledState}
          setValue={(e) => { setCity(e) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default CustomerSupplierUpdate