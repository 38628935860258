import React from 'react'
import { Icon } from '@iconify/react'

function ButtonAdd(props) {

  return (

    <button
      className='w-8 h-8 bg-tertiaryBackground rounded-full flex items-center justify-center shadow-lg hover:opacity-80'
      onClick={() => props.onClick()}
    >
      <Icon icon="ph:plus-bold" className='text-white text-lg font-bold' />
    </button>
  )
}

export default ButtonAdd