import React, { useEffect } from "react"
import { Navigate } from 'react-router-dom'

import { useTokenStore } from '../stores/authStore'

function PrivateRoutes({ children }) {

  const { token } = useTokenStore()

  return token && Object.keys(token).length > 0 ? children : <Navigate to="/login" />
}

export default PrivateRoutes