import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import api from '../config/api'

// #### GET

export const useGetReportMilkSales = (property_id = 0, start_date, end_date, page = 1, limit = 10) => {

  const QUERY_KEY = ['getReportMilkSales', property_id, start_date, end_date, page, limit]  

  const { data: { data: { MilkSales = [], pagination: reportMilkSalesPagination = {} } = [] } = [], ...getReportMilkSalesRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`report_milk_sales/${property_id}?start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })    

  return { MilkSales, reportMilkSalesPagination, getReportMilkSalesRest }
}