import React, { useState, useEffect } from 'react'

import Input from '../Input'

function ButtonsPagination(props) {

  const [visibleNumbers, setVisibleNumbers] = useState([])
  const [pageSelected, setPageSelected] = useState(1)
  const [limit, setLimit] = useState(10)

  //console.log(props.data)

  useEffect(() => {
    updateVisibleNumbers()
  }, [])

  useEffect(() => {
    updateVisibleNumbers()
  }, [props?.data])

  useEffect(() => {
    props.page(pageSelected)
  }, [pageSelected])

  useEffect(() => {
    props.limit(limit)
  }, [limit])

  const updateVisibleNumbers = () => {

    if (pageSelected > 0 && pageSelected <= 5) {

      if (props?.data?.lastPage >= 5) {
        setVisibleNumbers(Array.from({ length: 5 }, (_, i) => i + 1))
      } else {
        setVisibleNumbers(Array.from({ length: Number(props?.data?.lastPage) }, (_, i) => i + 1))
      }

    } else if (pageSelected > 5) {

      const startNumber = pageSelected - 4
      const arrNumbers = [startNumber, startNumber + 1, startNumber + 2, startNumber + 3, startNumber + 4]
      setVisibleNumbers(arrNumbers)

    }

  }

  const prevPage = () => {

    if (pageSelected - 1 >= visibleNumbers[0]) {

      setPageSelected(prev => prev - 1)

    } else if (pageSelected - 1 >= 1) {

      const startNumber = (pageSelected - 1)
      let arrNumbers

      if (props?.data?.lastPage >= 5) {
        arrNumbers = [startNumber, startNumber + 1, startNumber + 2, startNumber + 3, startNumber + 4]
      } else {
        arrNumbers = Array(props?.data?.lastPage).map(x => x)
      }


      setVisibleNumbers(arrNumbers)
      setPageSelected(prev => prev - 1)

    }

  }

  const nextPage = () => {

    if (pageSelected + 1 <= props?.data?.lastPage) {

      if (pageSelected + 1 <= visibleNumbers.length) {

        setPageSelected(prev => prev + 1)

      } else {

        const startNumber = (pageSelected + 1) - 4
        const arrNumbers = [startNumber, startNumber + 1, startNumber + 2, startNumber + 3, startNumber + 4]
        setVisibleNumbers(arrNumbers)
        setPageSelected(prev => prev + 1)

      }

    }

  }

  return (
    <div className='flex w-full lg:flex-row flex-col gap-y-6 lg:gap-y-0 items-center justify-between'>

      <div className='flex flex-1 h-10 items-center justify-start'>
        <p className='font-bold text-primaryBackground'>{`Total de registros: ${props?.data?.total_records}`}</p>
      </div>

      <nav aria-label="Page navigation example" className='w-full flex flex-1 items-center justify-center'>
        <ul className="inline-flex text-base">
          <li>
            <button onClick={() => { prevPage() }} className="flex items-center justify-center px-2 h-10 ms-0 leading-tight text-primaryBackground bg-white border border-e-0 border-primaryBackgroundShadow rounded-s-lg lg:hover:bg-tertiaryBackgroundShadow lg:hover:font-bold">Anterior</button>
          </li>

          {
            visibleNumbers.length > 0 && visibleNumbers.map(item => {

              const selectedTextColor = item == pageSelected ? "text-white font-bold" : "text-primaryBackground"
              const selectedBgColor = item == pageSelected ? "bg-tertiaryBackground" : "bg-white"

              return (
                <li key={item}>
                  <button
                    onClick={() => { setPageSelected(Number(item)) }}
                    className={`flex items-center justify-center h-10 leading-tight px-3 ${selectedTextColor} ${selectedBgColor} border border-primaryBackgroundShadow lg:hover:bg-tertiaryBackgroundShadow lg:hover:font-bold`}>{item}</button>
                </li>
              )
            })
          }

          <li>
            <button onClick={() => { nextPage() }} className="flex items-center justify-center px-2 h-10 leading-tight text-primaryBackground bg-white border border-primaryBackgroundShadow rounded-e-lg lg:hover:bg-tertiaryBackgroundShadow lg:hover:font-bold">Próxima</button>
          </li>
        </ul>
      </nav>

      <div className='flex flex-1 items-end justify-end'>

        <div className='w-40'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Quantidade por página"
            value={limit}
            setValue={(e) => { setLimit(e) }}
          />
        </div>

      </div>

    </div>

  )
}

export default ButtonsPagination