import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import ProductiveAreaAdd from '../ProductiveAreaAdd'
import BatchAdd from '../BatchAdd'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetAnimals, useUpdateAnimal } from '../../queries/AnimalQueries'
import { useGetProductiveAreas } from '../../queries/ProductiveAreaQueries'
import { useGetBatches } from '../../queries/BatchQueries'

import {
  sexList,
  breedList,
  originList,
  bloodDegreesList,
  brazilianCurrencyMask,
  brazilianCurrencyToFloat,
  toUSACurrency,
  setInputDateFormat
} from '../../utils'


function AnimalUpdate(props) {

  const { updateAnimal, updateAnimalRest } = useUpdateAnimal()
  const { selectedProperty } = usePropertyStore()

  const [productiveAreaListName, setProductiveAreaListName] = useState([])
  const [productiveAreaName, setProductiveAreaName] = useState("")
  const [productiveArea, setProductiveArea] = useState({})

  const [batchListName, setBatchListName] = useState([])
  const [batchName, setBatchName] = useState("Escolher Lote")
  const [batch, setBatch] = useState({})

  const [name, setName] = useState(props?.data?.name || "")
  const [earring, setEarring] = useState(props?.data?.earring || "")
  const [register, setRegister] = useState(props?.data?.register || "")
  const [sex, setSex] = useState(props?.data?.sex || sexList[0])
  const [breed, setBreed] = useState(props?.data?.breed || breedList[0])
  const [origin, setOrigin] = useState(props?.data?.origin || originList[0])
  const [bloodDegrees, setBloodDegreesList] = useState(props?.data?.blood_degrees || bloodDegreesList[0])
  const [birthDate, setBirthDate] = useState("")
  const [birthWeight, setBirthWeight] = useState(props?.data?.birth_weight ? brazilianCurrencyMask(props?.data?.birth_weight) : "")
  const [purchasePrice, setPurchasePrice] = useState(props?.data?.purchase_price || "")
  const [lastCalvingDate, setLastCalvingDate] = useState("")
  const [weaningDate, setWeaningDate] = useState("")

  const [father, setFather] = useState("")
  const [fatherListName, setFatherListName] = useState([])
  const [fatherName, setFatherName] = useState("Animal Pai")

  const [mother, setMother] = useState("")
  const [motherListName, setMotherListName] = useState([])
  const [motherName, setMotherName] = useState("Animal Mãe")

  const [showModalAddProductiveArea, setShowModalAddProductiveArea] = useState(false)
  const [showModalAddBatch, setShowModalAddBatch] = useState(false)

  let property_id

  if (props?.data?.ProductiveArea?.Property?.id) {
    property_id = props?.data?.ProductiveArea?.Property?.id
  } else if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Animals, getAnimalsRest } = useGetAnimals(property_id, 1, 1000000)
  const { productiveAreas, getProductiveAreasRest } = useGetProductiveAreas(property_id)
  const { Batches, getBatchesRest } = useGetBatches(property_id)

  useEffect(() => {

    console.log(props.data?.Bull?.name)

    if (productiveAreas.length > 0) {
      const list = productiveAreas.map(x => x?.name ? x?.name : `${x.id} - ${x.use} - ${x.size} ha`)
      setProductiveAreaListName(list)

      const productiveAreaItem = productiveAreas.find(x => x.id == props?.data?.productive_area_id)
      setProductiveAreaName(productiveAreaItem?.name ? productiveAreaItem?.name : `${productiveAreaItem?.id} - ${productiveAreaItem?.use} - ${productiveAreaItem?.size} ha` || list[0])
    }

    if (Batches.length > 0) {

      const batchesListData = Batches.map(x => x.name)
      setBatchListName(batchesListData)

      if (props?.data?.batch_id) {
        const batchesListDataItem = Batches.find(x => x.id == props.data?.batch_id)

        setBatchName(batchesListDataItem?.name || "Escolher Lote")

      }

    }

    if (Animals.length > 0) {

      const fatherListData = Animals.map((x) => {
        if (x?.sex === "Macho") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)
      setFatherListName(fatherListData)

     

      const motherListData = Animals.map((x) => {
        if (x?.sex === "Fêmea") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)

      setMotherListName(motherListData)     

    }

    if (props?.data?.birth_date) {
      const date = new Date(props?.data?.birth_date)
      setBirthDate(setInputDateFormat(date))
    }

    if (props?.data?.weaning_date) {
      const date = new Date(props?.data?.weaning_date)
      setWeaningDate(setInputDateFormat(date))
    }

    if (props?.data?.last_calving_date) {
      const date = new Date(props?.data?.last_calving_date)
      setLastCalvingDate(setInputDateFormat(date))
    }


  }, [])

  useEffect(() => {

    const list = productiveAreas.map(x => x?.name ? x?.name : `${x.id} - ${x.use} - ${x.size} ha`)
    setProductiveAreaListName(list)
    //setProductiveAreaName(list[0])

  }, [productiveAreas])

  useEffect(() => {

    const batchesListData = Batches.map(x => x.name)
    setBatchListName(batchesListData)
    //setBatchName(batchesListData[0])

  }, [Batches])

  useEffect(() => {

  //  console.log(props.data?.Bull?.name)

    if (Animals.length > 0) {

      const fatherListData = Animals.map((x) => {
        if (x?.sex === "Macho") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)
      setFatherListName(fatherListData)

      if (props?.data?.father_id) {
        setFatherName(props.data?.Bull?.name ? `${props.data?.Bull?.earring} - ${props.data?.Bull?.name}` : props.data?.Bull?.earring)
      }

      const motherListData = Animals.map((x) => {
        if (x?.sex === "Fêmea") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)

      setMotherListName(motherListData)

      if (props?.data?.mother_id) {
        setMotherName(props.data?.Cow?.name ? `${props.data?.Cow?.earring} - ${props.data?.Cow?.name}` : props.data?.Cow?.earring)
      }

    }

  }, [Animals])

  useEffect(() => {

    if (productiveAreaName) {

      const productiveSelected = productiveAreas.find(x => {
        if (productiveAreaName.indexOf(x.use) > 0 || (x?.name && x?.name === productiveAreaName)) {
          return x
        }
      })

      setProductiveArea(productiveSelected)

      //console.log(productiveSelected)

    }

  }, [productiveAreaName])

  useEffect(() => {

    if (batchName && batchName !== "Escolher Lote") {

      const batchSelected = Batches.find(x => x.name === batchName)

      setBatch(batchSelected)

    }

  }, [batchName])

  useEffect(() => {

    if (fatherName && fatherName !== "Animal Pai") {

      const fatherSelected = Animals.find(x => {

        // if (fatherName.indexOf(x.name) > 0) {
        if (fatherName.indexOf(x.name) > 0 || fatherName && fatherName === x?.earring) {
          return x
        }
      })

      setFather(fatherSelected)

    }

  }, [fatherName])

  useEffect(() => {

    if (motherName && motherName !== "Animal Mãe") {

      const motherSelected = Animals.find(x => {

        if (motherName.indexOf(x.name) > 0 || motherName && motherName === x?.earring) {
          return x
        }
      })

      setMother(motherSelected)

      //   console.log(motherSelected)

    }


  }, [motherName])

  const changeBirthWeight = (value) => {
    const newWeight = brazilianCurrencyMask(value)

    setBirthWeight(newWeight)
  }

  const changePurchasePrice = (value) => {
    const newPrice = brazilianCurrencyMask(value)

    setPurchasePrice(newPrice)
  }


  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (name && name !== props?.data?.name) {
      data.name = name
      dataUpdated = true
    }

    if (earring && earring !== props?.data?.earring) {
      data.earring = earring
      dataUpdated = true
    }

    if (batch?.id && batch.id !== props?.data?.batch_id) {
      data.batch_id = batch?.id
      dataUpdated = true
    }

    if (productiveArea?.id && productiveArea?.id !== props?.data?.productive_area_id) {
      data.productive_area_id = productiveArea?.id
      dataUpdated = true
    }

    if (register && register !== props?.data?.register) {
      data.register = register
      dataUpdated = true
    }

    if (sex && sex !== props?.data?.sex) {
      data.sex = sex
      dataUpdated = true
    }

    if (breed && breed !== props?.data?.breed) {
      data.breed = breed
      dataUpdated = true
    }

    if (origin && origin !== props?.data?.origin) {
      data.origin = origin
      dataUpdated = true
    }

    if (bloodDegrees && bloodDegrees !== props?.data?.blood_degrees) {
      data.blood_degrees = bloodDegrees
      dataUpdated = true
    }

    if (birthDate && birthDate !== props?.data?.birth_date) {
      const birthTextDate = birthDate?.replace(/-/g, "/")
      data.birth_date = birthTextDate
      dataUpdated = true
    }

    if (birthWeight && brazilianCurrencyToFloat(birthWeight) !== props?.data?.birth_weight) {
      data.birth_weight = brazilianCurrencyToFloat(birthWeight)
      dataUpdated = true
    }

    if (purchasePrice && Math.trunc(toUSACurrency(purchasePrice) * 100) !== parseInt(props?.data?.purchase_price)) {
      data.purchase_price = Math.trunc(toUSACurrency(purchasePrice) * 100)
      dataUpdated = true
    }

    if (lastCalvingDate && lastCalvingDate !== props?.data?.last_calving_date) {
      const lastingCalvingTextDate = lastCalvingDate?.replace(/-/g, "/")
      data.last_calving_date = lastingCalvingTextDate
      dataUpdated = true
    }

    if (mother?.id && mother?.id !== props?.data?.mother_id) {
      data.mother_id = mother.id
      dataUpdated = true
    }

    if (father?.id && father?.id !== props?.data?.father_id) {
      data.father_id = father?.id
      dataUpdated = true
    }


    if (dataUpdated) {

      console.log(data)

      updateAnimal({ data, animal_id: props?.data?.id })
      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (updateAnimalRest.isPending || getAnimalsRest.isPending || getProductiveAreasRest.isPending || getBatchesRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Área atual"
            value={productiveAreaName}
            list={productiveAreaListName}
            setValue={(e) => { setProductiveAreaName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddProductiveArea(true)} />
        </div>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Nome"
          value={name}
          setValue={(e) => { setName(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Brinco*"
          value={earring}
          setValue={(e) => { setEarring(e) }}
        />

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Lote"
            value={batchName}
            list={batchListName}
            setValue={(e) => { setBatchName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddBatch(true)} />
        </div>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Registro"
          value={register}
          setValue={(e) => { setRegister(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data de nascimento"
          value={birthDate}
          setValue={(e) => { setBirthDate(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Peso ao nascer"
            value={birthWeight}
            setValue={(e) => { changeBirthWeight(e) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>KG</p>
          </div>
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Sexo"
          value={sex}
          list={sexList}
          setValue={(e) => { setSex(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Raça"
          value={breed}
          list={breedList}
          setValue={(e) => { setBreed(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Origem"
          value={origin}
          list={originList}
          setValue={(e) => { setOrigin(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Preço de compra"
            value={purchasePrice}
            setValue={(e) => { changePurchasePrice(e) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Graus de sangue"
          value={bloodDegrees}
          list={bloodDegreesList}
          setValue={(e) => { setBloodDegreesList(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data do último parto"
          value={lastCalvingDate}
          setValue={(e) => { setLastCalvingDate(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data do desmame"
          value={weaningDate}
          setValue={(e) => { setWeaningDate(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Animal Mãe"
          value={motherName}
          list={motherListName}
          setValue={(e) => { setMotherName(e) }}
        />


        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Animal Pai"
          value={fatherName}
          list={fatherListName}
          setValue={(e) => { setFatherName(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProductiveArea &&
        <ModalSide setOpenModal={setShowModalAddProductiveArea} title="Nova Área Produtiva" >
          <ProductiveAreaAdd setOpenModal={setShowModalAddProductiveArea} />
        </ModalSide>
      }

      {showModalAddBatch &&
        <ModalSide setOpenModal={setShowModalAddBatch} title="Novo Lote" >
          <BatchAdd setOpenModal={setShowModalAddBatch} />
        </ModalSide>
      }

    </div >

  )
}

export default AnimalUpdate