import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Logo from '../../assets/logo.png'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import Loading from '../../components/Loading'

import { useAddUser } from '../../queries/UserQueries'
import { useGetUserTypes } from '../../queries/UserTypeQueries'
import { useLogin } from '../../queries/AuthQueries'

import { useUserStore, useTokenStore } from '../../stores/authStore'

import { phoneMask, cpfMask } from '../../utils'

function UserSignUp() {

  const navigate = useNavigate()

  const { UserTypes, getUserTypesRest } = useGetUserTypes()
  const { addUser, addUserRest } = useAddUser()
  const { login, loginRest } = useLogin()

  const { setToken } = useTokenStore()
  const { setUser } = useUserStore()

  const [userTypeListName, setUserTypeListName] = useState([])
  const [userTypeName, setUserTypeName] = useState("")
  const [userType, setUserType] = useState([])

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  useEffect(() => {
    if (UserTypes.length > 0) {
      const list = UserTypes.map(x => x.name)
      setUserTypeListName(list)
      setUserTypeName(list[0])
    }
  }, [UserTypes])

  useEffect(() => {

    if (userTypeName) {

      const userTypeSelected = UserTypes.find(x => x.name === userTypeName)

      setUserType(userTypeSelected)

    }

  }, [userTypeName])

  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (!userType?.id) {
      allFieldsFilled = false
    }

    if (name) {
      data.name = name
    } else {
      allFieldsFilled = false
    }

    if (email) {
      data.email = email
    } else {
      allFieldsFilled = false
    }

    if (phone) {
      data.phone = phone
    } else {
      allFieldsFilled = false
    }

    if (password !== confirmPassword) {
      alert("As senhas devem ser iguais!")
      return
    }

    if (password) {
      data.password = password
    } else {
      allFieldsFilled = false
    }

    if (confirmPassword) {
      data.confirm_password = confirmPassword
    } else {
      allFieldsFilled = false
    }

    if (allFieldsFilled) {

      await addUser({ data, user_type_id: userType?.id }).then(async () => {
        login({ email, password })
      }).catch((error) => {
        alert(error.message)
      })


    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  // if (addUserRest?.isSuccess) {
  //   // console.log(addUserRest?.isSuccess)
  //  // login({ email, password })
  //   return
  // }

  // if (loginRest?.isSuccess) {
  //   // setToken(responseLogin?.data?.token)
  //   // setUser(responseLogin?.data?.user)
  //   navigate("/")
  // }

  if (addUserRest?.isPending || getUserTypesRest?.isPending || loginRest?.isPending) {
    return <Loading />
  }

  return (
    <div className='lg:p-4 p-2 flex flex-col lg:flex-row items-center justify-center w-full min-h-screen bg-gradient-to-r from-tertiaryBackground to-primaryBackground gap-y-8'>

      <div className='flex lg:flex-1 flex-col items-center justify-start lg:gap-y-4 gap-y-2'>
        <img src={Logo} alt="" className='lg:w-[60%] w-[40%]' />
        <p className='text-primaryBackground font-black lg:text-8xl text-4xl'>GepAgro</p>
      </div>

      <div className='flex flex-1 w-[95%] items-start justify-start'>

        <div className='flex flex-col w-full lg:w-[55%] items-center justify-center bg-secondaryBackground isolate aspect-video rounded-xl shadow-lg ring-1 ring-black/5 p-6 gap-y-6'>

          <p className='text-white uppercase font-black text-2xl'>Meu Cadastro...</p>

          <div className='w-full flex flex-col items-center justify-center gap-y-2'>

            <InputSelect
              type="text"
              backColor="secondaryBackground"
              placeholder="Tipo*"
              value={userTypeName}
              list={userTypeListName}
              setValue={(e) => { setUserTypeName(e) }}
            />

            <Input
              type="text"
              textColor="white"
              backColor="secondaryBackground"
              placeholder="Nome*"
              value={name}
              setValue={(e) => { setName(e) }}
            />

            <Input
              type="text"
              backColor="secondaryBackground"
              placeholder="E-mail*"
              value={email}
              setValue={(e) => { setEmail(e) }}
            />

            {/* <Input
              type="text"
              backColor="secondaryBackground"
              placeholder="Confirmar e-mail*"
              value={confirmEmail}
              setValue={(e) => { setConfirmEmail(e) }}
            /> */}

            <Input
              type="text"
              backColor="secondaryBackground"
              placeholder="Phone*"
              value={phone}
              setValue={(e) => { setPhone(phoneMask(e)) }}
            />

            {/* <Input
              type="text"
              backColor="secondaryBackground"
              placeholder="CPF"
              value={cpf}
              setValue={(e) => { setCpf(cpfMask(e)) }}
            /> */}

            <Input
              type="password"
              backColor="secondaryBackground"
              placeholder="Senha*"
              value={password}
              setValue={(e) => { setPassword(e) }}
            />

            <Input
              type="password"
              backColor="secondaryBackground"
              placeholder="Confirme a senha*"
              value={confirmPassword}
              setValue={(e) => { setConfirmPassword(e) }}
            />

            <div className='flex justify-between w-full h-12 mt-2'>
              <ButtonSubmit title="ENVIAR" backColor="bg-primaryBackground" textColor="text-white" onClick={() => submit()} loading={""} />
            </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default UserSignUp