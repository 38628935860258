import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import InputBoolean from '../../components/InputBoolean'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'
import CustomerSupplierAdd from '../CustomerSupplierAdd'
import BankAccountAdd from '../BankAccountAdd'

import { usePropertyStore } from '../../stores/propertyStore'

import { useAddExpense } from '../../queries/ExpenseQueries'
import { useGetBankAccounts } from '../../queries/BankAccountQueries'
import { useGetCustomerSuppliers } from '../../queries/CustomerSupplierQueries'
import { useGetCostCenters } from '../../queries/CostCenterQueries'
import { useGetRevenueCategories } from '../../queries/RevenueCategoryQueries'

import { brazilianCurrencyMask, toUSACurrency, setDatePostFormat } from '../../utils'

function ExpenseAdd(props) {

  const { selectedProperty } = usePropertyStore()

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { addExpense, addExpenseRest } = useAddExpense()
  const { BankAccounts, getBankAccountsRest } = useGetBankAccounts(property_id)
  const { CustomerSuppliers, getCustomerSuppliersRest } = useGetCustomerSuppliers(property_id, 1, 1000000)
  const { CostCenters, getCostCenterRest } = useGetCostCenters()
  const { RevenueCategories, getRevenueCategoryRest } = useGetRevenueCategories()


  const [costCenter, setCostCenter] = useState({})
  const [costCenterName, setCostCenterName] = useState("")
  const [costCenterListName, setCostCenterListName] = useState([])

  const [category, setCategory] = useState({})
  const [categoryName, setCategoryName] = useState("")
  const [categoryListName, setCategoryListName] = useState([])

  const [customerSupplier, setCustomerSupplier] = useState({})
  const [customerSupplierName, setCustomerSupplierName] = useState("")
  const [customerSupplierListName, setCustomerSupplierListName] = useState([])

  const [bankAccount, setBankAccount] = useState({})
  const [bankAccountName, setBankAccountName] = useState("")
  const [bankAccountListName, setBankAccountListName] = useState([])

  const [description, setDescription] = useState("")
  const [numDoc, setNumDoc] = useState("")
  const [dateIssue, setDateIssue] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [value, setValue] = useState("")
  const [discount, setDiscount] = useState("")
  const [interest, setInterest] = useState("")
  const [total, setTotal] = useState("")
  const [observation, setObservation] = useState("")
  const [paid, setPaid] = useState(false)
  const [paymentDate, setPaymentDate] = useState("")
  const [numOfInstallments, setNumOfInstallments] = useState(1)

  const [formOfPayment, setFormOfPayment] = useState("")
  const [formOfPaymentList, setFormOfPaymentList] = useState("")

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)
  const [showModalAddCustomerSupllier, setShowModalAddCustomerSupllier] = useState(false)
  const [showModalAddBankAccount, setShowModalAddBankAccount] = useState(false)

  useEffect(() => {

    const formPayments = [
      "Dinheiro",
      "Cartão de Crédito",
      "Cartão de Débito",
      "Cheque",
      "Depósito",
      "Boleto",
      "Transferência",
      "Débito em Conta",
      "Débito em Folha",
      "Débito Automático",
      "Pix",
      "Crédito na Loja"
    ]

    setFormOfPaymentList(formPayments)
    setFormOfPayment(formPayments[0])

  }, [])

  useEffect(() => {
    if (CostCenters.length > 0) {
      const listCosts = CostCenters.map(x => x.name)
      setCostCenterListName(listCosts)
    }
  }, [CostCenters])

  useEffect(() => {
    if (CustomerSuppliers.length > 0) {
      const listCustomerSupplier = CustomerSuppliers.map(x => x.name)
      setCustomerSupplierListName(listCustomerSupplier)
    }
  }, [CustomerSuppliers])

  useEffect(() => {
    if (BankAccounts.length > 0) {
      const listBankAccount = BankAccounts.map(x => x.description)
      setBankAccountListName(listBankAccount)
    }
  }, [BankAccounts])

  useEffect(() => {
    if (RevenueCategories.length > 0) {
      const listCategory = RevenueCategories.map(x => x.name)
      setCategoryListName(listCategory)
    }
  }, [RevenueCategories])


  useEffect(() => {

    if (costCenterName) {

      const costCenterSelected = CostCenters.find(x => x.name === costCenterName)

      setCostCenter(costCenterSelected)

    }

  }, [costCenterName])

  useEffect(() => {

    if (categoryName) {

      const categorySelected = RevenueCategories.find(x => x.name === categoryName)

      setCategory(categorySelected)

    }

  }, [categoryName])

  useEffect(() => {

    if (customerSupplierName) {

      const customerSupplierSelected = CustomerSuppliers.find(x => x.name === customerSupplierName)

      setCustomerSupplier(customerSupplierSelected)

    }

  }, [customerSupplierName])

  useEffect(() => {

    if (bankAccountName) {

      const bankAccountSelected = BankAccounts.find(x => x.description === bankAccountName)

      setBankAccount(bankAccountSelected)
    }

  }, [bankAccountName])

  useEffect(() => {

    let discountTotal = 0
    let interestTotal = 0
    let valueTotal = 0

    if (discount) {
      discountTotal = Math.trunc(toUSACurrency(discount) * 100)
    }

    if (interest) {
      interestTotal = Math.trunc(toUSACurrency(interest) * 100)
    }

    if (value) {
      valueTotal = (Math.trunc(toUSACurrency(value) * 100)) - discountTotal - interestTotal

      setTotal(brazilianCurrencyMask(valueTotal))
    } else {
      setTotal("")
    }

  }, [value, discount, interest])

  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (description) {
      data.description = description
    } else {
      allFieldsFilled = false
    }

    if (dueDate) {
      data.first_installment_due = setDatePostFormat(dueDate)
    } else {
      allFieldsFilled = false
    }

    if (formOfPayment) {
      data.form_of_payment = formOfPayment
    } else {
      allFieldsFilled = false
    }

    if (value) {
      data.value = Math.trunc(toUSACurrency(value) * 100)
    } else {
      allFieldsFilled = false
    }

    if (discount) {
      data.discount = Math.trunc(toUSACurrency(discount) * 100)
    }

    if (interest) {
      data.interest = Math.trunc(toUSACurrency(interest) * 100)
    }

    if (total) {
      data.total = Math.trunc(toUSACurrency(total) * 100)
    }

    if (costCenter?.id) {
      data.cost_center_id = costCenter.id
    }

    if (category?.id) {
      data.category_id = category.id
    }

    if (dateIssue) {
      data.date_issue = setDatePostFormat(dateIssue)
    }

    if (numDoc) {
      data.num_doc = numDoc
    }

    if (observation) {
      data.observation = observation
    }

    if (typeof paid == "boolean") {
      data.paid = paid
    } else {
      allFieldsFilled = false
    }

    if (paymentDate) {
      data.payment_date = setDatePostFormat(paymentDate)
    }

    if (customerSupplier?.id) {
      data.customer_supplier_id = customerSupplier.id
    }

    if (bankAccount?.id) {
      data.bank_account_id = bankAccount.id
    }

    if (numOfInstallments && numOfInstallments > 0) {
      data.num_of_installments = numOfInstallments
    } else if(numOfInstallments && numOfInstallments <= 0) {
      data.num_of_installments = 1      
    } else {
      allFieldsFilled = false
    }


    if (allFieldsFilled) {

    //  console.log(data)

      addExpense({ data, property_id: selectedProperty?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (getBankAccountsRest.isPending || addExpenseRest.isPending || getCostCenterRest.isPending || getCustomerSuppliersRest.isPending || getRevenueCategoryRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Descrição*"
          value={description}
          setValue={(e) => { setDescription(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Forma de Pagamento*"
          value={formOfPayment}
          list={formOfPaymentList}
          setValue={(e) => { setFormOfPayment(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Número de parcelas"
          value={numOfInstallments}
          setValue={(e) => { setNumOfInstallments(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Vencimento da 1 parcela*"
          value={dueDate}
          setValue={(e) => { setDueDate(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Valor*"
            value={value}
            setValue={(e) => { setValue(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Desconto"
            value={discount}
            setValue={(e) => { setDiscount(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Juros"
            value={interest}
            setValue={(e) => { setInterest(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <div className='flex items-center'>
          <Input
            type="text"
            disabled
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Total"
            value={total}
            setValue={(e) => { setTotal(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Centro de Custo"
          value={costCenterName}
          list={costCenterListName}
          setValue={(e) => { setCostCenterName(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Categoria"
          value={categoryName}
          list={categoryListName}
          setValue={(e) => { setCategoryName(e) }}
        />


        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Cliente / Fornecedor"
            value={customerSupplierName}
            list={customerSupplierListName}
            setValue={(e) => { setCustomerSupplierName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddCustomerSupllier(true)} />
        </div>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Conta Bancária"
            value={bankAccountName}
            list={bankAccountListName}
            setValue={(e) => { setBankAccountName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddBankAccount(true)} />
        </div>

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data de Emissão"
          value={dateIssue}
          setValue={(e) => { setDateIssue(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Número do documento"
          value={numDoc}
          setValue={(e) => { setNumDoc(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Observação"
          value={observation}
          setValue={(e) => { setObservation(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }
      {showModalAddCustomerSupllier &&
        <ModalSide setOpenModal={setShowModalAddCustomerSupllier} title="Novo Cliente / Fornecedor" >
          <CustomerSupplierAdd setOpenModal={setShowModalAddCustomerSupllier} />
        </ModalSide>
      }
      {showModalAddBankAccount &&
        <ModalSide setOpenModal={setShowModalAddBankAccount} title="Nova Conta Bancária" >
          <BankAccountAdd setOpenModal={setShowModalAddBankAccount} />
        </ModalSide>
      }

    </div >

  )
}

export default ExpenseAdd