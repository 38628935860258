import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Logo from '../../assets/logo.png'

import Input from '../../components/Input'
import ButtonSubmit from '../../components/ButtonSubmit'
import InputSelectLanguage from '../../components/InputSelectLanguage'

import { useLogin } from '../../queries/AuthQueries'

function Login() {

  const { login, loginRest } = useLogin()

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const submit = async () => {

    if (email && password) {

      login({ email, password })

    } else {
      alert('O e-mail e a senha devem ser preenchidos')
    }

  }

  return (
    <div className='bg-primaryBackground min-h-screen flex flex-col items-center justify-around'>

      <div className='w-full h-12 flex items-center justify-end'>

        <InputSelectLanguage />

      </div>


      <div className='flex items-start justify-center'>
        <img src={Logo} alt="" className='lg:w-1/4 w-3/4' />
      </div>

      <div className='flex flex-col flex-1 lg:w-1/3 w-[90%] justify-center items-center space-y-2'>

        <div className='w-full h-full flex-2 flex flex-col mb-4 justify-center space-y-2'>
          <Input
            value={email}
            placeholder="E-mail*"
            type="text"
            backColor="primaryBackground"
            setValue={(e) => setEmail(e)}
          />
          <Input
            value={password}
            placeholder="Senha"
            type="password"
            backColor="primaryBackground"
            setValue={(e) => setPassword(e)}
          />
        </div>

        <div className='flex justify-between w-full h-12 space-x-4'>
          <ButtonSubmit title="CADASTRAR" backColor="bg-white" textColor="text-primaryBackground" onClick={() => { navigate("/signUp") }} />
          <ButtonSubmit title="ENTRAR" backColor="bg-primaryContrast" textColor="text-white" disabled={email && password ? false : true} loading={loginRest.isPending} onClick={() => submit()} />
        </div>
      </div>
      <div className='flex items-end pb-20 lg:pb-10 flex-1 text-white '>
        <span>GepAgro - Gestão especializada no agronegócio</span>
      </div>

    </div>
  )
}

export default Login