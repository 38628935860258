import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'

// #### GET

export const useGetMilkings = (property_id = 0, page = 1, limit = 10) => {

  const QUERY_KEY = ['getMilkings', property_id, page, limit]

  const { data: { data: { Milkings = [], pagination: milkingsPagination = {} } = [] } = [], ...getMilkingsRest } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => (await api.get(`milkings?property_id=${property_id}&page=${page}&limit=${limit}`)),
    placeholderData: keepPreviousData,
  })

  return { Milkings, milkingsPagination, getMilkingsRest }
}


// ### ADD

export const useAddMilking = () => {

  const queryClient = useQueryClient()

  const { mutate: addMilking, ...addMilkingRest } = useMutation({
    mutationFn: async ({ data, animal_id }) => (await api.post(`milkings/${animal_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMilkings'] })
      alertSuccess("Cadastrado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { addMilking, addMilkingRest }
}

// ### UPDATE

export const useUpdateMilking = () => {

  const queryClient = useQueryClient()

  const { mutate: updateMilking, ...updateMilkingRest } = useMutation({
    mutationFn: async ({ data, milking_id }) => (await api.put(`milkings/${milking_id}`, data)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMilkings'] })
      alertSuccess("Atualizado com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })
  return { updateMilking, updateMilkingRest }
}

// ### DELETE

export const useDestroyMilking = () => {

  const queryClient = useQueryClient()

  const { mutate: destroyMilking, ...destroyMilkingRest } = useMutation({
    mutationFn: async (milking_id) => (await api.delete(`milkings/${milking_id}`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getMilkings'] })
      alertSuccess("Excluído com sucesso!")
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { destroyMilking, destroyMilkingRest }

}
