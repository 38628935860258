import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import Loading from '../../components/Loading'

import ProductiveAreaAdd from '../ProductiveAreaAdd'
import ProductiveAreaUpdate from '../ProductiveAreaUpdate'
import BackgroundData from '../../components/BackgroundData'

import { usePropertyStore } from '../../stores/propertyStore'

import { useGetProductiveAreas, useDestroyProductiveArea } from '../../queries/ProductiveAreaQueries'

function ProductiveAreaList() {

  const { selectedProperty } = usePropertyStore()
  const { destroyProductiveArea, destroyProductiveAreaRest } = useDestroyProductiveArea()


  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [selectedProductiveArea, setSelectedProductiveArea] = useState()

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { productiveAreas, getProductiveAreasRest } = useGetProductiveAreas(property_id)

  const handleDeleteProductiveArea = async () => {
    destroyProductiveArea(selectedProductiveArea?.id)
    setShowModalConfirm(false)
  }

  if (getProductiveAreasRest?.isPending || destroyProductiveAreaRest?.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Areas Produtivas" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>


        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Nome</th>
                  <th scope="col" className="px-6 py-3">Uso</th>
                  <th scope="col" className="px-6 py-3">Tamanho</th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(productiveAreas) && productiveAreas.map((item, index) => {

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">{item?.name ? item?.name : "-"}</th>
                      <td className="px-6 py-4">{item?.use}</td>
                      <td className="px-6 py-4">{`${item?.size} ha`}</td>

                      <th>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedProductiveArea(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedProductiveArea(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

          </div>



        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Nova Area produtiva" ><ProductiveAreaAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar Área Produtiva">
          <ProductiveAreaUpdate setOpenModal={setShowModalUpdate} data={selectedProductiveArea} />
        </ModalSide>}

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteProductiveArea() }} />}
    </Menu>
  )
}

export default ProductiveAreaList