import React, { useState, useEffect } from 'react'

function Switch(props) {

  const [active, setActive] = useState(props?.active)

  useEffect(() => {
   
    props?.setValue(active)    

  }, [active])

  return (
    <div className='w-full flex items-center justify-center py-2'>
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" className="sr-only peer" onClick={() => setActive(prev => !prev)}  defaultChecked={active} />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-secondaryBackground rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-tertiaryBackground">

        </div>
        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {active ? props?.activeText || "Ativo" : props.inactiveText || "Inativo"}
        </span>
      </label>
    </div>
  )
}

export default Switch