import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useAddBankAccount } from '../../queries/BankAccountQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { brazilianCurrencyMask, toUSACurrency, banksList } from '../../utils'

function BankAccountAdd(props) {

  const { addBankAccount, addBankAccountRest } = useAddBankAccount()

  const { selectedProperty } = usePropertyStore()

  const [banksListName, setBanksListName] = useState([])
  const [typeList, setTypeList] = useState([])

  const [description, setDescription] = useState("")
  const [type, setType] = useState("")
  const [bank, setBank] = useState("")
  const [owner, setOwner] = useState("")
  const [agency, setAgency] = useState("")
  const [digitAgency, setDigitAgency] = useState("")
  const [account, setAccount] = useState("")
  const [digitAccount, setDigitAccount] = useState("")
  const [balance, setBalance] = useState("")
  const [observation, setObservation] = useState("")

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  useEffect(() => {

    if (banksList.length > 0) {
      const banks = banksList.map(x => x.label)
      setBanksListName(banks)
      setBank(banks[0])
    }

    const types = [
      "Conta Corrente",
      "Conta Poupança",
      "Conta Salário",
      "Saldo em Caixa"
    ]

    setTypeList(types)
    setType(types[0])

  }, [])

  useEffect(() => {
    if (banksList.length > 0) {
      const banks = banksList.map(x => x.label)
      setBanksListName(banks)
      setBank(banks[0])
    }
  }, [banksList])

  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (description) {
      data.description = description
    } else {
      allFieldsFilled = false
    }

    if (type) {
      data.type = type
    }

    if (bank) {
      data.bank = bank
    } else {
      allFieldsFilled = false
    }

    if (owner) {
      data.owner = owner
    }

    if (agency) {
      data.agency = parseInt(agency)
    } else {
      allFieldsFilled = false
    }

    if (digitAgency) {
      data.digit_agency = parseInt(digitAgency)
    }

    if (account) {
      data.account = parseInt(account)
    } else {
      allFieldsFilled = false
    }

    if (digitAccount) {
      data.digit_account = parseInt(digitAccount)
    }

    if (balance) {
      data.balance = Math.trunc(toUSACurrency(balance) * 100)
    }

    data.active = true

    if (observation) {
      data.observation = observation
    }


    if (allFieldsFilled) {

      addBankAccount({ data, property_id: selectedProperty.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (addBankAccountRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Descrição*"
          value={description}
          setValue={(e) => { setDescription(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Tipo*"
          value={type}
          list={typeList}
          setValue={(e) => { setType(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Banco*"
          value={bank}
          list={banksListName}
          setValue={(e) => { setBank(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Agência*"
          value={agency}
          setValue={(e) => { setAgency(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Dígito da Agência"
          value={digitAgency}
          setValue={(e) => { setDigitAgency(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Conta*"
          value={account}
          setValue={(e) => { setAccount(e) }}
        />

        <Input
          type="number"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Dígito da conta"
          value={digitAccount}
          setValue={(e) => { setDigitAccount(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Saldo inicial"
            value={balance}
            setValue={(e) => { setBalance(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Proprietário da conta"
          value={owner}
          setValue={(e) => { setOwner(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Observação"
          value={observation}
          setValue={(e) => { setObservation(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default BankAccountAdd