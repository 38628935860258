import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { useGetAnimals } from '../../queries/AnimalQueries'
import { useAddMilking } from '../../queries/MilkingQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { brazilianCurrencyMask, brazilianCurrencyToFloat, setDatePostFormat } from '../../utils'

function MilkingAdd(props) {

  const { addMilking, addMilkingRest } = useAddMilking()

  const { selectedProperty } = usePropertyStore()

  const [litersMilking1, setLitersMilking1] = useState(0)
  const [litersMilking2, setLitersMilking2] = useState(0)
  const [litersMilking3, setLitersMilking3] = useState(0)
  const [date, setDate] = useState()

  const [mother, setMother] = useState("")
  const [motherListName, setMotherListName] = useState([])
  const [motherName, setMotherName] = useState("")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Animals, getAnimalsRest } = useGetAnimals(property_id, 1, 1000000)

  useEffect(() => {

    if (Animals.length > 0) {
      const motherListData = Animals.map((x) => {
        if (x?.sex === "Fêmea") {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)

      setMotherListName(motherListData)
      setMotherName(motherListData[0])
    }

  }, [Animals])

  useEffect(() => {

    if (motherName) {

      const animalSelected = Animals.find(x => {

        if (motherName.indexOf(x.name) > 0 || motherName && motherName === x?.earring) {
          return x
        }
      })

      setMother(animalSelected)

    }

  }, [motherName])


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (date) {
      data.date = setDatePostFormat(date)
    } else {
      allFieldsFilled = false
    }

    if (litersMilking1) {
      data.liters_milking_1 = brazilianCurrencyToFloat(litersMilking1)
    } else {
      allFieldsFilled = false
    }

    if (litersMilking2) {
      data.liters_milking_2 = brazilianCurrencyToFloat(litersMilking2)
    }

    if (litersMilking3) {
      data.liters_milking_3 = brazilianCurrencyToFloat(litersMilking3)
    }

    if (allFieldsFilled) {

      addMilking({ data, animal_id: mother.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (addMilkingRest.isPending || getAnimalsRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Animal*"
            value={motherName}
            list={motherListName}
            setValue={(e) => { setMotherName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data da ordenha*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros ordenha 1*"
          value={litersMilking1}
          setValue={(e) => { setLitersMilking1(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros ordenha 2"
          value={litersMilking2}
          setValue={(e) => { setLitersMilking2(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros ordenha 3"
          value={litersMilking3}
          setValue={(e) => { setLitersMilking3(brazilianCurrencyMask(e)) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default MilkingAdd