import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Icon } from '@iconify/react'
import { toast } from "react-toastify"

import { useGetProperties } from '../../queries/PropertyQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import Item from './Item'
import Subitems from './Subitems'
import InputSelect from '../InputSelect'
import ModalSide from '../ModalSide'
import Loading from '../Loading'

import PropertyAdd from '../../pages/PropertyAdd'

import Logo from '../../assets/logo.png'

function Menu(props) {

  const notify = (message) => toast.error(message, { toastId: "custom-id-yes" })

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { selectedProperty, setSelectedProperty } = usePropertyStore()

  const { properties, getPropertiesRest } = useGetProperties()

  const [propertyListName, setPropertyListName] = useState([])
  const [propertyName, setPropertyName] = useState("")
  const [property, setProperty] = useState({})

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)


  useEffect(() => {
    if (selectedProperty?.name) {
      setPropertyName(selectedProperty?.name)
    } else if (properties?.length > 0) {
      let list = properties.map(x => x.name)
      setPropertyName(list[0])
    }
  }, [])

  useEffect(() => {
    if (properties?.length > 0) {
      let list = properties.map(x => x.name)
      setPropertyListName(list)
      setPropertyName(list[0])
    } else {
      setPropertyListName([])
      setPropertyName("")
    }
  }, [properties])

  useEffect(() => {

    if (propertyName) {

      const propertySelected = properties.find(x => x.name === propertyName)

      setProperty(propertySelected)

    }

  }, [propertyName])

  useEffect(() => {
    setSelectedProperty(property)
  }, [property])


  const logoff = () => {
    const promise = new Promise((resolve, reject) => {
      localStorage.removeItem("Token")
      queryClient.removeQueries()
      resolve("")
    })

    promise.then(() => {
      window.location.reload()
    })


  }

  const toggleElement = (id) => {

    let element = document.getElementById(id)

    let nowClassName = element.className

    if (nowClassName.search("hidden") !== -1) {
      nowClassName = nowClassName.replace("hidden", "flex")
      element.className = nowClassName
    } else if (nowClassName.search("flex") !== -1) {
      nowClassName = nowClassName.replace("flex", "hidden")
      element.className = nowClassName
    }

  }

  if (getPropertiesRest.isPending) {
    return <Loading />
  }

  return (

    <div className='flex flex-col min-w-screen bg-gray-50'>

      <nav className='bg-white border-y border-gray-200 lg:h-20 flex lg:flex-row flex-col items-center lg:justify-start justify-around pr-4 py-4 shadow-md lg:gap-x-2 gap-y-4 lg:gap-y-0'>


        <div className='w-full flex items-center justify-around flex-1 order-2 lg:order-1'>

          <div className='hidden lg:flex flex-row lg:w-1/3 w-3/4 h-20 items-center justify-center'>
            <div className='flex flex-row items-center justify-center'>
              <img src={Logo} className='w-12 p-0' />
              <p className='text-xl text-primaryBackground font-semibold'>GepAgro</p>
            </div>
          </div>

          <button
            className='flex lg:hidden'
            onClick={() => {
              toggleElement('menu')
              toggleElement('menuIcon')
              toggleElement('closeMenuIcon')
            }}
          >
            <div className='flex py-4 px-6' id="menuIcon">
              <Icon icon="material-symbols:menu" className='flex text-4xl text-primaryBackground' />
            </div>
            <div className='hidden py-4 px-6' id="closeMenuIcon">
              <Icon icon="carbon:close-outline" className='text-4xl text-primaryBackground' />
            </div>
          </button>

          <div className='flex flex-1 items-center justify-center pr-0'>
            <h1 className='text-xl lg:text-2xl font-bold text-primaryBackground'>{props.title}</h1>
          </div>

        </div>

        <div className='flex flex-1 w-full justify-center items-center order-1 lg:order-2'>

          <div className='flex items-center space-x-2 lg:space-x-1'>
            <InputSelect
              type="text"
              borderColor="primaryBackground"
              textColor="primaryBackground"
              placeholder="Propriedade"
              value={propertyName}
              list={propertyListName}
              setValue={(e) => { setPropertyName(e) }}
            />

          </div>
        </div>

        {/* <div className='relative'>
          <button
            className='flex flex-2 justify-end items-center pr-6 space-x-2 hover:bg-primaryShadow p-2'
            onClick={() => {
              toggleElement("language")
            }}
          >
            <div className='flex items-center justify-center' id="arrowUpIcon">
              <Icon icon={selectedLanguageIconName} className="text-primaryBackground text-xl group-hover:font-semibold" />
            </div>
            <h1 className='hidden lg:flex text-sm font-medium text-primaryBackground'>{selectedLanguageTitle}</h1>
            <div className='hidden lg:flex items-center justify-center' id="arrowUpIcon">
              <Icon icon="ep:arrow-down" className="text-primaryBackground text-sm group-hover:font-semibold" />
            </div>
          </button>

          <div className='hidden absolute items-center justify-start group top-10 w-full space-x-2' id="language">
            <button
              className='flex items-center justify-start w-full p-1 bg-tertiaryBackground hover:bg-secondaryBackground group space-x-2'
              onClick={() => {
                setPortuguese(!portuguese)
                toggleElement("language")
              }}
            >
              <div className='flex items-center justify-center' id="arrowUpIcon">
                <Icon icon={unselectedLanguageIconName} className="text-primaryBackground text-xl" />
              </div>
              <h1 className='hidden lg:flex text-sm font-medium group-hover:font-semibold text-white'>{unselectedLanguageTitle}</h1>
            </button>
          </div>
        </div> */}


        {/* <div className='relative flex flex-2 justify-end rounded-md'>
          <button
            className='flex items-center space-x-4 hover:bg-primaryShadow lg:p-2'
            onClick={() => {
              toggleElement("userBox")
            }}
          >
            <div className='rounded-full'>
              <img src={user.url} className='w-6 lg:w-8 p-0 rounded-full' />
            </div>
            <div className='hidden lg:flex flex-col items-start'>
              <h1 className='text-sm text-primaryBackground'>{user.name}</h1>
              <h1 className='text-xs text-primaryBackground'>Produtor</h1>
            </div>
            <div className='hidden lg:flex items-center justify-center' id="arrowUpIcon">
              <Icon icon="ep:arrow-down" className="text-primaryBackground text-sm group-hover:font-semibold" />
            </div>
          </button>

          <div
            className='absolute hidden flex-col items-center justify-center top-14 lg:w-full w-[500%] rounded-md bg-primaryShadow p-2'
            id='userBox'
          >
            <div className='flex flex-col items-center space-y-1'>
              <img src={user.url} className='rounded-full w-10' />
              <span className='text-sm'>{user.name}</span>
            </div>
            <button className='flex items-center space-x-1 hover:bg-primaryShadow p-1'>
              <Icon icon="uil:signout" className="text-primaryBackground text-sm group-hover:font-semibold" />
              <span className='text-sm text-primaryBackground'>Sair</span>
            </button>
          </div>

        </div> */}

      </nav>


      <div className='flex lg:flex-row flex-col min-w-screen'>

        <div className='hidden absolute lg:static z-50 lg:flex flex-col lg:w-1/5 w-3/4 max-w-[224px] bg-primaryBackground' id='menu'>

          <aside className='min-h-screen py-6 flex-col items-center space-y-8'>

            <Item title="Propriedades" id="proprieties" iconName="iconoir:farm">
              <Subitems title="Todas as propriedades" pageName="/" />
              <Subitems title="Areas produtivas" pageName="/productive_area" />
            </Item>

            <Item title="Rebanho" id="herd" iconName="mdi:cow">
              <Subitems title="Lotes" pageName="/batches" />
              <Subitems title="Animais" pageName="/animals" />
              <Subitems title="Pesagem Animal" pageName="/weighings" />
              <Subitems title="Descarte animal" pageName="/animal_disposal" />
              <Subitems title="Inseminação / Cobertura" pageName="/matings" />
              <Subitems title="Parto" pageName="/calvings" />
            </Item>

            <Item title="Produção" id="milk" iconName="lucide:milk">
              <Subitems title="Ordenha" pageName="/milkings" />
              <Subitems title="Produção diária / Entrega" pageName="/daily_production" />
              <Subitems title="Início de Lactação Individual" pageName="/lactation_inductions" />
              <Subitems title="Final de Lactação Individual" pageName="/stop_lactations" />             
              <Subitems title="Qualidade do Leite Mensal" pageName="/milk_quality" />
            </Item>

            <Item title="Financeiro" id="money" iconName="grommet-icons:money">
              <Subitems title="Clientes / Fornecedores" pageName="/customer_suppliers" />
              <Subitems title="Contas Bancárias" pageName="/bank_accounts" />
              <Subitems title="Receitas" pageName="/revenues" />
              <Subitems title="Despesas" pageName="/expenses" />
              <Subitems title="Investimento / Patrimônio" pageName="/investiments" />
            </Item>

            <Item title="Relatórios" id="reports" iconName="mdi:report-line">
              <Subitems title="Produtivos" pageName="/report_productive" />
              <Subitems title="Animais a Secar" pageName="/report_animal_stop_lactation" />
              <Subitems title="Reprodutivos" pageName="/report_reproductive" />
              <Subitems title="Vendas" pageName="/report_sales" />
              <Subitems title="Lotes" pageName="/report_batch_list" />
              <Subitems title="Financeiro" pageName="/report_financial_list" />
            </Item>
            

            <Item title="Usuários" id="users" iconName="ci:users">
              <Subitems title="Todos os usuários" pageName="/users" />
            </Item>

            {/* <Item title="Sair" id="sair" iconName="uil:signout" onClick={() => logoff()}> */}

            <div className='flex flex-col items-center justify-center w-[95%] group hover:bg-tertiaryBackground rounded-b'>
              <button
                className="flex items-center justify-start py-6 lg:py-2 px-4 w-full rounded group hover:shadow-md"
                onClick={() => { logoff() }}
              >
                <div>
                  <Icon icon="uil:signout" className="flex-1 text-white lg:text-xl text-2xl group-hover:font-semibold" />
                </div>
                <span className='text-white lg:text-lg text-xl pl-4 pr-2 group-hover:font-semibold'>Sair</span>

              </button>

            </div>

          </aside>

        </div>

        <main className='w-full min-h-screen bg-gray-50 p-2 shadow-inner'>
          {props.children}
        </main>

      </div>

      {
        showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }
    </div >

  )
}

export default Menu