import React, { useState } from 'react'

import Input from '../../components/Input'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { useAddDailyProduction } from '../../queries/DailyProductionQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { brazilianCurrencyMask, setDatePostFormat, toUSACurrency } from '../../utils'


function DailyProductionAdd(props) {

  const { selectedProperty } = usePropertyStore() 
  const { addDailyProduction, addDailyProductionRest } = useAddDailyProduction()

  const [date, setDate] = useState()
  const [litersDelivered, setLitersDelivered] = useState("")
  const [litersConsumedCalves, setLitersConsumedCalves] = useState("")
  const [litersConsumedInternally, setLitersConsumedInternally] = useState("")
  const [litersDiscarted, setLitersDiscarted] = useState("")
  const [price, setPrice] = useState("")

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)
  

  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (date) {
      data.date = setDatePostFormat(date)
    } else {
      allFieldsFilled = false
    }

    if (litersDelivered) {
      data.liters_delivered = Math.trunc(toUSACurrency(litersDelivered) * 100)
    }

    if (litersConsumedCalves) {
      data.liters_consumed_calves = Math.trunc(toUSACurrency(litersConsumedCalves) * 100)
    }

    if (litersConsumedInternally) {
      data.liters_consumed_internally = Math.trunc(toUSACurrency(litersConsumedInternally) * 100)
    }

    if (litersDiscarted) {
      data.liters_discarted = Math.trunc(toUSACurrency(litersDiscarted) * 100)
    }

    if (price) {
      data.price = Math.trunc(toUSACurrency(price) * 100)
    }

    if (allFieldsFilled) {

      addDailyProduction({ data, property_id: selectedProperty?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if (addDailyProductionRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>        

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros entregues"
          value={litersDelivered}
          setValue={(e) => { setLitersDelivered(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros consumidos bezerros"
          value={litersConsumedCalves}
          setValue={(e) => { setLitersConsumedCalves(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros consumidos internamente"
          value={litersConsumedInternally}
          setValue={(e) => { setLitersConsumedInternally(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros descartados"
          value={litersDiscarted}
          setValue={(e) => { setLitersDiscarted(brazilianCurrencyMask(e)) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Preço do leite"
            value={price}
            setValue={(e) => { setPrice(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova Propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default DailyProductionAdd