import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import PropertyAdd from '../PropertyAdd'

import { usePropertyStore } from '../../stores/propertyStore'

import { useAddInvestiment } from '../../queries/InvestimentQueries'

import { brazilianCurrencyMask, toUSACurrency, setDatePostFormat, installmentList, investimentTypeList } from '../../utils'

function InvestimentAdd(props) {

  const { selectedProperty } = usePropertyStore()

  const { addInvestiment, addInvestimentRest } = useAddInvestiment()  

  const [description, setDescription] = useState("")
  const [startDate, setStartDate] = useState("")
  const [cost, setCost] = useState("")
  const [installments, setInstallments] = useState("")
  const [depreciation, setDepreciation] = useState("")
  const [brand, setBrand] = useState("")
  const [observation, setObservation] = useState("")

  const [type, setType] = useState("")
  const [typeList, setTypeList] = useState("")

  const [showModalAddProperty, setShowModalAddProperty] = useState(false)

  useEffect(() => { 

    setTypeList(investimentTypeList)
    setType(investimentTypeList[0])

    setInstallments(installmentList[0])

  }, [])


  const submit = async () => {

    let data = {}
    let allFieldsFilled = true

    if (description) {
      data.description = description
    } else {
      allFieldsFilled = false
    }

    if (startDate) {
      data.start_date = setDatePostFormat(startDate)
    } else {
      allFieldsFilled = false
    }

    if (type) {
      data.type = type
    } else {
      allFieldsFilled = false
    }

    if (cost) {
      data.cost = Math.trunc(toUSACurrency(cost) * 100)
    } else {
      allFieldsFilled = false
    }

    if (depreciation) {
      data.depreciation = Math.trunc(toUSACurrency(depreciation) * 100)
    } else {
      allFieldsFilled = false
    }

    if (installments) {
      data.installments = installments
    }

    if (brand) {
      data.brand = brand
    }

    if (observation) {
      data.observation = observation
    }

    if (allFieldsFilled) {

      addInvestiment({ data, property_id: selectedProperty?.id })

      props.setOpenModal(false)

    } else {
      alert('Preencha todos os campos obrigatórios (*)')
    }

  }

  if(addInvestimentRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>      

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Descrição*"
          value={description}
          setValue={(e) => { setDescription(e) }}
        />

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Categoria*"
          value={type}
          list={typeList}
          setValue={(e) => { setType(e) }}
        />

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data de Aquisição*"
          value={startDate}
          setValue={(e) => { setStartDate(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Custo*"
            value={cost}
            setValue={(e) => { setCost(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <InputSelect
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Prestações*"
          value={installments}
          list={installmentList}
          setValue={(e) => { setInstallments(e) }}
        />

        <div className='flex items-center'>
          <Input
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Depreciação a.a*"
            value={depreciation}
            setValue={(e) => { setDepreciation(brazilianCurrencyMask(e)) }}
          />
          <div className='flex items-center justify-center w-14 h-12 rounded-r-lg bg-gray-200'>
            <p className='text-sm'>R$</p>
          </div>
        </div>

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Marca"
          value={brand}
          setValue={(e) => { setBrand(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Observação"
          value={observation}
          setValue={(e) => { setObservation(e) }}
        />

      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddProperty &&
        <ModalSide setOpenModal={setShowModalAddProperty} title="Nova propriedade" >
          <PropertyAdd setOpenModal={setShowModalAddProperty} />
        </ModalSide>
      }

    </div >

  )
}

export default InvestimentAdd