import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useUserStore, useTokenStore } from '../stores/authStore'

import { alertSuccess, alertError } from '../utils'

import api from '../config/api'


// ### ADD

export const useLogin = () => {

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { setToken } = useTokenStore()
  const { setUser } = useUserStore()

  const { mutateAsync: asyncLogin, mutate: login, data: responseLogin, ...loginRest } = useMutation({
    mutationFn: async ({ email, password }) => (await api.post('session', { email, password })),

    onSuccess: async (responseLogin) => {

      //console.log(responseLogin)

      const setData = new Promise((resolve, reject) => {

        if (responseLogin?.data?.token) {
          setToken(responseLogin?.data?.token)
          resolve()
        } else {
          reject(Error("Token not defined"))
        }

        if (responseLogin?.data?.user) {
          setUser(responseLogin?.data?.user)
        } else {
          reject(Error("User not defined"))
        }

      })

      await setData.then(() => {
        navigate("/")
      })



      queryClient.invalidateQueries({ queryKey: ['useLogin'] })
    },
    onError: (error) => {
      if (error.response) {
        alertError(error.response.data.error)
        console.log(error.response.data.error)
      } else {
        alertError(error.message)
        console.log(error.message)
      }
    }
  })

  return { login, asyncLogin, loginRest, responseLogin }
}

