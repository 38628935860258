import React, { useState, useEffect } from 'react'

import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonSubmit from '../../components/ButtonSubmit'
import ButtonAdd from '../../components/ButtonAdd'
import ModalSide from '../../components/ModalSide'
import Loading from '../../components/Loading'

import AnimalAdd from '../AnimalAdd'

import { useGetAnimals } from '../../queries/AnimalQueries'
import { useUpdateMilking } from '../../queries/MilkingQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { brazilianCurrencyMask, brazilianCurrencyToFloat, setDatePostFormat, setInputDateFormat } from '../../utils'


function MilkingUpdate(props) {

  const selectedMilking = props?.data || {}

  const { selectedProperty } = usePropertyStore()

  const { updateMilking, updateMilkingRest } = useUpdateMilking()

  const [litersMilking1, setLitersMilking1] = useState(selectedMilking?.liters_milking_1 || "")
  const [litersMilking2, setLitersMilking2] = useState(selectedMilking?.liters_milking_2 || "")
  const [litersMilking3, setLitersMilking3] = useState(selectedMilking?.liters_milking_3 || "")
  const [date, setDate] = useState(setInputDateFormat(selectedMilking?.date) || "")

  const [mother, setMother] = useState("")
  const [motherListName, setMotherListName] = useState([])
  const [motherName, setMotherName] = useState("")

  const [showModalAddAnimal, setShowModalAddAnimal] = useState(false)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Animals, getAnimalsRest } = useGetAnimals(property_id, 1, 1000000)

  useEffect(() => {

    const motherListData = Animals.filter((x) => {
      if (x?.sex === "Fêmea") {
        return true
      }
    }).map(x => x?.name ? `${x?.earring} - ${x?.name}` : x?.earring)

    setMotherListName(motherListData)
    //setMotherName(motherListData[0])

    if (selectedMilking?.animal_id) {
      const animalNameStart = Animals.map((x) => {

        if (x?.id === selectedMilking?.animal_id) {
          return x?.name ? `${x?.earring} - ${x?.name}` : x?.earring
        }
      }).filter((str) => str)

      setMotherName(animalNameStart)
    }

  }, [Animals])

  useEffect(() => {

    if (motherName) {

      const animalSelected = Animals.find(x => {

        if (motherName.indexOf(x.name) > 0 || motherName && motherName === x?.earring) {
          return x
        }
      })

      setMother(animalSelected)

    }

  }, [motherName])


  const submit = async () => {

    let data = {}
    let dataUpdated = false

    if (mother?.id && mother?.id !== selectedMilking?.animal_id) {
      data.animal_id = mother?.id
      dataUpdated = true
    }

    if (litersMilking1 && brazilianCurrencyToFloat(litersMilking1) !== brazilianCurrencyToFloat(selectedMilking?.liters_milking_1 / 100)) {
      data.liters_milking_1 = brazilianCurrencyToFloat(litersMilking1)
      dataUpdated = true
    }

    if (litersMilking2 && brazilianCurrencyToFloat(litersMilking2) !== brazilianCurrencyToFloat(selectedMilking?.liters_milking_2 / 100)) {
      data.liters_milking_2 = brazilianCurrencyToFloat(litersMilking2)
      dataUpdated = true
    }

    if (litersMilking3 && brazilianCurrencyToFloat(litersMilking3) !== brazilianCurrencyToFloat(selectedMilking?.liters_milking_3 / 100)) {
      data.liters_milking_3 = brazilianCurrencyToFloat(litersMilking3)
      dataUpdated = true
    }

    const newDate = new Date(selectedMilking?.date)
    const newDateFormat = setInputDateFormat(newDate)

    if (date && date !== newDateFormat) {
      //const textDate = date?.replace(/-/g, "/")
      data.date = setDatePostFormat(date)
      dataUpdated = true
    }

    if (dataUpdated) {

      updateMilking({ data, milking_id: selectedMilking?.id })

      props.setOpenModal(false)

    } else {
      alert('Nenhum dado para ser atualizado')
    }

  }

  if (updateMilkingRest.isPending || getAnimalsRest.isPending) {
    return <Loading />
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-3'>

        <div className='flex items-center space-x-2 lg:space-x-1'>
          <InputSelect
            type="text"
            borderColor="primaryBackground"
            textColor="primaryBackground"
            placeholder="Animal*" sta
            value={motherName}
            list={motherListName}
            setValue={(e) => { setMotherName(e) }}
          />
          <ButtonAdd onClick={() => setShowModalAddAnimal(true)} />
        </div>

        <Input
          type="date"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Data*"
          value={date}
          setValue={(e) => { setDate(e) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros ordenha 1*"
          value={litersMilking1}
          setValue={(e) => { setLitersMilking1(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros ordenha 2"
          value={litersMilking2}
          setValue={(e) => { setLitersMilking2(brazilianCurrencyMask(e)) }}
        />

        <Input
          type="text"
          borderColor="primaryBackground"
          textColor="primaryBackground"
          placeholder="Litros ordenha 3"
          value={litersMilking3}
          setValue={(e) => { setLitersMilking3(brazilianCurrencyMask(e)) }}
        />


      </div>

      <div className='flex justify-between w-full h-12 space-x-4'>

        <ButtonSubmit title="CANCELAR" backColor='bg-primaryAlert' textColor="text-white" onClick={() => { props.setOpenModal(false) }} loading={""} />

        <ButtonSubmit title="ENVIAR" backColor="bg-tertiaryBackground" textColor="text-white" onClick={() => { submit() }} loading={""} />
      </div>

      {showModalAddAnimal &&
        <ModalSide setOpenModal={setShowModalAddAnimal} title="Novo Animal" >
          <AnimalAdd setOpenModal={setShowModalAddAnimal} />
        </ModalSide>
      }

    </div >

  )
}

export default MilkingUpdate