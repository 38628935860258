import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import Menu from '../../components/Menu'
import ButtonSubmit from '../../components/ButtonSubmit'
import ModalSide from '../../components/ModalSide'
import ModalConfirm from '../../components/ModalConfirm'
import BackgroundData from '../../components/BackgroundData'
import ButtonsPagination from '../../components/ButtonsPagination'
import Loading from '../../components/Loading'

import MilkingAdd from '../MilkingAdd'
import MilkingUpdate from '../MilkingUpdate'

import { useGetMilkings, useDestroyMilking,  } from '../../queries/MilkingQueries'

import { usePropertyStore } from '../../stores/propertyStore'

import { toBrazilianCurrency, setDateBrazilianFormat } from '../../utils'

function MilkingList() {

  const { destroyMilking, destroyMilkingRest } = useDestroyMilking()

  const { selectedProperty } = usePropertyStore()

  const [selectedMilking, setSelectedMilking] = useState()

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  let property_id

  if (selectedProperty?.name) {
    property_id = selectedProperty?.id
  } else {
    property_id = 0
  }

  const { Milkings, getMilkingsRest, milkingsPagination } = useGetMilkings(property_id, page, limit)


  const handleDeleteMilking = async () => {

    destroyMilking(selectedMilking.id)

    setShowModalConfirm(false)
  }

  if (getMilkingsRest.isPending || destroyMilkingRest.isPending) {
    return <Loading />
  }

  return (
    <Menu title="Ordenha" >
      <div className='w-full flex flex-col items-center justify-center p-2 lg:p-4 space-y-6'>

        <BackgroundData>
          <div className='w-full flex justify-end'>
            <div className='w-64 flex'>
              <ButtonSubmit
                textColor="text-white"
                backColor="bg-tertiaryBackground"
                title="Cadastrar"
                icon="ic:baseline-plus"
                onClick={() => setShowModalAdd(true)}
              />
            </div>
          </div>
        </BackgroundData>

        <BackgroundData>

          <div className="relative overflow-x-auto space-y-4">
            <table className="w-full text-sm text-left rtl:text-righ">
              <thead className="text-xs uppercase bg-gray-50 text-primaryBackground">
                <tr>
                  <th scope="col" className="px-6 py-3">Animal</th>
                  <th scope="col" className="px-6 py-3">Data da ordenha</th>
                  <th scope="col" className="px-6 py-3">Ordenha 1</th>
                  <th scope="col" className="px-6 py-3">Ordenha 2</th>
                  <th scope="col" className="px-6 py-3">Ordenha 3</th>
                  <th scope="col" className="px-6 py-3">Total litros</th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>

              {Array.isArray(Milkings) && Milkings.map((item, index) => {

                const totalLiters = ((parseInt(item?.liters_milking_1 || 0) + parseInt(item?.liters_milking_2 || 0) + parseInt(item?.liters_milking_3 || 0)))

                return (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <td className="px-6 py-4">{item?.Animal?.name ? `${item?.Animal?.earring} - ${item?.Animal?.name}` : item?.Animal?.earring || ""}</td>
                      <td className="px-6 py-4">{setDateBrazilianFormat(item?.date)}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(item?.liters_milking_1) || 0}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(item?.liters_milking_2) || 0}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(item?.liters_milking_3) || 0}</td>
                      <td className="px-6 py-4">{toBrazilianCurrency(totalLiters) || 0}</td>

                      <th className=''>
                        <div className='flex items-center justify-end'>
                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedMilking(item)
                              setShowModalUpdate(true)
                            }}
                          ><Icon icon="bxs:edit" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>

                          <button
                            className='p-3 hover:bg-secondaryBackground group'
                            onClick={() => {
                              setSelectedMilking(item)
                              setShowModalConfirm(true)
                            }}
                          >
                            <Icon icon="bxs:trash" className='text-2xl text-primaryBackground group-hover:text-white' />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                )
              })}

            </table>

            <div className='flex items-center justify-center'>
              <ButtonsPagination

                data={milkingsPagination}
                page={(page) => { setPage(page) }}
                limit={(limit) => { setLimit(limit) }}
              />
            </div>

          </div>
        </BackgroundData>

      </div>
      {showModalAdd &&
        <ModalSide setOpenModal={setShowModalAdd} title="Nova Ordenha" ><MilkingAdd setOpenModal={setShowModalAdd} /></ModalSide>
      }
      {showModalUpdate &&
        <ModalSide setOpenModal={setShowModalUpdate} title="Atualizar Ordenha"><MilkingUpdate setOpenModal={setShowModalUpdate} data={selectedMilking} /></ModalSide>}

      {showModalConfirm &&
        <ModalConfirm setOpenModal={setShowModalConfirm} message="Tem certeza que deseja excluir?" confirmFunction={() => { handleDeleteMilking() }} />}
    </Menu>
  )
}

export default MilkingList