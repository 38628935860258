import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'

import { toggleElement } from '../../utils'

function InputSelectLanguage() {

    const [portuguese, setPortuguese] = useState(true)
    const [selectedLanguageIconName, setSelectedLanguageIconName] = useState("emojione:flag-for-brazil")
    const [unselectedLanguageIconName, setUnselectedLanguageIconName] = useState("emojione:flag-for-united-states")
    const [selectedLanguageTitle, setSelectedLanguageTitle] = useState("Por (BR)")
    const [unselectedLanguageTitle, setUnselectedLanguageTitle] = useState("Eng (US)")

    useEffect(() => {
        if (portuguese) {
            setSelectedLanguageIconName("emojione:flag-for-brazil")
            setUnselectedLanguageIconName("emojione:flag-for-united-states")
            setSelectedLanguageTitle("Por (BR)")
            setUnselectedLanguageTitle("Eng (US)")
        } else {
            setSelectedLanguageIconName("emojione:flag-for-united-states")
            setUnselectedLanguageIconName("emojione:flag-for-brazil")
            setSelectedLanguageTitle("Eng (US)")
            setUnselectedLanguageTitle("Por (BR)")
        }

    }, [portuguese])

    return (
        <div className='relative'>
            <button
                className='flex flex-2 justify-end items-center pr-6 space-x-2 hover:bg-primaryShadow p-2'
                onClick={() => {
                    toggleElement("language")
                }}
            >
                <div className='flex items-center justify-center' id="arrowUpIcon">
                    <Icon icon={selectedLanguageIconName} className="text-primaryBackground text-xl group-hover:font-semibold" />
                </div>
                <h1 className='hidden lg:flex text-sm font-medium text-white'>{selectedLanguageTitle}</h1>
                <div className='hidden lg:flex items-center justify-center' id="arrowUpIcon">
                    <Icon icon="ep:arrow-down" className="text-white text-sm group-hover:font-semibold" />
                </div>
            </button>

            <div className='hidden absolute items-center justify-start group top-10 w-full space-x-2' id="language">
                <button
                    className='flex items-center justify-start w-full p-1 bg-tertiaryBackground hover:bg-secondaryBackground group space-x-2'
                    onClick={() => {
                        setPortuguese(!portuguese)
                        toggleElement("language")
                    }}
                >
                    <div className='flex items-center justify-center' id="arrowUpIcon">
                        <Icon icon={unselectedLanguageIconName} className="text-primaryBackground text-xl" />
                    </div>
                    <h1 className='hidden lg:flex text-sm font-medium group-hover:font-semibold text-white'>{unselectedLanguageTitle}</h1>
                </button>
            </div>
        </div>
    )
}

export default InputSelectLanguage